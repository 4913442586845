import { mdiSort } from "@mdi/js";
import React from "react";
import { SecondaryButton } from "../CustomButton";
import { Dialog } from "../dialog/DialogManager";
import { ISort } from "./Order";

type TDocumentOrderPopupProps<TOrder extends ISort> = {
    order?: TOrder;
    orderChanged: (order: TOrder) => void;
}

type TDocumentOrderPopupState = {

}

export abstract class OrderPopup<TOrder extends ISort> extends React.Component<TDocumentOrderPopupProps<TOrder>, TDocumentOrderPopupState> {
    private _dismiss: () => void = () => { };
    constructor(props: TDocumentOrderPopupProps<TOrder> | Readonly<TDocumentOrderPopupProps<TOrder>>) {
        super(props);

        this.state = {};
    }

    abstract getPopupContent(apply: (o: TOrder) => void): JSX.Element;

    open = async (evt: React.MouseEvent<Element>) => {
        const apply = (o: TOrder) => {
            this.props.orderChanged(o);
        }

        await Dialog.tooltip(this.getPopupContent(apply), {
            handle: evt.currentTarget,
            dispatchDismiss: (dismiss) => { this._dismiss = dismiss; },
            notModal: false
        });
    };

    render = () => {
        return <>
            <SecondaryButton maIcon={mdiSort} link onClick={this.open} />
        </>;
    };
}