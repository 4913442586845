import styled from "@emotion/styled";
import Colors, { ColorSections } from "./Color/MainColors";

type TTooltipItemProps = {
    active?: boolean
    noCursor?: boolean;
}

export const TooltipItem = styled.div<TTooltipItemProps>`
    display: flex;
    align-items: center;
    cursor: ${props => props.noCursor ? "auto" : "pointer"};
    padding: 0.25rem 0.75rem;
    background-color: ${props => props.active ? "rgba(0,0,0, 0.20)" : "none"};

    :hover {
        background-color: rgba(0,0,0, 0.20);
    }
`;

const TooltipPopupStyle = styled.div`
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
`;

interface Props {
    children: React.ReactNode;
    colors?: Colors
}

export const TooltipPopup = ({ children, colors }: Props) => {
    colors = colors ?? Colors.Current;
    return <TooltipPopupStyle style={{ backgroundColor: colors.get(ColorSections.TooltipBG), color: colors.get(ColorSections.TooltipText) }}>{children}</TooltipPopupStyle>
}