import { ActionButton } from "../ActionButton";
import Icon, { Stack } from '@mdi/react';
import { mdiTableLargePlus, mdiTableColumnPlusAfter, mdiTableColumnPlusBefore, mdiTableRowPlusAfter, mdiTableRowPlusBefore, 
    mdiTableLargeRemove, mdiTableRowRemove, mdiTableColumnRemove, mdiMenuDown, mdiTableLarge } from '@mdi/js'; 
import { useCommands } from "@remirror/react";
import { ButtonGroup } from "reactstrap";
import { useState } from "react";
import styled from "@emotion/styled";
import { SecondaryButton } from "../../../../../utils/CustomButton";

export const AddTable = () => {
    const [open, setOpen] = useState(false);
    const commands = useCommands();

    return <ButtonGroup size="sm">
        <SecondaryButton onClick={() => setOpen(!open)}>
            <Toggle size="1.5em" open={open}>
                <Icon path={mdiTableLarge} size="1.5em" />
                <Icon path={mdiMenuDown} />
            </Toggle>
        </SecondaryButton>
        {open && <>
            <ActionButton enabled onClick={(evt) => commands.createTable({ withHeaderRow: false })}><Icon path={mdiTableLargePlus} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={(evt) => commands.deleteTable()}><Icon path={mdiTableLargeRemove} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={(evt) => commands.addTableColumnAfter()}><Icon path={mdiTableColumnPlusAfter} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={(evt) => commands.addTableColumnBefore()}><Icon path={mdiTableColumnPlusBefore} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={(evt) => commands.deleteTableColumn()}><Icon path={mdiTableColumnRemove} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={(evt) => commands.addTableRowAfter()}><Icon path={mdiTableRowPlusAfter} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={(evt) => commands.addTableRowBefore()}><Icon path={mdiTableRowPlusBefore} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={(evt) => commands.deleteTableRow()}><Icon path={mdiTableRowRemove} size="1.5em" /></ActionButton>        
        </>}
    </ButtonGroup>;
}

type TProps = {
    open: boolean;
}

const Toggle = styled(Stack)<TProps>`
    > g:first-of-type {
        transform: scale(0.85) translate(-5px, 0px)!important;
        transform-origin: center center;
    }
    > path:first-of-type {
        transform: ${props => `scale(1.125) translate(8px, 0px) rotate(${props.open ? "-" : ""}90deg) !important`};
        transform-origin: center center;
    }
`;