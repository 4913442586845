import styled from "@emotion/styled";
import React, { CSSProperties, useState } from "react";
import IDocument from "../../../realm/schemas/Document/IDocument";
import { AuthService } from "../../../services/AuthService";
import DocumentService from "../../../services/DocumentService";
import { DisplayTitle } from "../DisplayTitle";
import { DocumentTitleEditor } from "./DocumentTitleEditor";
import { ObjectId } from "bson";
import Icon from "@mdi/react";
import { mdiCloseThick, mdiEarth, mdiPlusThick, mdiShield } from "@mdi/js";
import { DocumentPathEditor } from "./DocumentPathEditor";
import Colors, { ColorSections } from "../../../utils/Color/MainColors";
import { Dialog } from "../../../utils/dialog/DialogManager";
import { DialogResult } from "../../../utils/dialog/enums/DialogResult";
import PathGroupsService from "../../../services/PathGroupsService";
import Localized from "../../../Localization/Localized";

type TDocumentTitleProps = {
    document?: IDocument | null;
}

type TDocumentTitleState = {
    docId: ObjectId | null;
    title: string | null;
    publicTitle: string | null;
    paths: string[] | null;
    publicPaths: string[] | null;
    canEdit?: boolean;
    isTitleEdit: boolean;
}
export default class DocumentTitleAndPath extends React.Component<TDocumentTitleProps, TDocumentTitleState> {
    constructor(props: TDocumentTitleProps | Readonly<TDocumentTitleProps>){
        super(props);

        const doc = props.document;
        const paths = Array.isArray(doc?.path) ? doc?.path : (doc?.path ? [ doc.path ] : null);
        const publicPaths = Array.isArray(doc?.publicPath) ? doc?.publicPath : (doc?.publicPath ? [ doc.publicPath ] : null);
        this.state = {
            docId: doc?._id ?? null,
            paths: paths ?? null,
            publicPaths: publicPaths ?? null,
            title: doc?.title ?? null,
            publicTitle: doc?.publicTitle ?? null,
            canEdit: AuthService.isOwner(doc?.createdById),
            isTitleEdit: false
        }
    }

    toggleTitleEdit = () => {
        this.setState(state => {
            return {
                isTitleEdit: !state.isTitleEdit
            }
        });
    }

    addPath = () => {
        this.setState(state => {
            const found = state.paths?.find(f => f.indexOf(Localized.Document.NewPath) > -1);
            let p =  state.paths ?? [];
            if(!found) p.push(Localized.Document.NewPath);
            return {
                paths: p
            }
        });
    }

    addPublicPath = () => {
        this.setState(state => {
            const found = state.publicPaths?.find(f => f.indexOf(Localized.Document.NewPublicPath) > -1);
            let p =  state.publicPaths ?? [];
            if(!found) p.push(Localized.Document.NewPublicPath);
            return {
                publicPaths: p
            }
        });
    }

    private _removePath = (index: number, path: string, isPublic: boolean) => {
        if(this.state.docId) {
            Dialog.confirm(Localized.format(Localized.Document.ConfirmDeletePath, path)).then(result => {
                if(result === DialogResult.Ok && this.state.docId) {
                    DocumentService.RemovePath(this.state.docId, index, isPublic);
                }
            })
        }
    }

    removePath = (index: number, path: string) => {
        this._removePath(index, path, false);
    }

    removePublicPath = (index: number, path: string) => {
        this._removePath(index, path, true);
    }

    render = () => {       
        return <div>
            {this.state.docId && this.state.canEdit && <TitleDisplay 
                docId={this.state.docId} 
                title={this.state.title ?? ""} 
                isEditable={this.state.canEdit} />}
                
            {this.state.docId && <TitleDisplay 
                docId={this.state.docId} 
                title={this.state.publicTitle ?? ""} 
                isEditable={this.state.canEdit} 
                isPublic isPublished/>}

            {this.state.docId && this.state.canEdit && <PathDisplay 
                paths={this.state.paths ?? []} 
                docId={this.state.docId} 
                isEditable={this.state.canEdit} 
                removePath={this.removePath} 
                addPath={this.addPath} />}

            {this.state.docId && (this.state.canEdit || (this.state.publicPaths && this.state.publicPaths.length > 0)) && <PathDisplay 
                paths={this.state.publicPaths ?? []} 
                docId={this.state.docId} 
                isEditable={this.state.canEdit} 
                removePath={this.removePublicPath} 
                addPath={this.addPublicPath} isPublic />}
        </div>
    }
}

type TTitleDisplayProps = {
    docId: ObjectId;
    title: string;
    isPublic?: boolean;
    isEditable?: boolean;
    isPublished?: boolean;
}

const TitleDisplay:React.FC<TTitleDisplayProps> = (props: TTitleDisplayProps) => {
    const [edit, setEdit] = useState(false);
    
    return <>
        {!edit && <TitleContainer onDoubleClick={(props.isEditable ? () => setEdit(!edit) : undefined)} 
            style={{ cursor: props.isEditable ? "pointer" : undefined }}>
            <DisplayTitle title={props.title} isPublic={props.isPublic} isPublished={props.isPublished} />
        </TitleContainer>}
        {edit && <DocumentTitleEditor docId={props.docId} title={props.title} close={() => setEdit(false)} isPublic={props.isPublic} />}
    </>
} 

type TPathDisplayProps = {
    docId: ObjectId;
    paths: string[];
    isPublic?: boolean;
    isEditable?: boolean;
    removePath:(index: number, path: string) => void;
    addPath: () => void;
}

const PathStyle = (path: string) => {
    const g = PathGroupsService.GetGroup(path);

    let style:CSSProperties | undefined = undefined;
    if(g && g.bgColor && (g.bgColor.a ?? 0) > 0) {
        style = {
            backgroundColor: g.bgColor ? Colors.toRGBAStringColor(g.bgColor) : undefined
        };
    } 

    return style
}

const PathDisplay:React.FC<TPathDisplayProps> = (props: TPathDisplayProps) => {
    const [editPathIndex, setEditPathIndex] = useState<number>(-1);

    return <div style={{fontSize: "0.8em", position: "relative", display: "flex", justifyContent: "start", alignItems: "center"}}>
        <div>
            {props.isPublic && <Icon path={mdiEarth}  size="1em"/>}
            {!props.isPublic && <Icon path={mdiShield}  size="1em"/>}
        </div>
        <div style={{width: "100%"}}>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {props.paths?.map((m, i) => <div key={i} style={i === editPathIndex ? { width: "100%" } : { whiteSpace: "nowrap" }}>
                        {i === editPathIndex ? <DocumentPathEditor docId={props.docId ?? new ObjectId()} path={m} index={i} close={() => {
                        setEditPathIndex(-1);
                    }} isPublic={props.isPublic} /> : 
                    <>
                        <PathContiner style={PathStyle(m)} key={i} onDoubleClick={() => {
                            setEditPathIndex(i);
                        }}>{m}<RemovePath isLast={i === (props.paths?.length ?? 1) - 1} onClick={() => props.removePath(i, m)}><Icon path={mdiCloseThick} size="1.5em" /></RemovePath>
                        </PathContiner>
                    </>
                }</div>)}
            </div>
        </div>
        {props.isEditable && <div>
            <AddPath onClick={props.addPath} ><Icon path={mdiPlusThick} size="1.5em" /></AddPath>
        </div>}
    </div>
}

const PathContiner = styled.div`
    position: relative;
    cursor: pointer;
    border: ${`solid 1px ${Colors.get(ColorSections.Text)}`};
    border-radius: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
`;

const TitleContainer = styled.div`
    title {
        display: block;
    }
`;

type TRemovePathProps = {
    isLast: boolean
}

const RemovePath = styled.span<TRemovePathProps>`
    //position: absolute;
    cursor: pointer;
    //right:0;
    //margin-right: ${props => props.isLast ? "1.5em" : "0"};
    color: ${Colors.get(ColorSections.DangerColor)};
    :hover {
        color: ${Colors.toHSLAStringColor(Colors.rgbToHsl(Colors.getRGB(ColorSections.DangerColor)), 1.5)};
    }
`;

const AddPath = styled.span`
    //position: absolute;
    //bottom:0;
    //right: 0.5rem;
    width: 1em;
    cursor: pointer;
    color: ${Colors.toHSLAStringColor(Colors.rgbToHsl(Colors.getRGB(ColorSections.PrimaryColor)), 2.5)};
    :hover {
        color: ${Colors.toHSLAStringColor(Colors.rgbToHsl(Colors.getRGB(ColorSections.PrimaryColor)), 1.5)};
    }
`;