import { useMediaQuery } from "react-responsive";

type TResponsiveContainerProps = {}

const ResponsiveFormPage: React.FC<React.PropsWithChildren<TResponsiveContainerProps>> = (props: React.PropsWithChildren<TResponsiveContainerProps>) => {
    const xlarge = useMediaQuery({ minWidth: 2400 });
    const large = useMediaQuery({ minWidth: 1224 });
    const medium = useMediaQuery({ minWidth: 900 });

    let maxWidth = "98%";
    if(xlarge) maxWidth = "50%";
    if(large) maxWidth = "65%";
    if(medium) maxWidth = "75%";

    return <div style={{ maxWidth: maxWidth, marginLeft: "auto", marginRight: "auto" }}>
        {props.children}
    </div>
}

export default ResponsiveFormPage;


type TOnSizeOnlyProps = {
    minWidth: string | number;
}

export const OnSizeOnly: React.FC<React.PropsWithChildren<TOnSizeOnlyProps>> = (props: React.PropsWithChildren<TOnSizeOnlyProps>) => {
    const isVisible = useMediaQuery({ minWidth: props.minWidth });
    return <>
        {isVisible && props.children}
    </>;
}


