import { mdiFilter } from "@mdi/js";
import React from "react";
import { SecondaryButton } from "../CustomButton";
import { IFilter } from "./Filter";

interface IFilterPopupProps<TFilter extends IFilter> {
    filter?: TFilter;
    filterChanged: (filter: TFilter) => void;
}

type TFilterPopupState = {

}

export abstract class FilterPopup<TFilter extends IFilter> extends React.Component<IFilterPopupProps<TFilter>, TFilterPopupState> {
    protected _dismiss: () => void = () => { };
    constructor(props: IFilterPopupProps<TFilter> | Readonly<IFilterPopupProps<TFilter>>) {
        super(props);

        this.state = {};
    }

    abstract open(target : Element): Promise<void>;

    render = () => {
        return <>
            <SecondaryButton maIcon={mdiFilter} link onClick={(evt) => this.open(evt.currentTarget)} />
        </>;
    };
}