import styled from "@emotion/styled";
import { mdiContentSave, mdiEarth, mdiShield, mdiUndoVariant, mdiViewDashboardEdit, mdiViewDashboardVariant } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import Localized from "../../../Localization/Localized";
import { ISpace } from "../../../realm/schemas/ISpace";
import { AuthService } from "../../../services/AuthService";
import SpaceService from "../../../services/SpaceService";
import { PrimaryButton, SecondaryButton, SuccessButton, WarningButton } from "../../../utils/CustomButton";
import { CustomInput } from "../../../utils/CustomInput";
import { CustomInputGroup } from "../../../utils/CustomInputGroup";
import { AddSpaces } from "./AddSpaces";

export type TSpacesState = {
    spaces: ISpace[];
    newSpaceName: string;
    isAddDisabled?: boolean;
    isAdding?: boolean;
}


export class Spaces extends React.Component<{}, TSpacesState> {
    constructor(props: Readonly<{}>) {
        super(props);

        this.state = {
            spaces: [],
            newSpaceName: "",
            isAddDisabled: true
        };
    }

    componentDidMount = () => {
        this.load();
    };

    load = async () => {
        if(!AuthService.currentProfile) return;
        var result = await SpaceService.GetByOwnerId(AuthService.currentProfile._id);
        
        this.setState({
            spaces: result
        });
    };

    render = () => {
        return <div>
            <h3><Icon path={mdiViewDashboardVariant} size="1em" /> {Localized.Space.ListTitle}</h3>
            <SpaceList>
                {this.state.spaces.map(s => <Space key={s._id.toHexString()} space={s}/>)}
            </SpaceList>
            <AddSpaces spacerAdded={this.load} />
        </div>;
    };
}

type TSpaceProps = {
    space: ISpace;
}

type TSpaceState = {
    isEdit?: boolean;
    spaceName: string;
    oldSpaceName: string;
    isPublished: boolean;
}
class Space extends React.Component<TSpaceProps, TSpaceState> {
    constructor(props: TSpaceProps | Readonly<TSpaceProps>) {
        super(props);

        this.state = {
            spaceName: props.space.name,
            oldSpaceName: props.space.name,
            isPublished: props.space.isPublished
        }
    }

    onChange = (value: string) => {
        if (!value || value.length === 0) {
            this.setState({
                spaceName: ""
            });

            return;
        }

        if (/^[A-Z0-9-:_ ]+$/.test(value.toUpperCase())) {
            this.setState({
                spaceName: value.toUpperCase()
            });
        } 
    }

    cancel = () => {
        this.setState(state => {
            return {
                spaceName: state.oldSpaceName,
                isEdit: false
            }
        });
    }

    save = () => {
        SpaceService.update(this.props.space._id, this.state.spaceName).then(result => {
            if (result) {
                this.setState({
                    isEdit: false,
                    oldSpaceName: this.state.spaceName
                });
            }
        });
    }

    tooglePublished = async () => {
        const result = await SpaceService.publish(this.props.space._id, !this.state.isPublished);

        if(result) this.setState({
            isPublished: !this.state.isPublished
        });  
    }

    render = () => {
        return <div style={{
            display:"flex",
            justifyContent: "space-between"
        }}>
            {this.state.isEdit && <CustomInputGroup>
                <CustomInput value={this.state.spaceName} onChange={(evt) => this.onChange(evt.currentTarget.value)} />
                <SuccessButton maIcon={mdiContentSave} onClick={this.save} />
                <WarningButton maIcon={mdiUndoVariant} onClick={this.cancel} />
            </CustomInputGroup>}
            {!this.state.isEdit && <>
                <span>
                    <SecondaryButton maIcon={this.state.isPublished ? mdiEarth : mdiShield} link onClick={this.tooglePublished} />
                    {this.state.spaceName}
                </span>
                <PrimaryButton maIcon={mdiViewDashboardEdit} onClick={() => this.setState({ isEdit: true })} />
            </>}
        </div>
    }
}

const SpaceList = styled.div`
    > div:not(:first-of-type) {
        margin-top: 0.25rem;
    }

    > div:last-of-type {
        margin-bottom: 0.25rem;
    }
`;
