import { ObjectId } from "bson";
import { AuthService } from "../services/AuthService";
import React from "react";

type TDisplayName = {
    id: ObjectId;
}

type TDisplayNameState = {
    name?: string;
}
class DisplayName extends React.Component<TDisplayName, TDisplayNameState> {
    private _mounted: boolean = false;
    constructor(props: TDisplayName | Readonly<TDisplayName>) {
        super(props);

        this.state = {}
    }

    load = async () => {
        const result = await AuthService.GetById(this.props.id);

        if(result && this._mounted)
        {
            this.setState({
                name: result.display_name
            });
        }
    }

    componentDidMount = () => {
        this._mounted = true;
        this.load();
    }

    componentWillUnmount = () => {
        this._mounted = false;
    }

    render = () => {
        return <span>{this.state.name}</span>
    }
}

export default DisplayName;