import styled from "@emotion/styled";
import { FilterType } from "./Filter";

type TTextFilterDisplayProps = {
    label: string | JSX.Element;
    filter?:{type: FilterType, filter: string};
}

export const TextFilterDisplay = (props:TTextFilterDisplayProps) => {
    if(!props.filter) return null;
    return <TextFilterStyled>
        <label>{props.label}</label> 
        <span>
            {(props.filter.type === FilterType.StartWith || props.filter.type === FilterType.Equal) && "["}
            {props.filter.type === FilterType.EndWith && "..."}
            {props.filter.type === FilterType.Contain && "[..."}
        </span>
        <span style={{
            display: "inline-block",
            maxWidth: "15em",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }}>
            {props.filter.filter}
        </span>
        <span>
            {(props.filter.type === FilterType.EndWith || props.filter.type === FilterType.Equal) && "]"}
            {props.filter.type === FilterType.StartWith && "..."}
            {props.filter.type === FilterType.Contain && "...]"}
        </span>
    </TextFilterStyled>
}

const TextFilterStyled = styled.div`
    display: inline-flex;
    align-items: center;
    > * {
        margin-left: 0.25rem;
    }
`;