import { mdiViewDashboardVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { ISpace } from "../../../realm/schemas/ISpace";
import { TextFilterDisplay } from "../../../utils/List/TextFilterDisplay";
import { ISpaceFilter, SpaceFilterDefault } from "./FilterAndOrder/SpaceFilter";
import { SpaceListItem } from "./SpaceListItem";
import ISpaceOrder, { SpaceOrderDefault } from "./FilterAndOrder/SpaceOrder";
import { ListView, TListViewProps } from "../../../utils/List/ListView";
import { SpaceFilterPopup } from "./FilterAndOrder/SpaceFilterPopup";
import { SpaceOrderPopup } from "./FilterAndOrder/SpaceOrderPopup";
import SpaceService from "../../../services/SpaceService";
import PathGroupsService from "../../../services/PathGroupsService";
import { v4 } from "uuid";
import Localized from "../../../Localization/Localized";

interface ISpaceListProps extends TListViewProps<ISpaceFilter, ISpaceOrder> {
    selectable?: boolean;
    onSelected?: (space: ISpace) => void;
}
export default class SpaceList extends ListView<ISpace, ISpaceFilter, ISpaceOrder, ISpaceListProps>{
    public static PageTitle = () => <><Icon path={mdiViewDashboardVariant} size="1em" /> {Localized.Space.ListTitle}</>;

    get Title(): { simple: string; rich: JSX.Element; } {
        return {
            simple: Localized.Space.ListTitle,
            rich: SpaceList.PageTitle()
        }
    }

    constructor(props: Readonly<TListViewProps<ISpaceFilter, ISpaceOrder>>) {
        super(props);
        PathGroupsService.LoadGroup();
    }

    stateKey(): string {
        return `SL_${this.props.context}`;
    }

    defaultOrder(): ISpaceOrder {
        return SpaceOrderDefault();
    }

    defaultFilter(): ISpaceFilter {
        return SpaceFilterDefault();
    }

    getItems = async () => {
        const result = await SpaceService.All(this.state.filter, this.state.order);
        return result;
    }

    filterChanged = (filter: ISpaceFilter) => {
        this.setState({
            filter: filter
        }, () => this.load());
    }

    orderChanged = (order: ISpaceOrder) => {
        this.setState({
            order: order
        }, () => this.load());
    }

    reload = () => {
        this.setState({
            isReloading: true
        }, this.load);
    }

    getFullScreenElement(exit: () => void): JSX.Element {
        return <SpaceList filter={this.state.filter} order={this.state.order} isFullScreen exitFullScreen={exit} context={this.props.context} />
    }

    getDisplayFilterElements(): JSX.Element[] {
        const showTitleFilter = this.state.filter?.text && this.state.filter?.text.filter && this.state.filter?.text.filter.length > 0;
        const startDateFilter = this.state.filter?.startDate;
        const endDateFilter = this.state.filter?.endDate;

        const filterElements: JSX.Element[] = [];
        if(startDateFilter) filterElements.push(<span key={1}>From {startDateFilter.toFormat("yyyy-MM-dd HH:mm:ss")}</span>);
        if(endDateFilter) filterElements.push(<span key={2}>To {endDateFilter.toFormat("yyyy-MM-dd HH:mm:ss")}</span>);
        if(showTitleFilter) filterElements.push(<TextFilterDisplay key={3} label={Localized.General.Title} filter={this.state.filter?.text} />);

        return filterElements;
    }

    getCustomeActions(): JSX.Element[] {
        return [
            <SpaceOrderPopup key={v4()} order={this.state.order} orderChanged={this.orderChanged} />,
            <SpaceFilterPopup key={v4()} filter={this.state.filter} filterChanged={this.filterChanged} />
        ]
    }

    getItemElement(item: ISpace): JSX.Element {
        let onSelected: undefined | (() => void) = undefined;

        if(this.props.onSelected) onSelected = () => { if(this.props.onSelected) this.props.onSelected(item); };

        return <SpaceListItem key={item._id.toHexString()} space={item} selectable={this.props.selectable} onSelected={onSelected} context={this.props.context} />
    }
}