import styled from "@emotion/styled";
import { useState } from "react";
import { ButtonGroup } from "reactstrap";
import { TListType } from "../../realm/schemas/ICustomList";
import { PrimaryButton, SecondaryButton } from "../../utils/CustomButton";
import { CustomInput } from "../../utils/CustomInput";
import { CustomInputGroup } from "../../utils/CustomInputGroup";
import { CustomInputGroupText } from "../../utils/CustomInputGroupText";
import { DocumentList } from "../Document/DocumentList/DocumentList";
import SpaceList from "../Document/Space/SpaceList";

type TNewCustomListProps = {
    changed: (name: string, list: TListType) => void;
}

export const NewCustomList = (props: TNewCustomListProps) => {
    const [value, setValue] = useState("");
    const [list, setList] = useState<TListType>("Documents");

    const onListChanged = (list: TListType) => {
        setList(list);
        props.changed(value, list);
    }

    return <NewFormStyled>
        <CustomInputGroup>
            <CustomInputGroupText>List name *</CustomInputGroupText>
            <CustomInput value={value} onChange={(evt) => {
                const v = evt.currentTarget.value;
                setValue(v);
                props.changed(v, list);
            }} />
        </CustomInputGroup>
        <ButtonGroup>
            {list === "Documents" && <PrimaryButton>{DocumentList.PageTitle()}</PrimaryButton>}
            {list !== "Documents" && <SecondaryButton onClick={() => onListChanged("Documents")}>{DocumentList.PageTitle()}</SecondaryButton>}
            {list === "Spaces" && <PrimaryButton>{SpaceList.PageTitle}</PrimaryButton>}
            {list !== "Spaces" && <SecondaryButton onClick={() => onListChanged("Spaces")}>{SpaceList.PageTitle}</SecondaryButton>}
        </ButtonGroup>
    </NewFormStyled>
}

const NewFormStyled = styled.div`
    > * {
        margin-top: 0.25rem;
    }
`;