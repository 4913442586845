import { Dialog } from "../../../utils/dialog/DialogManager";
import { IDocumentFilter } from "./IDocumentFilter";
import { DocumentFilterPopupContent } from "./DocumentFilterPopupContent";
import { FilterPopup } from "../../../utils/List/FilterPopup";

export class DocumentFilterPopup extends FilterPopup<IDocumentFilter> {
    open = async (target : Element) => {
        await Dialog.tooltip(<DocumentFilterPopupContent filter={this.props.filter} 
            filterChanged={this.props.filterChanged} />, {
            handle: target,
            dispatchDismiss: (dismiss) => { this._dismiss = dismiss; },
            notModal: false
        });
    };
}
