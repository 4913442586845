import React from "react";
import { IDialogProps } from "./interfaces/IDialogProps";
import { FreeShadow } from "./DialogStyle";
import { IDialog } from "./interfaces/IDialog";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DangerButton } from "../CustomButton";

interface IPictureDialogProps extends IDialogProps {
    dialog:IDialog 
}

export default class PictureDialog extends React.Component<IPictureDialogProps> { 
    getMainElement(): JSX.Element {
        const dialog = this.props.dialog;

        const onClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            evt.stopPropagation();
        }
     
        return (<PictureMainElement onClick={onClick} >
            {dialog.body}
            <DangerButton onClick={() => this.props.dismissHandle(dialog)} ><FontAwesomeIcon icon="times" /></DangerButton>
        </PictureMainElement>);
    } 

    render() {
        const dialog = this.props.dialog;
        const zi = dialog.baseZIndex ?? 25000;

        return (<FreeShadow notModal={false} onClick={() => this.props.dismissHandle(dialog)} 
            style={{zIndex: (zi + this.props.index)}}
            className="dialog-free-shadow">
            {this.getMainElement()}
        </FreeShadow>);        
    }
}

const PictureMainElement = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;

    background-color: rgba(0,0,0, 0.75);

    > button {
        position: absolute;
        top: 5px;
        right: 5px;
    }
`;
