export enum DialogSize {
    NA,
    XS,
    S,
    M,
    L,
    XL,
    Max,
    FullScreen
}
