import Localized from "../../../../Localization/Localized";
import { IOrder, TField } from "../../../../utils/List/Order";

type TSpaceField = TField | "name";

export default interface ISpaceOrder extends IOrder<TSpaceField> {
}

export const SpaceOrderDefault: () => ISpaceOrder = () => {
    return {
        fields: [
            { 
                label: Localized.General.ModificationDate,
                field: "modifiedDateTimeUTC",
                direction: "D"
            },
            { 
                label: Localized.Space.OrderNameLabel,
                field: "name",
                direction: "A"
            },
            { 
                label: Localized.General.CreationDate,
                field: "createdDateTimeUTC",
                direction: "N/A"
            }
        ]
    }
}