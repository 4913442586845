import styled from "@emotion/styled";
import React from "react";
import Localized from "../../Localization/Localized";
import { RealmApp } from "../../realm/RealmApp";
import HeaderService from "../../services/HeaderService";
import { PrimaryButton } from "../../utils/CustomButton";
import { CustomInput } from "../../utils/CustomInput";
import { Msg } from "../../utils/Messenger";

interface IRegisterState {
    email: string;
    isSubmitted: boolean;
    isSubmitting: boolean;
}

export default class SendPasswordReset extends React.Component<{}, IRegisterState> {
  constructor(props: Readonly<{}>){
    super(props);
    this.state = {
      email: "",
      isSubmitted: false,
      isSubmitting: false
    };
  }

  componentDidMount = () => {
    HeaderService.setTitle(Localized.SendPasswordResetView.Title);
  }

  submitHandle = () => {
    this.setState({
      isSubmitting: true
    }, () => {
      RealmApp.emailPasswordAuth.sendResetPasswordEmail(this.state.email).then(() => {
        this.setState({
          isSubmitted: true,
          isSubmitting: false
        });
      })
      .catch(err => {    
        console.log(err);  
        Msg.error(Localized.GeneralError.Unexpected);

        this.setState({
          isSubmitting: false
        });          
      });
    });
  }

  emailChangeHandle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: evt.target.value
    });
  }

  render() {
    return (<ContainerDiv>
        {!this.state.isSubmitted && <RegisterForm>
            <CustomInput name="email" placeholder="Email" onChange={this.emailChangeHandle} />
            <RegisterButton isSubmit disabled={this.state.isSubmitting} onClick={this.submitHandle}>{Localized.SendPasswordResetView.SendButton}</RegisterButton>
        </RegisterForm>}
        {this.state.isSubmitted && <div>
            {Localized.SendPasswordResetView.Successful}
        </div>}
    </ContainerDiv>);
  }
}
const ContainerDiv = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const RegisterButton = styled(PrimaryButton)`
  margin-top: 10px;
  :first-of-type {
    margin-top: 0px;
  }
`;

const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  > *{
    margin-bottom: 0.25rem;
  }
`;
