import styled from "@emotion/styled";
import React from "react";
import Loading, { LoadingDiv } from "../../../../utils/Loading";
import DocumentView from "../DocumentView";
import { ObjectId } from "bson";
import Colors, { ColorSections } from "../../../../utils/Color/MainColors";
import { IDocument } from "../../../../realm/schemas/Document/IDocument";
import { AuthService } from "../../../../services/AuthService";
import DocumentService from "../../../../services/DocumentService";
import Localized from "../../../../Localization/Localized";

export type TDocumentPopupProps = {
    documentId: ObjectId;
    fontSize?: number;
    onClose?: () => void;
    fontSizeHandler?: (handle: (fontSize: number) => void) => void;
}

export type TDocumentPopupState = {
    document?: IDocument;
    isLoading: boolean;
    fontSize: number;
    isOwner: boolean;
}

export default class DocumentPopupBody extends React.Component<TDocumentPopupProps, TDocumentPopupState> {
    constructor(props: TDocumentPopupProps | Readonly<TDocumentPopupProps>) {
        super(props);

        if (props.fontSizeHandler)
            props.fontSizeHandler((fontSize) => {
                this.setState({
                    fontSize: fontSize
                });
            });

        this.state = {
            isOwner: false,
            isLoading: true,
            fontSize: props.fontSize ?? 1.6
        };
    }

    componentDidMount = () => {
        this.Load();
    };

    Load = async () => {
        var result = await DocumentService.GetById(this.props.documentId);

        if (result) {
            this.setState({
                document: result,
                isLoading: false,
                isOwner: AuthService.isOwner(this.state.document?._id)
            });
        }
        else {
            this.setState({
                isLoading: false,
                isOwner: false
            });
        }
    };

    render = () => {
        return <DocumentContainer style={{
            fontSize: `${this.state.fontSize}em`
        }}>
            {this.state.isLoading && <LoadingDiv style={{ fontSize: "2em" }}><Loading /></LoadingDiv>}
            {!this.state.isLoading && this.state.document && <DocumentView document={this.state.document} isReadOnly={this.state.isOwner} />}
            {!this.state.isLoading && !this.state.document && <div>{Localized.Document.NotFound}</div>}
        </DocumentContainer>;
    };
}

export const DocumentContainer = styled.div`
    position: relative;
    background-color: ${Colors.get(ColorSections.BG)};
    color: ${Colors.get(ColorSections.Text)};
`;
