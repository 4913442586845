import styled from "@emotion/styled";
import { mdiLocationExit, mdiPlaylistPlus, mdiPlusCircle, mdiViewDashboard } from "@mdi/js";
import Constants from "../../Constants";
import { ICustomList, TListType } from "../../realm/schemas/ICustomList";
import LocationService from "../../services/LocationService";
import { NoButton, PrimaryButton, SecondaryButton } from "../../utils/CustomButton";
import { Dialog } from "../../utils/dialog/DialogManager";
import { DialogResult } from "../../utils/dialog/enums/DialogResult";
import Loading, { LoadingDiv } from "../../utils/Loading";
import { ObjectId } from "bson";
import { AddItemStyled, CustomListItem } from "./CustomListItem";
import OffcanvasService from "../../services/OffcanvasService";
import { NewCustomList } from "./NewCustomList";
import { IFilter } from "../../utils/List/Filter";
import { ISort } from "../../utils/List/Order";
import { AuthService } from "../../services/AuthService";
import NeedProfilePage, { INeedProfilePageState } from "../profile/NeedProfilePage";
import HeaderService from "../../services/HeaderService";
import Icon from "@mdi/react";
import Localized from "../../Localization/Localized";

type TCustomListViewProps = {
    navigationOrigine?: boolean;
    orientation?: "H" | "V";
    isCollapsible?: boolean;
    isPage?: boolean;
}

interface CustomListViewState extends INeedProfilePageState {
    customLists: ICustomList[];
    isLoading: boolean;
}

export class CustomListsView extends NeedProfilePage<TCustomListViewProps, CustomListViewState> {
    public static PageTitle = () => <><Icon path={mdiViewDashboard} size="1em" /> {Localized.CustomListsView.Title}</>;

    protected initState(props: Readonly<{}>): CustomListViewState {
        return {
            currentProfile: AuthService.currentProfile ?? null,
            customLists: [],
            isLoading: true
        };
    }
    protected profileChanged(): void {
        this.load();
    }

    override didMount: () => void = () => {
        if(this.props.isPage) HeaderService.setTitle(Localized.CustomListsView.Title, CustomListsView.PageTitle());
        this.load();
    }

    load = async () => {
        this.setState({
            customLists: AuthService.currentProfile?.customLists ?? [],
            isLoading: false
        });
    };

    goTo = (path: string) => {
        OffcanvasService.close();
        LocationService.goTo(path);
    }

    add = async () => {
        let newName = "";
        let newList:TListType = "Documents";
        let newSpaceId: ObjectId | undefined = undefined; 

        const onChange = (name: string, list:TListType, spaceId?: ObjectId) => {
            newName = name;
            newList = list;
            newSpaceId = spaceId;
        }

        const result = await Dialog.prompt(Localized.CustomListsView.PopupNewListTitle, <NewCustomList changed={onChange} />, {
            onOk: () => {
                return new Promise<boolean>(resolve => {
                    if(newName && newName.trim().length > 0) return resolve(true);
                    resolve(false);
                });
            }
        });

        if(result === DialogResult.Canceled || !newName || newName.trim().length === 0) return;

        const lists = this.state.customLists;
        lists.push({
            _id: new ObjectId(),
            name: newName,
            list: newList,
            spaceId: newSpaceId
        });

        this.setState({
            customLists: lists
        });
    }

    save = async (index: number, fo:[IFilter, ISort]) => {
        const list = this.state.customLists;
        const cl = list[index];
        cl.filter = fo[0];
        cl.order = fo[1];
        var result = await AuthService.updateCustomLists(list);
        return result;
    }

    render = () => {
        
        return <div>
            <CanvasTopActionsStyled>
                <PrimaryButton maIcon={mdiPlaylistPlus} link onClick={this.add} />
                {!this.props.navigationOrigine && <SecondaryButton maIcon={mdiLocationExit} link onClick={() => this.goTo("/Documents/CustomLists")} />}
            </CanvasTopActionsStyled>
            <div style={{
                paddingRight: this.props.navigationOrigine ? "0" : "0.5rem",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row"
            }}>
                {this.state.isLoading && <LoadingDiv><Loading style={{ fontSize: "2em" }} /></LoadingDiv>}
                {this.state.customLists.map((m, i) => <CustomListItem key={m._id.toHexString()} customList={m} orientation={this.props.orientation} save={async (fo) => this.save(i, fo)} isCollapsible={this.props.isCollapsible} />)}
                <AddItemStyled orientation={this.props.orientation} style={{
                    cursor: "pointer"
                }} onClick={this.add} ><NoButton maIcon={mdiPlusCircle} style={{
                    fontSize: "3em"
                }} /></AddItemStyled>
            </div>
        </div>;
    };
}

const CanvasTopActionsStyled = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;

    > * {
        opacity: ${Constants.ActionDefaultOpacity};

        :hover {
            opacity: 1;
        }
    }
`;
