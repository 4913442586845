import LocalizedStrings from "react-localization";
import Locals from './Module/Locals.json';
import { IGeneralStrings, IGeneralErrorStrings, IEmailConfirmationStrings, ILoginFormStrings, IPasswordResetFormStrings, IPasswordErrorStrings, ICustomListsViewStrings, IRegisterViewStrings, ISendPasswordResetViewStrings, IDocumentListViewStrings, IDocumentStrings, ISpaceStrings, IProfileStrings, IAuthServiceStrings } from "./LocalizedStrings";

export default class Localized {
    public static Languages = [
        {
            code: "fr",
            display: "Français"
        },
        {
            code: "en",
            display: "English"
        }
    ];

    public static GetQueryString = () => {
        const params = new URLSearchParams();
        params.append("Lang", Localized.General.getLanguage());
        return params;
    };

    public static setLanguage = (lang: string) => {
        Localized.General.setLanguage(lang);
        Localized.GeneralError.setLanguage(lang);
        Localized.EmailConfirmationView.setLanguage(lang);
        Localized.LoginView.setLanguage(lang);
        Localized.PasswordResetView.setLanguage(lang);
        Localized.PasswordError.setLanguage(lang);
        Localized.CustomListsView.setLanguage(lang);
        Localized.RegisterView.setLanguage(lang);
        Localized.SendPasswordResetView.setLanguage(lang);
        Localized.DocumentListView.setLanguage(lang);
        Localized.Document.setLanguage(lang);
        Localized.Space.setLanguage(lang);
        Localized.Profile.setLanguage(lang);
    };

    public static format = (source: string, ...value: string[]) => {
        const f = Localized.General.formatString(source, ...value);
        if (Array.isArray(f))
            return f.join(" ");
        return f;
    };

    public static General: IGeneralStrings = new LocalizedStrings(Locals.General);

    public static GeneralError: IGeneralErrorStrings = new LocalizedStrings(Locals.GeneralError);

    public static EmailConfirmationView: IEmailConfirmationStrings = new LocalizedStrings(Locals.EmailConfirmationView);

    public static LoginView: ILoginFormStrings = new LocalizedStrings(Locals.LoginView);

    public static PasswordResetView: IPasswordResetFormStrings = new LocalizedStrings(Locals.PasswordResetView);

    public static PasswordError: IPasswordErrorStrings = new LocalizedStrings(Locals.PasswordError);

    public static CustomListsView: ICustomListsViewStrings = new LocalizedStrings(Locals.CustomListsView);

    public static RegisterView: IRegisterViewStrings = new LocalizedStrings(Locals.RegisterView);

    public static SendPasswordResetView: ISendPasswordResetViewStrings = new LocalizedStrings(Locals.SendPasswordResetView);

    public static DocumentListView: IDocumentListViewStrings = new LocalizedStrings(Locals.DocumentListView);

    public static Document: IDocumentStrings = new LocalizedStrings(Locals.Document);

    public static Space: ISpaceStrings = new LocalizedStrings(Locals.Space);

    public static Profile: IProfileStrings = new LocalizedStrings(Locals.Profile);

    public static AuthService: IAuthServiceStrings = new LocalizedStrings(Locals.AuthService);
    
}
