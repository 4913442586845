import styled from "@emotion/styled";
import { DateTime } from "luxon";
import DateHelper from "../../../utils/date/DateHelper";
import DisplayName from "../../../utils/DisplayName";
import { ObjectId } from "bson";
import { EllipsisSpanStyled } from "../../../styles/ListStyled";
import Localized from "../../../Localization/Localized";

type TUserAndTimeInfosprops = {
    dateTimeUTC: Date,
    userId: ObjectId;
}

const UserAndTimeInfos = (props: TUserAndTimeInfosprops) => {
    const dt = DateTime.fromJSDate(props.dateTimeUTC).toLocal().toFormat(DateHelper.dateTimeFormatShort);
    return <UserAndTimeInfosStyled>
        <DisplayName id={props.userId} />
        <span>{Localized.General.OnDate}</span>
        <EllipsisSpanStyled title={dt}>{dt}</EllipsisSpanStyled>
    </UserAndTimeInfosStyled>
}

const UserAndTimeInfosStyled = styled(EllipsisSpanStyled)`
    > span:not(:last-of-type) {
        margin-right: 0.25rem;
    }
`;

export default UserAndTimeInfos;