import { useEffect, useState } from "react";
import RightOffcanvasService from "../../../services/RightOffcanvasService";
import { OffcanvasChange } from "../../../services/OffcanvasChange";
import OffcanvasService, { Direction } from "../../../services/OffcanvasService";
import Icon from "@mdi/react";
import { mdiDotsVertical } from "@mdi/js";
import { HeaderActionStyled } from "../HeaderActionStyled";


type TRightOffcanvasProps = {

}

export const RightOffcanvas:React.FC<TRightOffcanvasProps> = (props: TRightOffcanvasProps) => {
    const open = () => {
        OffcanvasService.open({
            header: <RightHeader />,
            body: <RightBody />,
            direction: Direction.End,
            backdrop: false
        });
    }

    return <>
        <HeaderActionStyled onClick={open}><Icon path={mdiDotsVertical} size="1.5em" /></HeaderActionStyled>
    </>
}

const RightHeader = () => {
    const [header, setHeader] = useState(RightOffcanvasService.Header);
    useEffect(() => {
        RightOffcanvasService.subscribeChange("LeftOffcanvasHeader", (field) => {
            if(field === OffcanvasChange.Header) {
                setHeader(RightOffcanvasService.Header);
            }
        });

        return () => {
            RightOffcanvasService.unsubscribeChange("LeftOffcanvasHeader");
        }
    });

    return <>
        {header}
    </>
}

const RightBody = () => {
    const [body, setBody] = useState(RightOffcanvasService.Body);
    useEffect(() => {
        RightOffcanvasService.subscribeChange("LeftOffcanvasBody", (field) => {
            if(field === OffcanvasChange.Body) {
                setBody(RightOffcanvasService.Body);
            }
        });

        return () => {
            RightOffcanvasService.unsubscribeChange("LeftOffcanvasBody");
        }
    });

    return <>
        {body}
    </>
}

