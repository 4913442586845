import Observer from "../utils/Observer";
import { OffcanvasChange } from "./OffcanvasChange";

export default class RightOffcanvasService  extends Observer {
    public static readonly RightOffcanvasChangedEventKey = "RightOffcanvasChangedEventKey"

    private static readonly _instance: RightOffcanvasService = new RightOffcanvasService();
    public static get Instance() {
        return this._instance;
    }

    private _canvas: Array<[header:string | Element | JSX.Element, body: string | Element | JSX.Element]> = [];

    public static get Header(): Element | string | JSX.Element | null {
        const l = this.Instance._canvas.length;
        if(l === 0) return null;
        return this.Instance._canvas[l - 1][0];
    }

    public static get Body(): Element | string | JSX.Element | null {
        const l = this.Instance._canvas.length;
        if(l === 0) return null;
        return this.Instance._canvas[l - 1][1];
    } 

    public static Push(canvas: [header:string | Element | JSX.Element, body: string | Element | JSX.Element]) {
        this.Instance._canvas.push(canvas);
        this.Instance.notifyChange(OffcanvasChange.All);
    }

    public static Pop() {
        const canvas = this.Instance._canvas.pop();
        this.Instance.notifyChange(OffcanvasChange.All);
        return canvas;
    }

    static subscribeChange(key: string, callBack: (field?: string, value?: any) => void): void {
        this.Instance.subscribeChange(this.RightOffcanvasChangedEventKey, key, callBack);
    }
    
    static unsubscribeChange(key: string): void {
        this.Instance.unsubscribeChange(this.RightOffcanvasChangedEventKey, key);
    }
}