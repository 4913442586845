import styled from "@emotion/styled";
import { mdiCancel, mdiContentSave, mdiFileDownload, mdiFileUpload, mdiPalette, mdiWeatherNight } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { RGBColor } from "react-color";
import { Button, ButtonGroup, InputGroup, UncontrolledAlert } from "reactstrap";
import { ColorPickerBase } from "../utils/Color/ColorPicker";
import Colors, { ColorSections } from "../utils/Color/MainColors";
import { InfoButton, PrimaryButton, SecondaryButton, SuccessButton, WarningButton } from "../utils/CustomButton";
import { TooltipItem, TooltipPopup } from "../utils/TooltipPopup";
import { TooltipSeparator } from "../utils/Separator";
import { FreeDialogContent } from "../utils/dialog/FreeDialog";
import { IFreeDialog } from "../utils/dialog/interfaces/IFreeDialog";
import { DialogType } from "../utils/dialog/enums/DialogType";
import { DialogSize } from "../utils/dialog/enums/DialogSize";
import { IDialog } from "../utils/dialog/interfaces/IDialog";
import { CustomInput } from "../utils/CustomInput";
import { CustomInputGroupText } from "../utils/CustomInputGroupText";
import { Dialog } from "../utils/dialog/DialogManager";
import { Msg } from "../utils/Messenger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Clipboard from 'react-clipboard.js';
import { DialogResult } from "../utils/dialog/enums/DialogResult";
import { AuthService } from "../services/AuthService";
import NeedProfilePage, { INeedProfilePageState } from "./profile/NeedProfilePage";
import Loading, { LoadingDiv } from "../utils/Loading";
import HeaderService from "../services/HeaderService";
import Localized from "../Localization/Localized";

// mdiThemeLightDark

//mdiWeatherNight
//mdiWeatherSunny

interface ColorsProfileState extends INeedProfilePageState {
    colorsProfile: Colors;
    old: Colors
}
export default class ColorsProfile extends NeedProfilePage<{}, ColorsProfileState> {
    protected didMount(): void {
        HeaderService.setTitle(Localized.Profile.ColorsProfilePageTitle, ColorsProfile.PageTitle());
    }

    public static PageTitle = () => <><Icon path={mdiPalette} size="1em" /> {Localized.Profile.ColorsProfilePageTitle}</>;

    protected override initState(props: Readonly<{}>): ColorsProfileState {
        return {
            currentProfile: AuthService.currentProfile ?? null,
            colorsProfile: Colors.Current,
            old: Colors.Current.clone()
        }
    }
    
    protected profileChanged(): void {
        this.setState({
            colorsProfile: AuthService.getCurrentColorsProfile()
        });
    }

    change = (key:ColorSections, color: RGBColor) => {
        Colors.Current.set(key, Colors.rgbToHex(color));
        this.setState(state => {
            const newProfile = state.colorsProfile;
            return {
                colorsProfile: newProfile.set(key, Colors.rgbToHex(color))
            }
        });
    }

    cancel = (key:ColorSections) => {
        console.log(this.state.colorsProfile.get(key), this.state.old.get(key), );
        Colors.Current.set(key, this.state.old.get(key));
        this.setState(state => {
            const newProfile = state.colorsProfile;
            return {
                colorsProfile: newProfile.set(key, this.state.old.get(key)).clone()
            }
        });
    }

    showExtractedColorProfile = () => {
        const onSuccessHandle = () => {
            Msg.success(Localized.Profile.ColorsProfileCopiedClipboard);
        }

        Dialog.config(Localized.Profile.ExtractedColorProfilePopupTitle, <div>
            <Clipboard component="a" 
                data-clipboard-text={Colors.Current.toJson()} 
                button-title={Localized.Profile.ExtractedColorProfilePopupButtonTitle} 
                onSuccess={onSuccessHandle}
                style={{
                    position: "absolute",
                    top: 45,
                    right: 10
                }}>                  
                    <FontAwesomeIcon icon="paste" />
            </Clipboard>
            <textarea defaultValue={Colors.Current.toJson()} readOnly style={{
                width: "100%",
                minHeight: "25vh"
            }} />
        </div>);
    }

    loadColorProfile = () => {
        let jsonToLoad = "";
        const onChange = (json: string) => {
            jsonToLoad = json;
        }
        Dialog.prompt(Localized.Profile.LoadColorProfilePopupTitle, <ColorProfileLoader onChanged={onChange} />).then(result => {
            if(result === DialogResult.Ok) {
                Colors.LoadFromJson(jsonToLoad);
                this.setState({
                    colorsProfile: Colors.Current.clone()
                });
            }
        });
    }

    loadDefaultDarkColorProfile = () => {
        Colors.Current = Colors.DefaultDark.clone();
        this.setState({
            colorsProfile: Colors.DefaultDark.clone()
        });
    }

    save = () => {
        AuthService.updateColorsPreferences(Colors.Current.toJson());
    }

    override render = () => {
        if(this.state.currentProfile === null) return <LoadingDiv style={{
            fontSize: "3em"
        }}><Loading /></LoadingDiv>;

        const d: IFreeDialog = {
            id: "",
            type: DialogType.Confirm,
            header: Localized.Profile.ColorsProfileExPopupHeader,
            body: Localized.Profile.ColorsProfileExPopupBody,
            dismiss: function (): void {
                
            },
            cancel: function (): void {
                
            },
            ok: function (): void {
                
            },
            size: DialogSize.S
        }

        const phButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnPrimaryHoverBG), 
            borderColor: Colors.get(ColorSections.BtnPrimaryHoverBorder),
            color: Colors.get(ColorSections.BtnPrimaryHoverColor)
        } 

        const paButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnPrimaryActiveBG), 
            borderColor: Colors.get(ColorSections.BtnPrimaryActiveBorder),
            color: Colors.get(ColorSections.BtnPrimaryActiveColor)
        }

        const pfButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnPrimaryFocusBG), 
            borderColor: Colors.get(ColorSections.BtnPrimaryFocusBorder),
            color: Colors.get(ColorSections.BtnPrimaryFocusColor),
            boxShadow: `0 0 0 0.25rem ${Colors.get(ColorSections.BtnPrimaryFocusBoxShadow, 0.5)}`
        } 

        const shButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnSecondaryHoverBG), 
            borderColor: Colors.get(ColorSections.BtnSecondaryHoverBorder),
            color: Colors.get(ColorSections.BtnSecondaryHoverColor)
        } 

        const saButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnSecondaryActiveBG), 
            borderColor: Colors.get(ColorSections.BtnSecondaryActiveBorder),
            color: Colors.get(ColorSections.BtnSecondaryActiveColor)
        }

        const sfButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnSecondaryFocusBG), 
            borderColor: Colors.get(ColorSections.BtnSecondaryFocusBorder),
            color: Colors.get(ColorSections.BtnSecondaryFocusColor),
            boxShadow: `0 0 0 0.25rem ${Colors.get(ColorSections.BtnSecondaryFocusBoxShadow, 0.5)}`
        } 

        const sshButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnSuccessHoverBG), 
            borderColor: Colors.get(ColorSections.BtnSuccessHoverBorder),
            color: Colors.get(ColorSections.BtnSuccessHoverColor)
        } 

        const ssaButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnSuccessActiveBG), 
            borderColor: Colors.get(ColorSections.BtnSuccessActiveBorder),
            color: Colors.get(ColorSections.BtnSuccessActiveColor)
        }

        const ssfButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnSuccessFocusBG), 
            borderColor: Colors.get(ColorSections.BtnSuccessFocusBorder),
            color: Colors.get(ColorSections.BtnSuccessFocusColor),
            boxShadow: `0 0 0 0.25rem ${Colors.get(ColorSections.BtnSuccessFocusBoxShadow, 0.5)}`
        } 

        const whButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnWarningHoverBG), 
            borderColor: Colors.get(ColorSections.BtnWarningHoverBorder),
            color: Colors.get(ColorSections.BtnWarningHoverColor)
        } 

        const waButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnWarningActiveBG), 
            borderColor: Colors.get(ColorSections.BtnWarningActiveBorder),
            color: Colors.get(ColorSections.BtnWarningActiveColor)
        }

        const wfButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnWarningFocusBG), 
            borderColor: Colors.get(ColorSections.BtnWarningFocusBorder),
            color: Colors.get(ColorSections.BtnWarningFocusColor),
            boxShadow: `0 0 0 0.25rem ${Colors.get(ColorSections.BtnWarningFocusBoxShadow, 0.5)}`
        }

        const ihButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnInfoHoverBG), 
            borderColor: Colors.get(ColorSections.BtnInfoHoverBorder),
            color: Colors.get(ColorSections.BtnInfoHoverColor)
        } 

        const iaButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnInfoActiveBG), 
            borderColor: Colors.get(ColorSections.BtnInfoActiveBorder),
            color: Colors.get(ColorSections.BtnInfoActiveColor)
        }

        const ifButtonClass: React.CSSProperties = {
            backgroundColor: Colors.get(ColorSections.BtnInfoFocusBG), 
            borderColor: Colors.get(ColorSections.BtnInfoFocusBorder),
            color: Colors.get(ColorSections.BtnInfoFocusColor),
            boxShadow: `0 0 0 0.25rem ${Colors.get(ColorSections.BtnInfoFocusBoxShadow, 0.5)}`
        }

        const colors = this.state.colorsProfile;
        return <Container>
            <div style={{ width: "80%", backgroundColor: "silver", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                <div style={{ backgroundColor: colors.get(ColorSections.BG), color: colors.get(ColorSections.Text), width: "90%", height: "90%", margin: "auto", overflow: "hidden" }}>
                    <h1 style={{ backgroundColor: colors.get(ColorSections.HeaderBG), color: colors.get(ColorSections.HeaderText) }}>{Localized.General.Header}</h1>
                    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                        <div style={{ width: "20%", height: "100%", 
                            backgroundColor: colors.get(ColorSections.OffcanvasBobyBG), 
                            color: colors.get(ColorSections.OffcanvasBodyText), overflow: "hidden" }}>
                            <h4 style={{ backgroundColor: colors.get(ColorSections.OffcanvasHeaderBG), color: colors.get(ColorSections.OffcanvasHeaderText) }}>{Localized.Profile.ColorsProfileExOffcanvasHeader}</h4>
                            {Localized.Profile.ColorsProfileExOffcanvasBody}
                        </div>
                        <ContentItem style={{ width: "80%", padding: "0.5rem", overflow: "scroll", paddingBottom: "40px" }}>
                            <div>
                                {Localized.Profile.ColorsProfileExNormalText}
                            </div>
                            <div>
                                <TooltipPopup colors={colors}>
                                    <TooltipItem>{Localized.Profile.ColorsProfileExTooltipItem}</TooltipItem>
                                    <TooltipSeparator />
                                    <TooltipItem>{Localized.Profile.ColorsProfileExTooltipItem}</TooltipItem>
                                </TooltipPopup>
                            </div>
                            <UAlert2 style={{ 
                                backgroundColor : colors.get(ColorSections.DangerBG),
                                borderColor: colors.get(ColorSections.DangerBorder),
                                color: colors.get(ColorSections.DangerColor) }} onClick={() => {}} >
                                {Localized.Profile.ColorsProfileExAlertError}
                            </UAlert2>
                            <UAlert2 style={{ 
                                backgroundColor : colors.get(ColorSections.WarningBG),
                                borderColor: colors.get(ColorSections.WarningBorder),
                                color: colors.get(ColorSections.WarningColor) }} onClick={() => {}} >
                                {Localized.Profile.ColorsProfileExAlertWarning}
                            </UAlert2>
                            <UAlert2 style={{ 
                                backgroundColor : colors.get(ColorSections.InfoBG),
                                borderColor: colors.get(ColorSections.InfoBorder),
                                color: colors.get(ColorSections.InfoColor) }} onClick={() => {}} >
                                {Localized.Profile.ColorsProfileExAlertInfo}
                            </UAlert2>
                            <UAlert2 style={{ 
                                backgroundColor : colors.get(ColorSections.SuccessBG),
                                borderColor: colors.get(ColorSections.SuccessBorder),
                                color: colors.get(ColorSections.SuccessColor) }} onClick={() => {}} >
                                {Localized.Profile.ColorsProfileExAlertSuccess}
                            </UAlert2>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <span>{Localized.General.Default}</span>
                                <PrimaryButton>{Localized.General.Primary}</PrimaryButton>
                                <SecondaryButton>{Localized.General.Secondary}</SecondaryButton>
                                <SuccessButton>{Localized.General.Success}</SuccessButton>
                                <WarningButton>{Localized.General.Warning}</WarningButton>
                                <InfoButton>{Localized.General.Info}</InfoButton>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <span>{Localized.General.Hover}&nbsp;&nbsp;</span>
                                <Button style={phButtonClass} size="sm">{Localized.General.Primary}</Button>
                                <Button style={shButtonClass} size="sm">{Localized.General.Secondary}</Button>
                                <Button style={sshButtonClass} size="sm">{Localized.General.Success}</Button>
                                <Button style={whButtonClass} size="sm">{Localized.General.Warning}</Button>
                                <Button style={ihButtonClass} size="sm">{Localized.General.Info}</Button>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <span>{Localized.General.Active}&nbsp;&nbsp;</span>
                                <Button style={paButtonClass} size="sm">{Localized.General.Primary}</Button>
                                <Button style={saButtonClass} size="sm">{Localized.General.Secondary}</Button>
                                <Button style={ssaButtonClass} size="sm">{Localized.General.Success}</Button>
                                <Button style={waButtonClass} size="sm">{Localized.General.Warning}</Button>
                                <Button style={iaButtonClass} size="sm">{Localized.General.Info}</Button>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <span>{Localized.General.Focus}&nbsp;&nbsp;</span>
                                <Button style={pfButtonClass} size="sm">{Localized.General.Primary}</Button>
                                <Button style={sfButtonClass} size="sm">{Localized.General.Secondary}</Button>
                                <Button style={ssfButtonClass} size="sm">{Localized.General.Success}</Button>
                                <Button style={wfButtonClass} size="sm">{Localized.General.Warning}</Button>
                                <Button style={ifButtonClass} size="sm">{Localized.General.Info}</Button>
                            </div>
                            <div>
                                <FreeDialogContent
                                    dialog={d} setBodyElement={function (el: HTMLDivElement | null | undefined): void { } } 
                                    setFooterElement={function (el: HTMLDivElement | null | undefined): void {} } 
                                    toogleBody={function (): void { } } 
                                    dismissHandle={function (d: IDialog): void {} } 
                                    okHandle={function (d: IDialog): void {} } 
                                    cancelHandle={function (d: IDialog): void {} }  
                                />
                            </div>
                            <div>
                                <InputGroup>
                                    <CustomInputGroupText>{Localized.General.Text}</CustomInputGroupText>
                                    <CustomInput placeholder={Localized.General.Watermark} />
                                    <CustomInput defaultValue={Localized.General.Value} />
                                </InputGroup>
                            </div>
                        </ContentItem>
                    </div>
                </div>
                <div style={{
                    position: "absolute",
                    right: 0,
                    top: 0
                }}>
                    <ButtonGroup>
                        <SecondaryButton title={Localized.Profile.ColorsProfileExLoadFromDefaultDarkTooltip} onClick={this.loadDefaultDarkColorProfile} ><Icon path={mdiWeatherNight} size="1.5em" /></SecondaryButton>
                        <SecondaryButton title={Localized.Profile.ColorsProfileExExtractTooltip} onClick={this.showExtractedColorProfile} ><Icon path={mdiFileDownload} size="1.5em" /></SecondaryButton>
                        <SecondaryButton title={Localized.Profile.ColorsProfileExLoadTooltip} onClick={this.loadColorProfile}><Icon path={mdiFileUpload} size="1.5em" /></SecondaryButton>
                        <PrimaryButton title={Localized.Profile.ColorsProfileExSaveTooltip} onClick={this.save}><Icon path={mdiContentSave} size="1.5em" /></PrimaryButton>
                    </ButtonGroup>
                </div>
            </div>
            <div style={{ width: "20%", overflow: "scroll", height: "calc(100vh - 75px)"}}>
                {this.state.colorsProfile.colors.map(c => <ColorContainer key={`${c.key}-${c.value}`}>
                    <span style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        direction: "rtl",
                        marginRight: "0.25rem"
                    }}>{ColorSections[c.key]}</span>
                    <ButtonGroup>
                        <ColorPickerBase color={Colors.hexToRgb(c.value)} onChange={(color) => this.change(c.key, color)} />
                        <WarningButton onClick={() => this.cancel(c.key)}><Icon path={mdiCancel} size="1em" /></WarningButton>
                    </ButtonGroup>
                </ColorContainer>)}
            </div>
        </Container>
    }
}

type ColorProfileLoaderProps = {
    onChanged: (json: string) => void;
}

const ColorProfileLoader = (props: ColorProfileLoaderProps) => {
    const [value, setValue] = useState("");

    const onChange = (value: string) => {
        props.onChanged(value);
        setValue(value);
    }
    return <textarea value={value} style={{
        width: "100%",
        minHeight: "25vh"
    }} onChange={(evt) => onChange(evt.currentTarget.value)} />
}

const ContentItem = styled.div`
    > * {
        margin-bottom: 0.25rem;
    }
`;

export const UAlert2 = styled(UncontrolledAlert)`
    pointer-events: none;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const ColorContainer = styled.div`
    padding: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;