import React from "react";
import { IDialogProps } from "./interfaces/IDialogProps";
import { ITooltipDialog } from "./interfaces/IFreeDialog";
import { FreeMainElement, FreeBodyElement, FreeShadow } from "./DialogStyle";
import ZI from "../../styles/ZIndex";

type TTooltipState = {
    mainElementStyle?: React.CSSProperties;
    bodyElementStyle?: React.CSSProperties;
}


interface ITooltipProps extends IDialogProps {
    dialog:ITooltipDialog 
}

export default class TooltipDialog extends React.Component<ITooltipProps, TTooltipState> { 
    mainElement?: HTMLDivElement | null;
    headerElement?: HTMLDivElement | null;
    bodyElement?: HTMLDivElement | null;
    constructor(props: Readonly<IDialogProps>) {
        super(props);

        this.state = {
            mainElementStyle: undefined
        };

        const dialog = this.props.dialog;

        if(dialog.dispatchResize){             
            dialog.dispatchResize(()=> {
                this.rePosition();
            });
        }

        if(dialog.dispatchDismisse){             
            dialog.dispatchDismisse(()=> {
                this.props.dismissHandle(dialog);
            });
        }        
    }

    private _resize = () => {
        this.rePosition();
    } 

    componentDidMount() {
        $(window).on("resize", this._resize);
        this.rePosition();
    }

    componentWillUnmount = () => {
        $(window).off("resize", this._resize);
    }

    rePosition(): void {
        const dialog = this.props.dialog;
        if(!dialog.handle) return undefined;

        const element = dialog.handle;
        const bounding = element.getBoundingClientRect();

        const mainBounding = this.mainElement?.getBoundingClientRect();

        if(!mainBounding) return undefined;      
        
        const headerBounding = this.headerElement?.getBoundingClientRect();

        const bodyStyle: React.CSSProperties = {
            maxHeight: `calc(95vh - ${headerBounding ? headerBounding?.height : 0}px)`
        }

        this.setState({
            bodyElementStyle: bodyStyle
        }, () => {
            const wh = window.innerHeight;

            const ww = window.innerWidth;
            
            const wOffset = mainBounding.width + bounding.left > ww ? mainBounding.width - (ww - bounding.left) + 10 : 0;
    
            const hOffset = mainBounding.height + bounding.bottom > wh ? mainBounding.height - (wh - bounding.bottom) + 10 : 0;
    
            this.setState({
                mainElementStyle: {
                    position: "absolute",
                    top: `${bounding.bottom - hOffset}px`,
                    left:`${bounding.left - wOffset}px`,
                } 
            });      
        });
    }

    getMainElement(): JSX.Element {
        const dialog = this.props.dialog;

        const onClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            evt.stopPropagation();
        }
     
        return (<FreeMainElement onClick={onClick} style={this.state.mainElementStyle} ref={(div) => this.mainElement = div} >
        <div style={dialog.windowStyle}>
            <FreeBodyElement ref={(div) => this.bodyElement = div}>
                {dialog.body}
            </FreeBodyElement>
        </div>
    </FreeMainElement>);
    } 

    render() {
        const dialog = this.props.dialog;
        const notModal = dialog.notModal ? dialog.notModal : false;
        const zi = dialog.baseZIndex ?? ZI.Tooltip;

        return (<FreeShadow notModal={notModal} onClick={() => this.props.dismissHandle(dialog)} 
            style={{zIndex: (zi + this.props.index)}}
            className="dialog-free-shadow">
            {this.getMainElement()}
        </FreeShadow>);        
    }
}
