import styled from "@emotion/styled";
import React from "react";
import Localized from "../../Localization/Localized";
import { RealmApp } from "../../realm/RealmApp";
import HeaderService from "../../services/HeaderService";
import { PrimaryButton } from "../../utils/CustomButton";
import { CustomInput } from "../../utils/CustomInput";
import { Msg } from "../../utils/Messenger";

export const PasswordValidation = (password: string) => {
  const passwordErrors = [];
  if (!/[a-z]/.test(password))
    passwordErrors.push(Localized.PasswordError.AtLeast1Lowercase);
  if (!/[A-Z]/.test(password))
    passwordErrors.push(Localized.PasswordError.AtLeast1Uppercase);
  if (!/[0-9]/.test(password))
    passwordErrors.push(Localized.PasswordError.AtLeast1Numeric);
  if (!/[!@#\\$%\\^&\\*]/.test(password))
    passwordErrors.push(Localized.PasswordError.AtLeast1Special);
  if (password.length < 8)
    passwordErrors.push(Localized.PasswordError.AtLeast8Characters);
  if (passwordErrors.length > 0) {
    return Localized.format(Localized.PasswordError.InvalidPassword, passwordErrors.join(' ; ')) ?? "";
  }

  return null;
}

interface IRegisterState {
    email: string;
    password: string;
    isSubmitted: boolean;
    isSubmitting: boolean;
}

type TErrors = {
  email: string | null,
  password: string | null
}

export default class Register extends React.Component<{}, IRegisterState> {
  constructor(props: Readonly<{}>){
    super(props);
    this.state = {
      email: "",
      password: "",
      isSubmitted: false,
      isSubmitting: false
    };
  }

  componentDidMount = () => {
    HeaderService.setTitle(Localized.RegisterView.Title);
  }

  submitHandle = () => {
    this.setState({
      isSubmitting: true
    }, () => {

      const errors = this.validate();

      if(errors) {
        console.log(errors);
        return;
      }

      RealmApp.emailPasswordAuth.registerUser(this.state.email, this.state.password).then(() => {
        this.setState({
          isSubmitted: true,
          isSubmitting: false
        });
      })
      .catch(err => {      
        if(err.message === "name already in use") Msg.error(Localized.RegisterView.ErrorRegisteringNewUser);
        else Msg.error(Localized.GeneralError.Unexpected);

        this.setState({
          isSubmitting: false
        });          
      });

    });

  }

  validate(): TErrors | null {
    let errors: TErrors = {
      email: null,
      password: null      
    };
        
    if (!this.state.email) {
      errors.email = Localized.GeneralError.Required;
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) {
      errors.email = Localized.GeneralError.InvalidEmail;
    }
    if (!this.state.password) {
      errors.password = Localized.GeneralError.Required;
    }
    else {
      errors.password = PasswordValidation(this.state.password);
    }

    if(errors.email || errors.password) return errors

    return null;
  }

  emailChangeHandle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: evt.target.value
    });
  }

  passwordChangeHandle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: evt.target.value
    });
  }

  render() {
    return (<ContainerDiv>
        {!this.state.isSubmitted && <RegisterForm>
            <CustomInput type="email" name="email" placeholder="Email" onChange={this.emailChangeHandle} />
            <CustomInput type="password" name="password" placeholder="Password" onChange={this.passwordChangeHandle} />
            <RegisterButton isSubmit disabled={this.state.isSubmitting} onClick={this.submitHandle}>{Localized.RegisterView.Title}</RegisterButton>
        </RegisterForm>}
        {this.state.isSubmitted && <div>
            {Localized.RegisterView.Successful}
        </div>}
    </ContainerDiv>);
  }
}
export const ContainerDiv = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const RegisterButton = styled(PrimaryButton)`
  margin-top: 10px;
  :first-of-type {
    margin-top: 0px;
  }
`;

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  > *{
    margin-bottom: 0.25rem;
  }
`;
