export default class GlobalStateService {
    private static readonly _instance: GlobalStateService = new GlobalStateService();
    public static get Instance() {
        return GlobalStateService._instance;
    }

    private _states: Map<string, any> = new Map<string, any>();

    static Set = (key: string, state: any) => {
        this.Instance._states.set(key, state);
    }

    static Get = (key: string) => {
        return this.Instance._states.has(key) ? this.Instance._states.get(key) : null;
    }
}