import { Dialog } from "../../../../utils/dialog/DialogManager";
import { FilterPopup } from "../../../../utils/List/FilterPopup";
import { ISpaceFilter } from "./SpaceFilter";
import { SpaceFilterPopupContent } from "./SpaceFilterPopupContent";


export class SpaceFilterPopup extends FilterPopup<ISpaceFilter> {
    open = async (target : Element) => {
        await Dialog.tooltip(<SpaceFilterPopupContent filter={this.props.filter} 
            filterChanged={this.props.filterChanged} />, {
            handle: target,
            dispatchDismiss: (dismiss) => { this._dismiss = dismiss; },
            notModal: false
        });
    };
}