import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "@emotion/styled";

export default function Loading(props:any) {
    return (<FontAwesomeIcon icon="spinner" pulse {...props} />);
}


export function LoadingFile(props:any) {
    return (<span className="fa-layers fa-fw" {...props}>
    <FontAwesomeIcon icon={["far", "file"]} />
    <FontAwesomeIcon icon="spinner" pulse transform="shrink-8 down-2" />
  </span>);
}

export const LoadingAbsDiv = styled.div`
    z-index: 2000;
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, 0.25);
`;

export const LoadingDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, 0.25);
    padding:0.25rem;
`;