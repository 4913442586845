import Observer from "../utils/Observer";
import { Location } from 'react-router-dom';

export default class LocationService extends Observer {
    public static readonly LocationChangedEventKey = "LocationChangedEventKey";
    private static readonly _instance: LocationService = new LocationService();
    public static get Instance() {
        return LocationService._instance;
    }

    public static goTo = (path: string | Location) => {
        this.Instance.notifyChange(this.LocationChangedEventKey, "To", path);
    }

    static subscribeChange(key: string, callBack: (field?: string, value?: any) => void): void {
        this.Instance.subscribeChange(this.LocationChangedEventKey, key, callBack);
    }
    
    static unsubscribeChange(key: string): void {
        this.Instance.unsubscribeChange(this.LocationChangedEventKey, key);
    }
}
