import { ObjectId } from "bson";
import { UMongoDB } from "../realm/MongoDB";
import { Msg } from "../utils/Messenger";
import IDocument from "../realm/schemas/Document/IDocument";
import Constants from "../Constants";
import { DocumentFilterDefault, IDocumentFilter } from "../components/Document/FilterAndOrder/IDocumentFilter";
import IDocumentOrder, { DocumentOrderDefault } from "../components/Document/FilterAndOrder/IDocumentOrder";
import { AuthService } from "./AuthService";
import EscapeRegExp from "../utils/Regex";
import { FilterType } from "../utils/List/Filter";
import IDocumentInfo from "../realm/schemas/Document/IDocument";
import IDocumentChange from "../realm/schemas/Document/IDocumentChange";
import Localized from "../Localization/Localized";

export default class DocumentService {
    private static readonly _instance: DocumentService = new DocumentService();
    public static get Instance() {
        return this._instance;
    }

    static async create(title: string, keys: string[], path?: string, spaceId?: ObjectId): Promise<IDocument|null> {
        return UMongoDB.Functions().document_add(title, path, keys, spaceId).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.DocumentCreatedSuccessfully);
                return result.document;
            }

            if(result.msg === "TitleMandatory") Msg.error(Localized.Document.UpdateDocumentErrorTitleMandatory);

            if(result.msg === "Title<200") Msg.error(Localized.Document.UpdateDocumentErrorTitleLess200);

            if(result.msg === "SpaceMandatory") Msg.error(Localized.Document.DocumentSpaceMandatory);

            if(result.msg === "Path>200") Msg.error(Localized.Document.UpdateDocumentErrorPathGreater200);

            if(!result.msg || result.msg.length === 0) {
                Msg.unexpectedError();
                return null;
            }
            
            return null;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return null;
        }); 
    } 

    static UpdateTitle = async (docId: ObjectId, title: string, isPublic?: boolean) => {
        return UMongoDB.Functions().document_update_title(docId, title, isPublic).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.UpdateDocumentTitleSuccessfully);
                return true;
            }

            if(result.msg === "TitleMandatory") Msg.error(Localized.Document.UpdateDocumentErrorTitleMandatory);

            if(result.msg === "Title<200") Msg.error(Localized.Document.UpdateDocumentErrorTitleLess200);

            if(result.msg === "Unauthorized") Msg.error(Localized.General.Unauthorized);

            if(!result.msg || result.msg.length === 0) {
                Msg.unexpectedError();
            }

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });
    }

    static UpdatePath = async (docId: ObjectId, path: string, index: number, isPublic?: boolean, progres?: (path: string, state: boolean) => void) => {
         return UMongoDB.Functions().document_update_path(docId, path, index, isPublic).then((result:any) => {
            if(result.state === "OK") {
                if(!progres) Msg.success(Localized.Document.UpdateDocumentPathSuccessfully);
                else progres(path, true);
                return true;
            }

            if(result.msg === "Index<0") Msg.error(Localized.Document.UpdateDocumentErrorPathLess0);

            if(result.msg === "Path>200") Msg.error(Localized.Document.UpdateDocumentErrorPathGreater200);

            if(result.msg === "PathSectionEmpty") Msg.error(Localized.Document.UpdateDocumentErrorPathSectionEmpty);

            if(result.msg === "PathEmpty") Msg.error(Localized.Document.UpdateDocumentErrorPathEmpty);

            if(result.msg === "Unauthorized") Msg.error(Localized.General.Unauthorized);

            if(result.state === "Fail" && (!result.msg || result.msg.length === 0)) {
                Msg.unexpectedError();
            }

            if(progres) progres(path, false);

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            if(progres) progres(path, false);
            
            console.log(err);
            return false;
        });
    }

    static RemovePath = async (docId: ObjectId, index: number, isPublic?: boolean) => {
        return UMongoDB.Functions().document_remove_path(docId, index, isPublic).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.RemoveDocumentPathSuccessfully);
                return true;
            }

            if(result.msg === "Index<0") Msg.error(Localized.Document.UpdateDocumentErrorPathLess0);

            if(result.msg === "Unauthorized") Msg.error(Localized.General.Unauthorized);

            if(!result.msg || result.msg.length === 0) {
                Msg.unexpectedError();
                return false;
            }

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });
    }

    static async updateContent(id: ObjectId, content: string, comment?: string, quiet:boolean = false): Promise<boolean> {
        return UMongoDB.Functions().document_update_content(id, content, comment).then((result: any) => {
            if(result.state === "OK") {
                if(!quiet) Msg.success(Localized.Document.UpdateDocumentContentSuccessfully);
                return true;
            }

            if(result.msg === "ContentNotChanged" && !quiet) Msg.error(Localized.Document.UpdateDocumentContentNotChanged);

            if(result.msg === "Unauthorized" && !quiet) Msg.error(Localized.General.Unauthorized);

            if(!result.msg || result.msg.length === 0) {
                if(!quiet) Msg.unexpectedError();
                return false;
            }

            return false;
        }).catch((err: any) => {
            if(!quiet) Msg.unexpectedError();
            console.log(err);
            return false;
        }); 
    } 

    static publish = async (docId: ObjectId) => {
        return UMongoDB.Functions().document_publish(docId).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.PublishDocumentSuccessfully);
                return true;
            }

            if(result.msg === "NoPublicTitle") Msg.error(Localized.Document.PublishDocumentNoPublicTitle);

            if(result.msg === "Unauthorized") Msg.error(Localized.General.Unauthorized);

            if(!result.msg || result.msg.length === 0) {
                Msg.unexpectedError();
                return false;
            }

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });
    }

    static remove: (docId: ObjectId) => Promise<boolean> = async (docId: ObjectId) => {
        return UMongoDB.Functions().document_remove(docId).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.RemoveDocumentSuccessfully);
                return true;
            }

            if(result.msg === "Unauthorized") Msg.error(Localized.General.Unauthorized);

            if(!result.msg || result.msg.length === 0) {
                Msg.unexpectedError();
                return false;
            }

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });
    }

    static RemoveSpaceFromPath = (path: string) => {
        if(path.indexOf(Constants.ProfileIDPathKey) > -1 || path.indexOf(Constants.SpaceIDPathKey) > -1) {
            const splited = path.split("|").map(m => m.trim());
            splited.shift();
            return splited.join(" | ");
        }

        return path;
    } 

    static UpdateKeys:(docId: ObjectId, keys: string[]) => Promise<boolean> = async (docId: ObjectId, keys: string[]) => {
        return UMongoDB.Functions().document_update_keys(docId, keys).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.UpdateDocumentKeysSuccessfully);
                return true;
            }

            if(result.msg === "Unauthorized") Msg.error(Localized.General.Unauthorized);

            if(!result.msg || result.msg.length === 0) {
                Msg.unexpectedError();
                return false;
            }

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });
    }

    static All = async (filter?: IDocumentFilter, order?: IDocumentOrder) => {
        if(!filter) filter = DocumentFilterDefault();
        if(!order) order = DocumentOrderDefault();

        const isPublicOnly = filter?.isPublicOnly ?? false;
        const isPrivateOnly = filter?.isPrivateOnly ?? false;
        const spaceId = filter?.spaceId ?? null;
        const startDate = filter?.startDate;
        const endDate = filter?.endDate;

        const limit = filter.limit && filter.limit > 0 ? filter.limit : 100;
        const skip = filter.skip ?? 0;

        const pipline: any = [];

        const matchAnd: any = []

        if(spaceId) {
            matchAnd.push({
                'spaceId': spaceId
            });
        }

        if(isPrivateOnly) {
            matchAnd.push({
                'createdById': AuthService.currentProfile?._id
            });
        }

        if(isPublicOnly) {
            matchAnd.push({
                'isPublished': true
            });
        }

        if(startDate) {
            matchAnd.push({
                'modifiedDateTimeUTC': {
                    '$gte': startDate.toUTC().toJSDate()
                }
            });
        }

        if(endDate) {
            matchAnd.push({
                'modifiedDateTimeUTC': {
                    '$lte': endDate.toUTC().toJSDate()
                }
            });
        }

        matchAnd.push({
            '$or': [
                {
                    'referenceId': {
                        '$exists': false
                    }
                }, {
                    'referenceId': {
                        '$type': 10
                    }
                }
            ]
        });

        if(filter?.path && filter?.path.filter && filter?.path.filter.length > 0) {
            const pathFilter = filter?.path;

            let text = pathFilter.filter;

            if(pathFilter.type === FilterType.StartWith) text = `^${EscapeRegExp(text)}`;
            if(pathFilter.type === FilterType.EndWith) text = `${EscapeRegExp(text)}$`;
            console.log("Path filter", text);
            try {
                new RegExp(text);

                if(pathFilter.type !== FilterType.Equal) {
                    matchAnd.push({
                        '$or': [
                        {
                            'path': {
                                '$regex': text,
                                '$options': "i"
                            }
                        },
                        {
                            'publicPath': {
                                '$regex': text,
                                '$options': "i"
                            }
                        }
                        ]
                    });
                } else {
                    matchAnd.push({
                        '$or': [
                            {
                                'path': text
                            },
                            {
                                'publicPath': text
                            }
                        ]
                    });
                }
            } catch (error) {
                Msg.error("The path filter fail the regex!");
            }
        }

        if(filter?.text && filter?.text.filter && filter?.text.filter.length > 0) {
            const titleFilter = filter?.text;

            let text = titleFilter.filter;

            if(titleFilter.type === FilterType.StartWith) text = `^${text}`;
            if(titleFilter.type === FilterType.EndWith) text = `${text}$`;

            try {
                new RegExp(text);

                matchAnd.push({
                    '$or': [
                    {
                        'title': {
                            '$regex': text,
                            '$options': "i"
                        }
                    },
                    {
                        'publicTitle': {
                            '$regex': text,
                            '$options': "i"
                        }
                    }
                    ]
                });
            } catch (error) {
                Msg.error("The title filter fail the regex!");
            }
        }

        if(filter?.keys && filter?.keys.filter && filter?.keys.filter.length > 0) {
            const titleFilter = filter?.keys;

            let text = titleFilter.filter;

            try {
                new RegExp(text);

                matchAnd.push({
                    '$or': [
                    {
                        'keys': {
                            '$regex': text,
                            '$options': "i"
                        }
                    }
                    ]
                });
            } catch (error) {
                Msg.error("The title filter fail the regex!");
            }
        }

        pipline.push({
            '$match': {
                '$and': matchAnd
            }
        });

        // Project
        pipline.push({
            '$project': {
                'content': 0
            }
        });

        // Sort

        var o = order?.fields.filter(f => f.direction !== "N/A") ?? [];

        if(o && o.length > 0) {
            const sort: any = {}

            for (const s of o) {
                sort[s.field] = s.direction === "A" ? 1 : -1
            }

            console.log("Sort", sort);

            pipline.push({
                '$sort': sort
            });
        }

        // skip
        pipline.push({
            '$skip': skip
        });

        // Limit
        pipline.push({
            '$limit': limit
        });

        const results: IDocumentInfo[] = await UMongoDB.documents?.aggregate(pipline);
        return results ?? [];
    }

    static GetChanges = async (docId: ObjectId, action: "Published" | "Remove Public Path" | "Remove Path" | "Update Content" | "Update Public Path" | "Update Path" | "Update Public Title" | "Update Title" | "Update Keys") => {
        return UMongoDB.Functions().document_changes_get_all(docId, action).then((result:IDocumentChange[]) => {
            return result;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return null;
        }); 
    }

    static GetChange = async (documentId: ObjectId, changeId: ObjectId) => {
        return UMongoDB.Functions().document_changes_get(documentId, changeId).then((result:IDocumentChange) => {
            return result;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return null;
        }); 
    }

    static GetById =  async (id: ObjectId) => {
        const result = await UMongoDB.documents?.findOne({ _id : id});
        return result
    }

    // Only path and not publicPath
    static UpdatePaths: (pathToFind: string, pathToReplace: string, progres?: (path: string, state: boolean) => void) => Promise<boolean> = 
    async (pathToFind: string, pathToReplace: string, progres?: (path: string, state: boolean) => void) => {
        if(!pathToFind && pathToFind.length <= 0) return false;

        const pipline: any = [
            {
                '$project': {
                    'content': 0
                }
            }, {
                '$sort': {
                'path': 1, 
                'title': 1
                }
            }
            ];

            try {
            new RegExp(pathToFind);

            pipline.unshift({
                '$match': {
                    'path': {
                        '$regex': pathToFind,
                        '$options': "i"
                    }
                }
            });
        } catch (error) {
            Msg.error("The filter fail the regex!");
            return new Promise<boolean>((r) => r(false));
        }

        pipline.unshift({
            '$match': {
                '$or': [
                    {
                        'referenceId': {
                            '$exists': false
                        }
                    }, {
                        'referenceId': {
                            '$type': 10
                        }
                    }
                ]
            }
        });

        const results:IDocumentInfo[] = await UMongoDB.documents?.aggregate(pipline) ?? [];

        const promises = [];

        for (const doc of results) {
            promises.push(this._updatePathsByDoc(doc, pathToFind, pathToReplace, progres));
        }

        await Promise.allSettled(promises);

        return true;
    }

    // Only path and not publicPath
    private static _updatePathsByDoc = async (doc: IDocumentInfo, pathToFind: string, pathToReplace: string, progres?: (path: string, state: boolean) => void) => { 
        if(doc.path) {
            if(Array.isArray(doc.path)) {
                let i = 0;
                for (const p of doc.path) {
                    await this._updatePaths(doc._id, p, i, pathToFind, pathToReplace, progres);
                    i++;
                }
            } else {
                await this._updatePaths(doc._id, doc.path, 0, pathToFind, pathToReplace, progres);
            }
        }
    }

    // Only path and not publicPath
    private static _updatePaths = async (docId: ObjectId, currentPath: string, index: number, pathToFind: string, pathToReplace: string, progres?: (path: string, state: boolean) => void) => {
        if(currentPath.startsWith(pathToFind)) {
            const pathToFindSplited = pathToFind.split('|').map(m => m.trim());
            const currentPathSplited = currentPath.split('|').map(m => m.trim());

            currentPathSplited[pathToFindSplited.length - 1] = pathToReplace;

            const newPath = currentPathSplited.join(" | ");

            await DocumentService.UpdatePath(docId, newPath, index, false, progres);
        }
    } 
}