import { FilterType, ITextAndDateAdnPrivacyFilter } from "../../../../utils/List/Filter";

export interface ISpaceFilter extends ITextAndDateAdnPrivacyFilter {

}

export const SpaceFilterDefault: () => ISpaceFilter = () => {
    return {
        text: {
            filter: "",
            type: FilterType.Contain
        },
        skip: 0,
        limit: 100
    }
}