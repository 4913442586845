import Localized from "../../../../Localization/Localized";
import { FilterPopupContent } from "../../../../utils/List/FilterPopupContent";
import { ISpaceFilter, SpaceFilterDefault } from "./SpaceFilter";

export class SpaceFilterPopupContent extends FilterPopupContent<ISpaceFilter> {
    get publicFilterLabel(): string {
        return Localized.General.NotMineOnly;
    }
    get privateFilterLabel(): string {
        return Localized.General.MineOnly;
    }
    get textFilterLabel(): string {
        return Localized.Space.FilterNameLabel;
    }

    DefaultFilter(): ISpaceFilter {
        return SpaceFilterDefault();
    }

    protected beforeTitle(): JSX.Element[] {
        return []
    }
    protected betweenTitleAndDate(): JSX.Element[] {
        return [ ]
    }
    protected afterDate(): JSX.Element[] {
        return [];
    }
}
