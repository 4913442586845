import React from "react";
import { UncontrolledAlert } from "reactstrap";
import styled from "@emotion/styled";
import * as uuid from "uuid";
import ZI from "../styles/ZIndex";
import Colors, { ColorSections } from "./Color/MainColors";
import Localized from "../Localization/Localized";

interface IMsg{
    id: string,
    type: string,
    message: string | JSX.Element,
    fade: boolean,
    timeout?: number
}

class MsgManager {
    index: number = 0;
    stateHandler: (msg: IMsg) => void = (msg: IMsg) => {};

    info (msg: string | string[]){
        this.stateHandler({
            id: uuid.v4(),
            type: "info",
            message: this.toMsgElement(msg),
            fade: true,
            timeout: 2000
        });
    }

    error (msg: string | string[]){
        this.stateHandler({
            id: uuid.v4(),
            type: "danger",
            message: this.toMsgElement(msg),
            fade: false
        });
    }  

    unexpectedError(){
        this.stateHandler({
            id: uuid.v4(),
            type: "danger",
            message: Localized.GeneralError.Unexpected,
            fade: false
        });
    }

    warning (msg: string | string[]){
        this.stateHandler({
            id: uuid.v4(),
            type: "warning",
            message: this.toMsgElement(msg),
            fade: false
        });
    }

    success (msg: string | string[]){
        this.stateHandler({
            id: uuid.v4(),
            type: "success",
            message: this.toMsgElement(msg),
            fade: true,
            timeout: 2000
        });
    }

    setStateHandler(stateHandler: (msg: IMsg) => void){
        this.stateHandler = stateHandler;
    }

    private toMsgElement(msg: string | string[]) : string | JSX.Element{
        return Array.isArray(msg) ? <React.Fragment>{msg.map(s => <div key={s}>{s}</div>)}</React.Fragment> : msg;
    }
};

const Msg = new MsgManager();

export { Msg };

interface IMessengerState{
    messages: IMsg[]
}

export default class Messenger extends React.Component<{}, IMessengerState>{
    constructor(props: Readonly<{}>){
        super(props);

        this.state = {
            messages: []
        };

        const self = this;
        Msg.setStateHandler((msg: IMsg) => {
            const msgs = this.state.messages;
            msgs.push(msg);

            self.setState({
                messages: msgs
            },()=>{
                if(msg.timeout && msg.timeout > 0){
                    window.setTimeout(()=>{
                        this.onDismiss(msg.id);
                      },msg.timeout)
                }
              });
        });
    }

    onDismiss(id: string) {
        const msgs = this.state.messages.filter(function(value, index, arr){
            return value.id !== id;
        });
        this.setState({
            messages: msgs
        });
    } 

    style: (type: string) => React.CSSProperties = (type: string) => {
        switch (type) {
            case "success":
                return {
                    backgroundColor: Colors.get(ColorSections.SuccessBG),
                    borderColor: Colors.get(ColorSections.SuccessBorder),
                    color: Colors.get(ColorSections.SuccessColor)
                }
            case "danger":
                return {
                    backgroundColor: Colors.get(ColorSections.DangerBG),
                    borderColor: Colors.get(ColorSections.DangerBorder),
                    color: Colors.get(ColorSections.DangerColor)
                }
            case "warning":
                return {
                    backgroundColor: Colors.get(ColorSections.WarningBG),
                    borderColor: Colors.get(ColorSections.WarningBorder),
                    color: Colors.get(ColorSections.WarningColor)
                }
            default:
                return {
                    backgroundColor: Colors.get(ColorSections.InfoBG),
                    borderColor: Colors.get(ColorSections.InfoBorder),
                    color: Colors.get(ColorSections.InfoColor)
                }
        }
    }

    render() {
        const self = this;
        return (<MessengerPanel>
            {this.state.messages.map(item => {
                console.log(item.message);
                return (<UAlert key={item.id.toString()} style={this.style(item.type)} toggle={() => self.onDismiss(item.id)} fade={item.fade}>
                    {item.message}
                  </UAlert>)
            })}
        </MessengerPanel>); 
    }  
}

export const UAlert = styled(UncontrolledAlert)`
    pointer-events: all;
`;

const MessengerPanel = styled.div`
  position: fixed;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  min-height: 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: ${ZI.Alert};
  justify-content: flex-start;
  pointer-events: none;
  > * {
      margin-bottom:0.5rem;
  }
`;

