import Localized from "../../../Localization/Localized";
import { IOrder, ITypedField, TField } from "../../../utils/List/Order"

type TDocumentField = TField | "publishedDateTimeUTC" | "title" | string;
export default interface IDocumentOrder extends IOrder<TDocumentField>{

}

export const DocumentOrderDefault: () => IDocumentOrder = () => {
    return {
        fields: [
            { 
                label: "Modification Date",
                field: "modifiedDateTimeUTC",
                direction: "D"
            },
            { 
                label: "Title",
                field: "title",
                direction: "A"
            },
            { 
                label: "Creation Date",
                field: "createdDateTimeUTC",
                direction: "N/A"
            },
            { 
                label: "Publication Date",
                field: "publishedDateTimeUTC",
                direction: "N/A"
            }
        ]
    }
}

export const DocumentOrderToSearch:(order: IDocumentOrder, searchParams:URLSearchParams) => void = (order: IDocumentOrder, searchParams:URLSearchParams) => {
    const filtred = order.fields.filter(f => f.direction !== "N/A");

    for (let index = 0; index < filtred.length; index++) {
        const f = filtred[index];
        searchParams.append(`o_${f.field}`, f.direction);
    }
}

export const DocumentOrderFromSearch:(params: URLSearchParams) => IDocumentOrder = (params: URLSearchParams) => {
    const order:IDocumentOrder = {
        fields: []
    };

    let md = false;
    let title = false;
    let cd = false;
    let pd = false;

    params.forEach((v, k) => {
        if(k.indexOf("o_") >= 0) {
            const key = k.substring(2);
            const o:ITypedField<TDocumentField> = {
                label: "",
                field: "",
                direction: "A"
            }
            
            if(key === "modifiedDateTimeUTC"){
                o.label = "Modification Date";
                o.field = "modifiedDateTimeUTC";
                md = true;
            }

            if(key === "title"){
                o.label = "Title";
                o.field = "title";
                title = true;
            }

            if(key === "createdDateTimeUTC"){
                o.label = "Creation Date";
                o.field = "createdDateTimeUTC";
                cd = true;
            }

            if(key === "publishedDateTimeUTC"){
                o.label = "Publication Date";
                o.field = "publishedDateTimeUTC";
                pd = true;
            }

            if(key.indexOf("keys") >= 0) {
                const splited = key.split(".");

                o.label = `Keys ${splited[1]}`;
                o.field = `keys.${splited[1]}`;
            }

            o.direction = v === "A" ? "A" : "D";

            order.fields.push(o);
        }
    });

    if(!title) {
        order.fields.push({
            label: Localized.General.Title,
            field: "title",
            direction: "N/A"
        });
    }

    if(!md) {
        order.fields.push({
            label: Localized.General.ModificationDate,
            field: "modifiedDateTimeUTC",
            direction: "N/A"
        });
    }

    if(!cd) {
        order.fields.push({
            label: Localized.General.CreationDate,
            field: "createdDateTimeUTC",
            direction: "N/A"
        });
    }

    if(!pd) {
        order.fields.push({
            label: Localized.General.PublicationDate,
            field: "publishedDateTimeUTC",
            direction: "N/A"
        });
    }

    return order;
}
