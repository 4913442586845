import styled from "@emotion/styled";
import Colors, { ColorSections } from "./Color/MainColors";

interface Props {
    colors?: Colors
}

export const Separator = styled.hr`
    border: 1px solid ${Colors.get(ColorSections.Separator)};
    width: 100%;
    margin-top:0.25rem;
    margin-bottom:0.25rem;
`;

const TooltipSeparatorStyle = styled.hr`
    border: 1px solid;
    width: 100%;
    margin-top:0;
    margin-bottom:0.25rem;
`;

export const TooltipSeparator = ({ colors }: Props) => {
    colors = colors ?? Colors.Current;
    return <TooltipSeparatorStyle style={{ borderColor: colors.get(ColorSections.TooltipSeparator) }} />
}
