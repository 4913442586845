import styled from "@emotion/styled";
import { FloatingWrapper } from "@remirror/react";
import { AddMark } from "./Actions/AddMarkActions";
import { AddQuoteAndCode } from "./Actions/AddQuoteAndCodeActions";
import { AddFont } from "./Actions/AddFontActions";
import { AddHeader } from "./Actions/AddHeaderActions";
import { AddHorizontalRule } from "./Actions/AddHorizontalRuleActions";
import { AddTable } from "./Actions/AddTableActions";
import { AddTestCase } from "./Actions/AddTextCaseActions";
import { AddImage } from "./Actions/AddImageActions";
import { AddIndent } from "./Actions/AddIndentActions";
import { AddTextAlign } from "./Actions/AddTextAlignActions";
import { AddList } from "./Actions/AddListActions";
import { AddSave } from "./Actions/AddSaveAction";
import AddHistories from "./Actions/AddHistoriesAction";
import Constants from "../../../../Constants";
import {isMobile, isIOS13} from 'react-device-detect';
import { SecondaryButton } from "../../../../utils/CustomButton";
import { mdiHammerWrench } from "@mdi/js";
import { useState } from "react";
import {style} from "typestyle";

type TEditorButton = {
    group?: string[];
    fixed?: boolean;
    saveHandle?: () => Promise<boolean>;
    openHistories?: () => Promise<boolean>;
}

const ActionsMap = (() => {
    const am = new Map<string, () => JSX.Element>();
    am.set("B", AddMark);
    am.set("Q", AddQuoteAndCode);
    am.set("F", AddFont);
    am.set("H", AddHeader);
    am.set("L", AddHorizontalRule);
    am.set("T", AddTable);
    am.set("TC", AddTestCase);
    am.set("I", AddImage);
    am.set("ID", AddIndent);
    am.set("TA", AddTextAlign);
    am.set("LST", AddList);
    am.set("S", () => <span />);
    am.set("HI", () => <span />);

    return am;
})();

export const EditorButton: React.FC<TEditorButton> = (props: TEditorButton) => {
    const [open, setOpen] = useState(!isMobile);
    const actions: Array<JSX.Element> = [];
    const g = props.group;
    
    if(!g || g.length === 0) {
        ActionsMap.forEach((a, k) => {
            if(k === "S" && props.saveHandle) {
                actions.push(<AddSave onClick={props.saveHandle} />);
            }
            else if(k === "HI" && props.openHistories) {
                actions.push(<AddHistories onClick={props.openHistories} />);
            }
            else {
                actions.push(a());
            }
        });
    } else {
        g.forEach(group => {
            if(ActionsMap.has(group)) {
                if(group === "S" && props.saveHandle) {
                    actions.push(<AddSave onClick={props.saveHandle} />);
                }
                else if(group === "HI" && props.openHistories) {
                    actions.push(<AddHistories onClick={props.openHistories} />);
                }
                else {
                    const am = ActionsMap.get(group);
                    if(am) {
                        actions.push(am());
                    }
                }
            }
        });
    }

    const itemStyle: string | undefined = isMobile ?  style({
        display: "flex",
        flexDirection: "column",
        $nest: {
            '&:not(:last-of-type)': {
                marginTop: "0.25rem", 
            },
            '.btn-group': {
                display: "flex"
            }
        }
    }) : style({
        $nest: {
            '&:not(:last-of-type)': {
                marginLeft: "0.25rem", 
            }
        }
    });

    return (
        <EditorButtonContainer fixed={props.fixed}>
            {isMobile && <span className={itemStyle}><span><SecondaryButton maIcon={mdiHammerWrench} onClick={() => {
                setOpen(!open)
            }} /></span></span>}
            {open && actions.map((g, i) => <span key={i} className={itemStyle}><span>{g}</span></ span>)}
        </EditorButtonContainer>
    );
};

type TEditorButtonContainerProps = {
    fixed?: boolean;
}

const EditorButtonContainer = styled.div<TEditorButtonContainerProps>`
    position: ${p => p.fixed ? "fixed" : "relative"};
    display: flex;
    flex-direction: ${isMobile ? "column" : "row"};
    margin-top: 0;
    font-size: ${isMobile ? "1.5em" : "1em"};
    overflow: scroll;
    white-space: nowrap;
    z-index: 1000;
    opacity: ${isMobile ? 1 : Constants.ActionDefaultOpacity};
    max-height: ${`calc(100vh - ${Constants.HeaderHeight} - ${Constants.FooterHeight}${isIOS13 ? " - 2em" : ""})`};
    :hover {
        opacity: 1; 
    }
`;

export const FloatingButtons = () => {
    return <FloatingWrapper positioner="selection" placement="bottom">
        <EditorButton group={["B"]} />
    </FloatingWrapper>
}
