import { mdiContentSave } from "@mdi/js";
import Colors, { ColorSections } from "../../../../../utils/Color/MainColors";
import { AddCustom, TAddCustomProps } from "./AddCustomAction";

export class AddSave extends AddCustom {
    constructor(props: TAddCustomProps | Readonly<TAddCustomProps>) {
        super(props);

        this._backgroundColor = Colors.get(ColorSections.BtnPrimaryBG);
        this._borderColor = Colors.get(ColorSections.BtnPrimaryBorder);
        this._color = Colors.get(ColorSections.BtnPrimaryColor);
        this._icon = mdiContentSave;
    }
}
