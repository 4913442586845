import SpaceService from "../../../services/SpaceService";
import React from "react";
import { ISpace } from "../../../realm/schemas/ISpace";
import { ObjectId } from "bson";

type DisplaySpaceProps = {
    spaceId: ObjectId;
}

type DisplaySpaceState = {
    space?: ISpace;
}

export class DisplaySpace extends React.Component<DisplaySpaceProps, DisplaySpaceState> {
    private _mounted: boolean = false;
    constructor(props: DisplaySpaceProps | Readonly<DisplaySpaceProps>) {
        super(props);

        this.state = {};
    }

    load = () => {
        SpaceService.GetById(this.props.spaceId).then(result => {
            if (result && this._mounted) {
                this.setState({
                    space: result
                });
            }
            
        });
    };

    componentDidMount = () => {
        this._mounted = true;
        
        this.load();
    };

    componentWillUnmount = () => {
        this._mounted = false;
    }

    render = () => {
        return <span>
            {this.state.space && this.state.space?.name}
        </span>;
    };
}
