import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { RGBColor } from "react-color";
import PathGroupService from "../../services/PathGroupService";
import PathGroupsService from "../../services/PathGroupsService";
import { DangerButton, SuccessButton, WarningButton } from "../../utils/CustomButton";
import { ColorPickerBase } from "../../utils/Color/ColorPicker";
import { CustomInput } from "../../utils/CustomInput";
import { CustomInputGroupText } from "../../utils/CustomInputGroupText";
import DocumentService from "../../services/DocumentService";
import { CustomInputGroup } from "../../utils/CustomInputGroup";
import styled from "@emotion/styled";
import Localized from "../../Localization/Localized";

type DocumentGroupEditorProps = {
    name: string;
    path: string;
    onChanged?: () => void;
}

type DocumentGroupEditorState = {
    bgColor: RGBColor
}

export default class PathGroupStyleEditor extends React.Component<DocumentGroupEditorProps, DocumentGroupEditorState> {
    private _fullName: string;
    constructor(props: DocumentGroupEditorProps | Readonly<DocumentGroupEditorProps>) {
        super(props);

        this._fullName = this.props.name;

        if(this.props.path && this.props.path.length > 0) {
            this._fullName = `${DocumentService.RemoveSpaceFromPath(this.props.path)} | ${this._fullName}`;
        }

        const group = PathGroupsService.GetGroup(this._fullName, false);

        const bg = group && group.bgColor ? group.bgColor : {
            r:0,
            b:0,
            g:0,
            a:0
        };

        this.state = {
            bgColor : bg
        }
    }


    bgColorChange = (color: RGBColor) => {
        this.setState({
            bgColor: color
        });
    }

    onBGColorSave = async () => {
        const result = await PathGroupService.createOrUpdateGroup(this._fullName, this.state.bgColor);

        if(result) {
            await PathGroupsService.LoadGroup(true);
            if(this.props.onChanged) this.props.onChanged();
        }
    }

    onRemove = async () => {
        const result = await PathGroupService.remove(this._fullName);

        if(result) {
            await PathGroupsService.LoadGroup(true);
            if(this.props.onChanged) this.props.onChanged();
        }
    }

    render = () => {
        return <DivStiled>
            <CustomInputGroup>
                <CustomInputGroupText>{Localized.General.Name}</CustomInputGroupText>
                <CustomInput value={this._fullName} readOnly/>
            </CustomInputGroup>
            <CustomInputGroup>
                <CustomInputGroupText>{Localized.General.BGColor}</CustomInputGroupText>
                <ColorPickerBase color={this.state.bgColor} onChange={this.bgColorChange} />
                <SuccessButton onClick={() => this.onBGColorSave()}><FontAwesomeIcon icon="save"/></SuccessButton>
                <WarningButton><FontAwesomeIcon icon="redo"/></WarningButton>
            </CustomInputGroup>
            <DangerButton onClick={this.onRemove}>{Localized.General.Remove}</DangerButton>
        </DivStiled>
    }
}

const DivStiled = styled.div`
    > * {
        margin-top: 0.25rem;
    }
`;