import { mdiHistory } from "@mdi/js";
import Colors, { ColorSections } from "../../../../../utils/Color/MainColors";
import { AddCustom, TAddCustomProps } from "./AddCustomAction";

export default class AddHistories extends AddCustom {
    constructor(props: TAddCustomProps | Readonly<TAddCustomProps>) {
        super(props);

        this._backgroundColor = Colors.get(ColorSections.BtnInfoBG);
        this._borderColor = Colors.get(ColorSections.BtnInfoBorder);
        this._color = Colors.get(ColorSections.BtnInfoColor);
        this._icon = mdiHistory;
    }
}