import { Msg } from "../utils/Messenger";
import { RGBColor } from "react-color";
import { UMongoDB } from "../realm/MongoDB";
import Localized from "../Localization/Localized";

export default class PathGroupService { 
    private static readonly _instance: PathGroupService = new PathGroupService();
    public static get Instance() {
        return this._instance;
    }

    static async createOrUpdateGroup(name: string, bgColor: RGBColor): Promise<any> {
        return UMongoDB.Functions().document_add_or_update_group(name, bgColor).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.PathGroupStyleSaved);
                return true;
            }

            if(result.state === "Fail" && (!result.msg || result.msg.length === 0)) {
                Msg.unexpectedError();
                return false;
            }
            
            Msg.error(`${result.state}:${result.msg}`);

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        }); 
    }

    static async remove(name: string): Promise<any> {
        return UMongoDB.Functions().document_group_remove(name).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Document.PathGroupStyleRemoved);
                return true;
            }

            if(result.state === "Fail" && (!result.msg || result.msg.length === 0)) {
                Msg.unexpectedError();
                return false;
            }
            
            Msg.error(`${result.state}:${result.msg}`);

            return false;
        }).catch((err:any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        }); 
    }
}
