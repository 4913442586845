import { CSSProperties } from "remirror"
import { SecondaryButton } from "../../../../utils/CustomButton";

type TActionButtonProps = {
    active?: boolean;
    enabled?: boolean;
    onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void
    style?: CSSProperties
}

export const ActionButton: React.FC<TActionButtonProps> = ({
    active,
    enabled,
    onClick, 
    children,
    style
}) => {
    return <SecondaryButton size="sm" onMouseDown={(event) => event.preventDefault()}
    onClick={(evt) => onClick(evt)}
    active={active}
    disabled={enabled === false} style={style}
    >{children}</SecondaryButton>
}
