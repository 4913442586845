import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Dialogs from './utils/dialog/Dialogs';
import OffcanvasContainer from './components/OffcanvasContainer';
import Messenger from './utils/Messenger';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

ReactDOM.render(<React.StrictMode>
  <Dialogs />
</React.StrictMode>, document.getElementById('dialog'));

ReactDOM.render(<OffcanvasContainer />, document.getElementById('offcanvas'));

ReactDOM.render(<Messenger />, document.getElementById('messenger'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
