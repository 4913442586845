import React from "react";
import { IProfile } from "../../realm/schemas/Profile/IProfile";
import { AuthService } from "../../services/AuthService";
import { v4 } from "uuid";

export interface INeedProfilePageState {
    currentProfile:  IProfile | null;
}

export default abstract class NeedProfilePage<TProps extends {}, TState extends INeedProfilePageState> extends React.Component<TProps, TState> {
    private _id: string;
    constructor(props: Readonly<TProps>) {
        super(props);
        this._id = v4();
        this.state = this.initState(props);
    }

    protected abstract initState(props: Readonly<{}>): TState;

    protected abstract profileChanged(): void;

    protected abstract didMount(): void;

    componentDidMount = () => {
        AuthService.subscribeChange(this._id, (f, v) => {
            this.setState({
                currentProfile: AuthService.currentProfile ?? null,
            }, this.profileChanged);
        });

        this.didMount();
    }

    componentWillUnmount = () => {
        AuthService.unsubscribeChange(this._id);
    }
}