import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";
import LocationService from "../../services/LocationService";
import OffcanvasService from "../../services/OffcanvasService";
import { IsNoU } from "../../utils/IsNOU";
import Colors, { ColorSections } from "../../utils/Color/MainColors";
import Icon from "@mdi/react";
import { mdiAllInclusive } from "@mdi/js";
import metadata from '../../metadata.json';
import { DocumentList } from "../Document/DocumentList/DocumentList";
import SpaceList from "../Document/Space/SpaceList";
import Localized from "../../Localization/Localized";

export const MainMenu:React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!IsNoU(AuthService.currentProfile));
  
    useEffect(() => {  
      AuthService.subscribeChange("MainMenu", (f, v) => {
        setIsLoggedIn(!IsNoU(AuthService.currentProfile));
      });
  
      return () => {
          AuthService.unsubscribeChange("MainMenu");
      }
    });

    const goTo = (path: string) => {
        OffcanvasService.close();
        LocationService.goTo(path);
    }

    return <div style={{
      paddingLeft: "1rem"
    }}>
      <ActionMainMenuItem onClick={() => goTo("/")}><Icon path={mdiAllInclusive} size="1em" /> {Localized.General.HomeTitle}</ActionMainMenuItem>
      {isLoggedIn && <>
        <ActionMainMenuItem onClick={() => goTo("/Documents/List")}>{DocumentList.PageTitle()}</ActionMainMenuItem>
        <ActionMainMenuItem onClick={() => goTo("/Documents/Spaces/List")}>{SpaceList.PageTitle()}</ActionMainMenuItem>
        <MainMenuItem style={{ fontSize: "0.75em"}}>{Localized.General.Version} {metadata.buildMajor}.{metadata.buildMinor}.{metadata.buildRevision} {metadata.buildTag}</MainMenuItem>
      </>}
    </div>
}

export const MainMenuTitle = styled.span`
    font-size:1.5em;
`;

const MainMenuItem = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size:1.5em;

    > *:first-child {
      margin-right: 0.25rem;
    }
`;


const ActionMainMenuItem = styled(MainMenuItem)`
    cursor: pointer;
    :hover {
      background-color: ${Colors.get(ColorSections.OffcanvasBodyText, 0.25)}  
    }
`;