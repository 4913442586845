import { IDialogProps } from "./interfaces/IDialogProps";
import React from "react";
import { IFreeDialog } from "./interfaces/IFreeDialog";
import { ButtonGroup } from "reactstrap";
import Draggable from "react-draggable";
import { DialogSize } from "./enums/DialogSize";
import { FreeMainElement, FreeHeaderElement, FreeBodyElement, FreeShadow, FreeFooterElement } from "./DialogStyle";
import { DialogType } from "./enums/DialogType";
import Loading from "../Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { IDialog } from "./interfaces/IDialog";
import { DangerButton, PrimaryButton, SecondaryButton, WarningButton } from "../CustomButton";
import Colors, { ColorSections } from "../Color/MainColors";

interface IFreeProps extends IDialogProps {
    dialog: IFreeDialog;
}

export default class FreeDialog extends React.Component<IFreeProps, {}> {
    private _mainElement: HTMLDivElement | undefined | null = undefined;
    private _bodyElement: HTMLDivElement | undefined | null = undefined;
    private _footerElement: HTMLDivElement | undefined  | null= undefined;

    constructor(props: Readonly<IFreeProps>) {
        super(props);

        this.state = {}

        const dialog = this.props.dialog;

        if(dialog.dispatchDismisse){             
            dialog.dispatchDismisse(()=> {
                this.props.dismissHandle(dialog);
            });
        }   
    }

    toogleBody = () => {
        if(this._bodyElement) {
            $(this._bodyElement).toggleClass("hide");
        }

        if(this._footerElement) {
            $(this._footerElement).toggleClass("hide");
        }
    }

    getMainElement(): JSX.Element {
        const dialog: IFreeDialog = this.props.dialog;
        const onClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            evt.stopPropagation();
        }

        let ms: React.CSSProperties | undefined = {
            opacity: 0,            
        };

        if(dialog.size !== DialogSize.FullScreen) {
            if(dialog.size !== DialogSize.NA) {
                ms.width = this.width;
                ms.maxHeight = this.maxHeight;
            } 
            else {
                ms.maxWidth = this.width;
                ms.maxHeight = this.maxHeight;
            }
        } else {
            ms.top = 0;
            ms.bottom = 0;
            ms.left = "0 !important";
            ms.right = 0;
            ms.width = "100vw";
            ms.height = "100vh";
            ms.inset = undefined;
        }

        return (<FreeMainElement ref={(el) => this._mainElement = el} onClick={onClick} style={ms} isDraggable={dialog.isDraggable} >
            <FreeDialogContent
                dialog={dialog}
                setBodyElement={(el) => this._bodyElement = el}
                setFooterElement={(el) => this._footerElement = el}
                toogleBody={this.toogleBody}
                dismissHandle={this.props.dismissHandle}
                okHandle={this.props.okHandle}
                cancelHandle={this.props.cancelHandle}
                onAction={this.props.onAction}
                onOkAction={this.props.onOkAction}
                onCancelAction={this.props.onCancelAction}
                onDismissAction={this.props.onDismissAction}
            />
    </FreeMainElement>);
    }

    getDraggableMainElement() : JSX.Element {
        return (<Draggable>
            {this.getMainElement()}
        </Draggable>);
    }

    get width(): number { 
        return this.getSize(window.innerWidth);
    }

    get maxHeight(): number {
        return window.innerHeight * 0.95;
    }

    private getSize(referenceSize: number): number {
        const dialog: IFreeDialog = this.props.dialog;
        switch (dialog.size) {
            case DialogSize.XS:
                return referenceSize * 0.45;            
            case DialogSize.S:
                return referenceSize * 0.55;
            case DialogSize.L:
                return referenceSize * 0.75;
            case DialogSize.XL:
                return referenceSize * 0.85;
            case DialogSize.Max:
                return referenceSize * 0.95;
            case DialogSize.FullScreen:
                return referenceSize;
            default:
                return referenceSize * 0.65;
        }
    }

    componentDidMount() {
        if(!this._mainElement) return;
        const $me = $(this._mainElement);
        const b = this._mainElement.getBoundingClientRect();
        $me.css("top", -b.height);

        if(this.props.dialog.size !== DialogSize.FullScreen) $me.css("left", window.innerWidth/2 - b.width/2);

        const top = (window.innerHeight - b.height) * 0.05;

        $me.animate({
            top: top,
            opacity: 1
        }, 1000);
    }

    render() {
        const dialog: IFreeDialog = this.props.dialog;
        const notModal = dialog.notModal ? dialog.notModal : false;
        const zi = dialog.baseZIndex ?? 20000;

        //onClick={() => this.props.dismissHandle(dialog)}
        return (<StyleFreeShadow notModal={notModal} style={{zIndex: (zi + this.props.index)}}>
            {!dialog.isDraggable && !dialog.isResizable && this.getMainElement()}
            {dialog.isDraggable && this.getDraggableMainElement()}
        </StyleFreeShadow>);        
    }
}

const StyleFreeShadow = styled(FreeShadow)`
    .react-draggable {
        width: auto !important;
        overflow: hidden;
    }
`;

type FreeDialogProps = {
    dialog: IFreeDialog,
    setBodyElement: (el: HTMLDivElement | undefined | null) => void,
    setFooterElement: (el: HTMLDivElement | undefined | null) => void,
    toogleBody: () => void,
    dismissHandle: (d: IDialog) => void,
    okHandle: (d: IDialog) => void,
    cancelHandle: (d: IDialog) => void,
    onOkAction?: boolean,
    onAction?: boolean,
    onCancelAction?: boolean,
    onDismissAction?: boolean
}

export const FreeDialogContent: React.FC<FreeDialogProps> = (props : FreeDialogProps) => {
    const dialog = props.dialog;

    const ws: React.CSSProperties = dialog.windowStyle ? dialog.windowStyle : {
        backgroundColor: Colors.get(ColorSections.PopupBG),
        borderRadius: "0.25rem",
        border: `1px solid ${Colors.get(ColorSections.PopupBorder)}`,
        boxShadow: "5px 5px 5px rgba(0,0,0, 0.25)",
        width: dialog.size === DialogSize.FullScreen ? "100vw" : "100%",
        maxHeight: dialog.size === DialogSize.FullScreen ? "100vh" : "95vh",
        height: dialog.size === DialogSize.FullScreen ? "100vh" : undefined,
        color: Colors.get(ColorSections.PopupText)
    };
   
    const okLable = dialog.okLable ? dialog.okLable : "Ok";

    const headerStyle: React.CSSProperties = dialog.headerStyle ? dialog.headerStyle : {
        borderBottom: `1px solid ${Colors.get(ColorSections.PopupHeaderSeparator)}`,
        color: Colors.get(ColorSections.PopupHeaderText),
        backgroundColor: Colors.get(ColorSections.PopupHeaderBG)
    };

    const bodyStyle: React.CSSProperties = {
        maxHeight: dialog.size === DialogSize.FullScreen ? "100%" : "calc(95vh - 80px)",
    } 

    const footerStyle: React.CSSProperties = {
        borderTop: `1px solid ${Colors.get(ColorSections.PopupFooterSeparator)}`,
        color: Colors.get(ColorSections.PopupFooterText),
        backgroundColor: Colors.get(ColorSections.PopupFooterBG)
    } 
    return <div style={ws} className="dialog-window">
        {dialog.header && <FreeHeaderElement style={headerStyle}>
            {dialog.header}
            {!dialog.hideHeaderButton && <ButtonGroup size="sm">
                <SecondaryButton onClick={props.toogleBody}><FontAwesomeIcon icon="window-minimize" /></SecondaryButton>
                <DangerButton onClick={() => props.dismissHandle(dialog)} ><FontAwesomeIcon icon="times" /></DangerButton>
            </ButtonGroup>}
        </FreeHeaderElement>}
        <FreeBodyElement ref={props.setBodyElement} style={bodyStyle}>
            {dialog.body}
        </FreeBodyElement>
        {dialog.type !== DialogType.Free && <FreeFooterElement ref={props.setFooterElement} style={footerStyle}>
            {dialog.type !== DialogType.Config && <PrimaryButton onClick={() => props.okHandle(dialog)} disabled={props.onAction}>
                {props.onOkAction && <span><Loading />&nbsp;</span>}{okLable}
            </PrimaryButton>}
            {dialog.type !== DialogType.Alert && dialog.type !== DialogType.Config && <WarningButton onClick={() => props.cancelHandle(dialog)} disabled={props.onAction}>
                {props.onCancelAction && <span><Loading />&nbsp;</span>}Cancel
            </WarningButton>}
            {(dialog.type === DialogType.Config || dialog.type === DialogType.iframe) && <PrimaryButton onClick={() => props.dismissHandle(dialog)} disabled={props.onAction}>
                {props.onDismissAction && <span><Loading />&nbsp;</span>}Close
            </PrimaryButton>}
        </FreeFooterElement>}
    </div>
}