import styled from "@emotion/styled";
import { v4 } from "uuid";
import Constants from "../../../../Constants";
import ZI from "../../../../styles/ZIndex";
import { SecondaryButton } from "../../../../utils/CustomButton";
import DoccumentActionsBar from "../DoccumentActionInfoBar";
import { ObjectId } from "bson";

type TPopupControlBarProps = {
    documentId: ObjectId;
    fontSize: number;
    isPublished?: boolean;
    toggleInfo?: () => void;
    fontSizeIncrease?: () => number;
    fontSizeDecrease?: () => number;
    onClose?: () => void;
}

export const DocumentPopupControlBar = (props: TPopupControlBarProps) => {
    const _id = v4();

    return <>
        <DoccumentViewActionBar className={_id} style={{ float: "right", display: "inline-flex", width: "auto"}}>
            <DoccumentActionsBar {...props} beforeCloseGroupActions={() => {
                return [
                    <SecondaryButton key={v4()} faIcon="external-link-alt" onClick={() => {
                        window.open(`http://${window.location.host}/Documents/${props.documentId}`, "_blank");
                    }} />
                ];
            }} />
        </DoccumentViewActionBar>
    </>
}

const DoccumentViewActionBar = styled.div`
    margin-top:0.25rem;
    z-index: ${ZI.OffCanvasLow};
    right:0;

    > * {
        opacity: ${Constants.ActionDefaultOpacity};
        :hover {
            opacity:1
        }
    }

    * {
        margin: 0;
        padding: 0 0.25rem 0 0.25rem !important;
    }
`;
