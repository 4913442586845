import Observer from "../utils/Observer";

export default class HeaderService extends Observer {
    public static readonly TitleChangedEventKey = "TitleChangedEventKey";

    public static readonly AppName = "Unlimited";
    private static readonly _instance: HeaderService = new HeaderService();
    public static get Instance() {
        return this._instance;
    }

    private _title: string | JSX.Element = HeaderService.AppName;

    static get title() {
        return this.Instance._title;
    }

    static setTitle(title: string, value?: JSX.Element, ) {
        if(this.Instance._title === value) return;
        
        document.title = `${title}-${HeaderService.AppName}`;
        this.Instance._title = value ? value : title; 
        this.Instance.notifyChange(this.TitleChangedEventKey, "Title", value);
    }

    static subscribeChange(key: string, callBack: (field?: string, value?: any) => void): void {
        this.Instance.subscribeChange(this.TitleChangedEventKey, key, callBack);
    }
    
    static unsubscribeChange(key: string): void {
        this.Instance.unsubscribeChange(this.TitleChangedEventKey, key);
    }
}