import styled from "@emotion/styled";
import Constants from "../Constants";

export const ListHeaderStyled = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
    title {
        display: inline-block;
        font-size: 1.5em;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ListFilterStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    > * {
        opacity: ${Constants.InfoDefaultOpacity};
        margin-left: 0.25rem;

        :hover {
            opacity: 1;
        }
    }

    * {
        min-width: 0
    }

    > *:not(:last-child)::after {
        content: ' ; ';
    }
`;

export const ListFiltredItem = styled.span`
    > * {
        margin-left: 0.25rem;
    }
`;

export const ListActions = styled.div`
    > div > * {
        opacity: ${Constants.ActionDefaultOpacity};

        :hover {
            opacity: 1;
        }
    }

    > div {
        margin-right: 0.5rem;
    }
`;

export const EllipsisSpanStyled = styled.span`
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const RFSNDivStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    min-width: 0;
    text-overflow: ellipsis;
`