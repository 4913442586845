import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCommands } from "@remirror/react";
import { ButtonGroup } from "reactstrap";
import { ActionButton } from "../ActionButton";

export const AddIndent = () => {
    const commands = useCommands();
    
    return <ButtonGroup size="sm">
        <ActionButton enabled onClick={(evt) => commands.increaseIndent()}><FontAwesomeIcon icon="indent" /></ActionButton>
        <ActionButton enabled onClick={(evt) => commands.decreaseIndent()}><FontAwesomeIcon icon="outdent" /></ActionButton>
    </ButtonGroup>
}