import Icon from "@mdi/react";
import { mdiAllInclusive } from "@mdi/js";
import LeftOffcanvasService from "../../services/LeftOffcanvasService";
import { MainMenuTitle, MainMenu } from "./MainMenu";
export class MainMenuLeftCanvas {
  static Set() {
    LeftOffcanvasService.Push([<MainMenuTitle style={{
      color: "black"
    }}><Icon path={mdiAllInclusive} size="2em" /></MainMenuTitle>, <MainMenu />]);
  }
}
