import styled from "@emotion/styled";
import { mdiChevronDown, mdiChevronLeft, mdiReload } from "@mdi/js";
import React from "react";
import { ButtonGroup } from "reactstrap";
import { ISpace } from "../../../realm/schemas/ISpace";
import SectionContainer from "../../../styles/Section";
import { SecondaryButton } from "../../../utils/CustomButton";
import UserAndTimeInfos from "../DocumentList/UserAndTimeInfos";
import { DocumentFilterDefault, IDocumentFilter } from "../FilterAndOrder/IDocumentFilter";
import { DocumentFilterPopup } from "../FilterAndOrder/DocumentFilterPopup";
import PathList from "../Path/PathList";
import Constants from "../../../Constants";
import GlobalStateService from "../../../services/GlobalStateService";

type TSpaceListItemProps = {
    space: ISpace;
    selectable?: boolean;
    onSelected?: () => void;
    context?: string;
}

type TSpaceListItemState = {
    isOpen: boolean;
    filter: IDocumentFilter;
    isReloading?: boolean;
}

export class SpaceListItem extends React.Component<TSpaceListItemProps, TSpaceListItemState> {
    private _filterChangeHandle: (filter: IDocumentFilter) => void = () => {};
    private _reloadHandle: () => Promise<void> = async () => {};

    constructor(props: TSpaceListItemProps | Readonly<TSpaceListItemProps>) {
        super(props);

        let open = GlobalStateService.Get(`${this.stateKey}_open`);
        open = open  == null ? false : open;
        
        this.state = {
            isOpen: open,
            filter:  DocumentFilterDefault(),
        };
    }

    get stateKey() {
        return `SLI_${this.props.context}_${this.props.space.name}`;
    }

    toggleOpen = () => {
        this.setState(state => {
            return {
                isOpen: !state.isOpen,
                filter: !state.isOpen ? DocumentFilterDefault() : state.filter
            };
        }, () => {
            GlobalStateService.Set(`${this.stateKey}_open`, this.state.isOpen);
        });
    };

    filterChanged = (filter: IDocumentFilter) => {
        this.setState({
            filter: filter
        }, () => this._filterChangeHandle(this.state.filter));
    }

    filterChangeHandler = (handle: (filter: IDocumentFilter) => void) => {
        this._filterChangeHandle = handle;
    }

    reloadHandler = (handle: () => Promise<void>) => {
        this._reloadHandle = handle;
    }

    reload = async () => {
        this.setState({
            isReloading: true
        });

        await this._reloadHandle();

        this.setState({
            isReloading: false
        });
    }

    render = () => {
        return <SectionContainer style={{
            cursor: this.props.selectable ? "pointer" : undefined
        }} onClick={this.props.onSelected} >
            <SpaceTitle>
                {!this.props.selectable && <span onClick={this.toggleOpen} style={{
                    cursor: "pointer"
                }}>
                    <SecondaryButton maIcon={this.state.isOpen ? mdiChevronDown : mdiChevronLeft} link />
                    {this.props.space.name}
                </span>}
                {this.props.selectable && <span>{this.props.space.name}</span>}
                <span>
                    {this.state.isOpen && <>
                        <ButtonGroup size="sm">
                            <DocumentFilterPopup filter={this.state.filter} filterChanged={this.filterChanged} />
                            <SecondaryButton maIcon={mdiReload} link onClick={this.reload} iconSpin={this.state.isReloading} />
                        </ButtonGroup>
                    </>}
                </span>
            </SpaceTitle>
            {!this.props.selectable && <div style={{
                paddingLeft: "0.5rem"
            }}>
                {this.state.isOpen && <PathList spaceId={this.props.space._id} filter={this.state.filter} filterChangeHandler={this.filterChangeHandler} reloadHandler={this.reloadHandler} context={this.props.context} />}
                <DetailDetail>
                    <span></span>
                    <SpaceDetailInfo>
                        <UserAndTimeInfos 
                            userId={this.props.space.modifiedById ? this.props.space.modifiedById : this.props.space.createdById} 
                            dateTimeUTC={this.props.space.modifiedDateTimeUTC ? this.props.space.modifiedDateTimeUTC : this.props.space.createdDateTimeUTC} />
                    </SpaceDetailInfo>
                </DetailDetail>
            </div>}
        </SectionContainer>;
    };
}

const DetailDetail = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
`;

const SpaceDetailInfo = styled.span`
    opacity: ${Constants.InfoDefaultOpacity};
    :hover {
        opacity: 1;
    }
`;

const SpaceTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

