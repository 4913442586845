import { RGBColor } from "react-color";
import { isNumber } from "remirror";

export enum ColorSections {
  BG,

  HeaderBG,
  HeaderText,
  HeaderBottomBorder,

  FooterBG,
  FooterText,
  FooterTopBorder,

  SectionBG,
  SectionText,
  SectionBorder,

  TooltipBG,
  TooltipText,
  TooltipSeparator,

  PopupHeaderBG,
  PopupHeaderSeparator,
  PopupHeaderText,
  PopupBG,
  PopupBorder,
  PopupText,
  PopupFooterBG,
  PopupFooterSeparator,
  PopupFooterText,

  Text,
  Separator,

  OffcanvasHeaderBG,
  OffcanvasHeaderText,
  OffcanvasBobyBG,
  OffcanvasBodyText,

  SuccessColor,
  SuccessBG,
  SuccessBorder,

  BtnSuccessColor,
  BtnSuccessBG,
  BtnSuccessBorder,
  BtnSuccessHoverColor,
  BtnSuccessHoverBG,
  BtnSuccessHoverBorder, 
  BtnSuccessActiveColor,
  BtnSuccessActiveBG,
  BtnSuccessActiveBorder, 
  BtnSuccessFocusColor,
  BtnSuccessFocusBG,
  BtnSuccessFocusBorder,
  BtnSuccessFocusBoxShadow,

  DangerColor,
  DangerBG,
  DangerBorder,

  BtnDangerColor,
  BtnDangerBG,
  BtnDangerBorder,
  BtnDangerHoverColor,
  BtnDangerHoverBG,
  BtnDangerHoverBorder,
  BtnDangerActiveColor,
  BtnDangerActiveBG,
  BtnDangerActiveBorder,
  BtnDangerFocusColor,
  BtnDangerFocusBG,
  BtnDangerFocusBorder,
  BtnDangerFocusBoxShadow,

  WarningColor,
  WarningBG,
  WarningBorder,

  BtnWarningColor,
  BtnWarningBG,
  BtnWarningBorder,
  BtnWarningHoverColor,
  BtnWarningHoverBG,
  BtnWarningHoverBorder,
  BtnWarningActiveColor,
  BtnWarningActiveBG,
  BtnWarningActiveBorder,
  BtnWarningFocusColor,
  BtnWarningFocusBG,
  BtnWarningFocusBorder,
  BtnWarningFocusBoxShadow,

  PrimaryColor,
  PrimaryBG,
  PrimaryBorder,

  BtnPrimaryColor,
  BtnPrimaryBG,
  BtnPrimaryBorder,
  BtnPrimaryHoverColor,
  BtnPrimaryHoverBG,
  BtnPrimaryHoverBorder,
  BtnPrimaryActiveColor,
  BtnPrimaryActiveBG,
  BtnPrimaryActiveBorder,
  BtnPrimaryFocusColor,
  BtnPrimaryFocusBG,
  BtnPrimaryFocusBorder,
  BtnPrimaryFocusBoxShadow,

  SecondaryColor,
  SecondaryBG,
  SecondaryBorder,

  BtnSecondaryColor,
  BtnSecondaryBG,
  BtnSecondaryBorder,
  BtnSecondaryHoverColor,
  BtnSecondaryHoverBG,
  BtnSecondaryHoverBorder,
  BtnSecondaryActiveColor,
  BtnSecondaryActiveBG,
  BtnSecondaryActiveBorder,
  BtnSecondaryFocusColor,
  BtnSecondaryFocusBG,
  BtnSecondaryFocusBorder,
  BtnSecondaryFocusBoxShadow,

  InfoColor,
  InfoBG,
  InfoBorder,

  BtnInfoColor,
  BtnInfoBG,
  BtnInfoBorder,
  BtnInfoHoverColor,
  BtnInfoHoverBG,
  BtnInfoHoverBorder,
  BtnInfoActiveColor,
  BtnInfoActiveBG,
  BtnInfoActiveBorder,
  BtnInfoFocusColor,
  BtnInfoFocusBG,
  BtnInfoFocusBorder,
  BtnInfoFocusBoxShadow,

  InputColor,
  InputBG,
  InputBorder,
  InputPlaceholder,

  InputFocusColor,
  InputFocusBG,
  InputFocusBorder,
  InputFocusPlaceholder,
  InputFocusBoxShadow,

  InputSuccessColor,
  InputSuccessBG,
  InputSuccessBorder,
  InputSuccessPlaceholder,

  InputDangerColor,
  InputDangerBG,
  InputDangerBorder,
  InputDangerPlaceholder,

  InputWarningColor,
  InputWarningBG,
  InputWarningBorder,
  InputWarningPlaceholder,

  InputGroupTextColor,
  InputGroupTextBG,
  InputGroupTextBorder,

  SplitterBarColor,
  SplitterBarMovingColor,
  SplitterBarHoverColor,
}

export type HSLColor = {
  h: number;
  s: number;
  l: number;
  a?: number;
} 

export default class Colors {
  private _colors:Map<ColorSections, string>;

  constructor(colors?: Map<ColorSections, string>) {
    this._colors = colors ?? Colors.DefaultDark._colors;
  }

  public clone = () => {
    return new Colors(new Map(this._colors));
  }

  public get = (section: ColorSections, alpha:number = 1) => {
    if(alpha === 1) return this._colors.get(section) ?? "#000000";
    return Colors.toRGBAStringColor(this.getRGB(section), alpha);
  }

  public set = (section: ColorSections, hexColor: string) => {
    this._colors.set(section, hexColor);
    return this;
  }

  public get colors() {
    return Array.from(this._colors).map(([key, value]) => {
      return {
        key,
        code: ColorSections[key],
        value
      }
    });
  }

  public toJson = () => {
    return JSON.stringify(this.colors, null, 4);
  }

  public static LoadFromJson = (json: string) => {
    const parsedJson = JSON.parse(json);
    if(Array.isArray(parsedJson) && parsedJson.length === Colors.Current.colors.length) {
      const map = new Map(this.Current._colors);
      parsedJson.forEach(c => {
        if(isNumber(c.key) && c.value) {
          map.set(c.key, c.value);
        }
      });

      this.Current = new Colors(map);
    }
  }

  getRGB = (section: ColorSections) => {
    const color = this._colors.get(section);
    if(color) return Colors.hexToRgb(color);
    return {
      r:0,
      g:0,
      b:0,
      a:1
    };
  }

  private static _instance: Colors = new Colors();

  public static get Current() {
    return this._instance;
  }

  public static set Current(colors: Colors) {
    this._instance = colors;
  }

  public static get DefaultDark() {
    const colors:Map<ColorSections, string> = new Map<ColorSections, string>(); 
    
    colors.set(ColorSections.BG, "#1e1e1e"); // #1e1e1e -> #252526 -> #333333 -> #858585

    colors.set(ColorSections.Text, "#E4E6EB");
    colors.set(ColorSections.Separator, "#E4E6EB");

    colors.set(ColorSections.HeaderBG, "#242526");
    colors.set(ColorSections.HeaderText, "#E4E6EB");
    colors.set(ColorSections.HeaderBottomBorder, "#000000");

    colors.set(ColorSections.FooterBG, "#242526");
    colors.set(ColorSections.FooterText, "#E4E6EB");
    colors.set(ColorSections.FooterTopBorder, "#000000");

    colors.set(ColorSections.SectionBG, "#242526");
    colors.set(ColorSections.SectionText, "#E4E6EB");
    colors.set(ColorSections.SectionBorder, "#000000");

    colors.set(ColorSections.TooltipBG, "#b6bbc9");
    colors.set(ColorSections.TooltipText, "#363c49");
    colors.set(ColorSections.TooltipSeparator, "#363c49");

    colors.set(ColorSections.PopupHeaderBG, "#363c49");
    colors.set(ColorSections.PopupHeaderSeparator, "#363c49");
    colors.set(ColorSections.PopupHeaderText, "#E4E6EB");
    colors.set(ColorSections.PopupBG, "#575f75");
    colors.set(ColorSections.PopupBorder, "#363c49");
    colors.set(ColorSections.PopupText, "#c4c8d4");
    colors.set(ColorSections.PopupFooterBG, "#2b303b");
    colors.set(ColorSections.PopupFooterSeparator, "#363c49");
    colors.set(ColorSections.PopupFooterText, "#c4c8d4");

    colors.set(ColorSections.OffcanvasHeaderBG, "#16181d");
    colors.set(ColorSections.OffcanvasHeaderText, "#E4E6EB");
    colors.set(ColorSections.OffcanvasBobyBG, "#313335");
    colors.set(ColorSections.OffcanvasBodyText, "#E4E6EB");

    colors.set(ColorSections.SuccessColor, "#0f5132");
    colors.set(ColorSections.SuccessBG, "#d1e7dd");
    colors.set(ColorSections.SuccessBorder, "#badbcc");

    colors.set(ColorSections.BtnSuccessColor, "#ffffff");
    colors.set(ColorSections.BtnSuccessBG, "#198754");
    colors.set(ColorSections.BtnSuccessBorder, "#198754");
    colors.set(ColorSections.BtnSuccessHoverColor, "#ffffff");
    colors.set(ColorSections.BtnSuccessHoverBG, "#157347");
    colors.set(ColorSections.BtnSuccessHoverBorder, "#146c43");
    colors.set(ColorSections.BtnSuccessActiveColor, "#ffffff");
    colors.set(ColorSections.BtnSuccessActiveBG, "#146c43");
    colors.set(ColorSections.BtnSuccessActiveBorder, "#13653f");
    colors.set(ColorSections.BtnSuccessFocusColor, "#ffffff");
    colors.set(ColorSections.BtnSuccessFocusBG, "#157347");
    colors.set(ColorSections.BtnSuccessFocusBorder, "#146c43");
    colors.set(ColorSections.BtnSuccessFocusBoxShadow, "#3c996e");

    colors.set(ColorSections.DangerColor, "#842029");
    colors.set(ColorSections.DangerBG, "#f8d7da");
    colors.set(ColorSections.DangerBorder, "#f5c2c7");

    colors.set(ColorSections.BtnDangerColor, "#ffffff");
    colors.set(ColorSections.BtnDangerBG, "#dc3545");
    colors.set(ColorSections.BtnDangerBorder, "#dc3545");
    colors.set(ColorSections.BtnDangerHoverColor, "#ffffff");
    colors.set(ColorSections.BtnDangerHoverBG, "#bb2d3b");
    colors.set(ColorSections.BtnDangerHoverBorder, "#b02a37");
    colors.set(ColorSections.BtnDangerActiveColor, "#ffffff");
    colors.set(ColorSections.BtnDangerActiveBG, "#b02a37");
    colors.set(ColorSections.BtnDangerActiveBorder, "#a52834");
    colors.set(ColorSections.BtnDangerFocusColor, "#ffffff");
    colors.set(ColorSections.BtnDangerFocusBG, "#bb2d3b");
    colors.set(ColorSections.BtnDangerFocusBorder, "#b02a37");
    colors.set(ColorSections.BtnDangerFocusBoxShadow, "#e15361");
    

    colors.set(ColorSections.WarningColor, "#664d03");
    colors.set(ColorSections.WarningBG, "#fff3cd");
    colors.set(ColorSections.WarningBorder, "#ffecb5");

    colors.set(ColorSections.BtnWarningColor, "#000000");
    colors.set(ColorSections.BtnWarningBG, "#ffc107");
    colors.set(ColorSections.BtnWarningBorder, "#ffc107");
    colors.set(ColorSections.BtnWarningHoverColor, "#000000");
    colors.set(ColorSections.BtnWarningHoverBG, "#ffca2c");
    colors.set(ColorSections.BtnWarningHoverBorder, "#ffc720");
    colors.set(ColorSections.BtnWarningActiveColor, "#000000");
    colors.set(ColorSections.BtnWarningActiveBG, "#ffcd39");
    colors.set(ColorSections.BtnWarningActiveBorder, "#ffc720");
    colors.set(ColorSections.BtnWarningFocusColor, "#000000");
    colors.set(ColorSections.BtnWarningFocusBG, "#ffca2c");
    colors.set(ColorSections.BtnWarningFocusBorder, "#ffc720");
    colors.set(ColorSections.BtnWarningFocusBoxShadow, "#d9a406");

    colors.set(ColorSections.PrimaryColor, "#084298");
    colors.set(ColorSections.PrimaryBG, "#cfe2ff");
    colors.set(ColorSections.PrimaryBorder, "#b6d4fe");

    colors.set(ColorSections.BtnPrimaryColor, "#ffffff");
    colors.set(ColorSections.BtnPrimaryBG, "#0d6efd");
    colors.set(ColorSections.BtnPrimaryBorder, "#0d6efd");
    colors.set(ColorSections.BtnPrimaryHoverColor, "#ffffff");
    colors.set(ColorSections.BtnPrimaryHoverBG, "#0b5ed7");
    colors.set(ColorSections.BtnPrimaryHoverBorder, "#0a58ca");
    colors.set(ColorSections.BtnPrimaryActiveColor, "#ffffff");
    colors.set(ColorSections.BtnPrimaryActiveBG, "#0a58ca");
    colors.set(ColorSections.BtnPrimaryActiveBorder, "#0a53be");
    colors.set(ColorSections.BtnPrimaryFocusColor, "#ffffff");
    colors.set(ColorSections.BtnPrimaryFocusBG, "#0b5ed7");
    colors.set(ColorSections.BtnPrimaryFocusBorder, "#0a58ca");
    colors.set(ColorSections.BtnPrimaryFocusBoxShadow, "#3184f5");

    colors.set(ColorSections.SecondaryColor, "#41464b");
    colors.set(ColorSections.SecondaryBG, "#e2e3e5");
    colors.set(ColorSections.SecondaryBorder, "#e2e3e5");

    colors.set(ColorSections.BtnSecondaryColor, "#ffffff");
    colors.set(ColorSections.BtnSecondaryBG, "#6c757d");
    colors.set(ColorSections.BtnSecondaryBorder, "#6c757d");
    colors.set(ColorSections.BtnSecondaryHoverColor, "#ffffff");
    colors.set(ColorSections.BtnSecondaryHoverBG, "#5c636a");
    colors.set(ColorSections.BtnSecondaryHoverBorder, "#565e64");
    colors.set(ColorSections.BtnSecondaryActiveColor, "#ffffff");
    colors.set(ColorSections.BtnSecondaryActiveBG, "#565e64");
    colors.set(ColorSections.BtnSecondaryActiveBorder, "#51585e");
    colors.set(ColorSections.BtnSecondaryFocusColor, "#ffffff");
    colors.set(ColorSections.BtnSecondaryFocusBG, "#5c636a");
    colors.set(ColorSections.BtnSecondaryFocusBorder, "#565e64");
    colors.set(ColorSections.BtnSecondaryFocusBoxShadow, "#828a91");

    colors.set(ColorSections.InfoColor, "#055160");
    colors.set(ColorSections.InfoBG, "#cff4fc");
    colors.set(ColorSections.InfoBorder, "#b6effb");

    colors.set(ColorSections.BtnInfoColor, "#000000");
    colors.set(ColorSections.BtnInfoBG, "#0dcaf0");
    colors.set(ColorSections.BtnInfoBorder, "#0dcaf0");
    colors.set(ColorSections.BtnInfoHoverColor, "#000000");
    colors.set(ColorSections.BtnInfoHoverBG, "#31d2f2");
    colors.set(ColorSections.BtnInfoHoverBorder, "#25cff2");
    colors.set(ColorSections.BtnInfoActiveColor, "#000000");
    colors.set(ColorSections.BtnInfoActiveBG, "#3dd5f3");
    colors.set(ColorSections.BtnInfoActiveBorder, "#25cff2");
    colors.set(ColorSections.BtnInfoFocusColor, "#000000");
    colors.set(ColorSections.BtnInfoFocusBG, "#31d2f2");
    colors.set(ColorSections.BtnInfoFocusBorder, "#25cff2");
    colors.set(ColorSections.BtnInfoFocusBoxShadow, "#0baccc");

    colors.set(ColorSections.InputColor, "#212529");
    colors.set(ColorSections.InputBG, "#969696");
    colors.set(ColorSections.InputBorder, "#000000");
    colors.set(ColorSections.InputPlaceholder, "#cfd8dc");

    colors.set(ColorSections.InputFocusColor, "#000000");
    colors.set(ColorSections.InputFocusBG, "#607d8b");
    colors.set(ColorSections.InputFocusBorder, "#263238");
    colors.set(ColorSections.InputFocusPlaceholder, "#cfd8dc");
    colors.set(ColorSections.InputFocusBoxShadow, "#455a64");

    colors.set(ColorSections.InputGroupTextColor, "#525252");
    colors.set(ColorSections.InputGroupTextBG, "#d9d9d9");
    colors.set(ColorSections.InputGroupTextBorder, "#525252");

    colors.set(ColorSections.SplitterBarColor, "#666666");
    colors.set(ColorSections.SplitterBarMovingColor, "#333333");
    colors.set(ColorSections.SplitterBarHoverColor, "#333333");

    return new Colors(colors);
  }

  static get = (section: ColorSections, alpha:number = 1) => {
    return this.Current.get(section, alpha)
  }

  static getRGB = (section: ColorSections) => {
    return this.Current.getRGB(section);
  }

  static getHex = (section: ColorSections) => {
    const color = this.Current._colors.get(section);
    return color ?? "#000000";
  }

  static hexToRgb: (hex: string, alpha?: number) => RGBColor = (hex: string, alpha: number = 1) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: alpha
    } : {
      r: 0,
      g: 0,
      b: 0,
      a: 0.5
    };
  }

  static toRGBAStringColor: (color: RGBColor, aplha?: number) => string = (color: RGBColor, aplha?: number) => {
    if(color) return `rgba(${color.r}, ${color.g}, ${color.b}, ${aplha ?? color.a})`;
    return "rgba(0,0,0,1)";
  }

  static toHSLAStringColor: (color: HSLColor, l?: number, aplha?: number) => string = (color: HSLColor, l?: number, aplha?: number) => {
    if(color) return `hsla(${color.h}, ${color.s}%, ${l ? color.l * l : color.l}%, ${aplha ?? color.a})`;
    return "hsla(0,0,0,1)";
  }

  private static rgbtohex = (rgb: number) => {
    var first, second; // makes the two hex code for each rgb value
  
    first = Math.floor(rgb / 16); //get first unit of hex
    second = rgb % 16; //get second unit of hex
    // convert to string with hex base 16
    first = first.toString(16);
    second = second.toString(16);
    //concatenate the two units of the hex
    var rgbtohex = first + second;
    //return the two unit hex code for the r,g,b value
    return rgbtohex;
  }

  static rgbToHex = (color: RGBColor) => {
    var r_str = Colors.rgbtohex(color.r),
    g_str = Colors.rgbtohex(color.g),
    b_str = Colors.rgbtohex(color.b);
    //concatenate the final string for the output
  
    return `#${r_str}${g_str}${b_str}`;
  }

  static rgbToHsl = (color: RGBColor) => {
    let r = color.r;
    let b = color.b;
    let g = color.g;

    r /= 255;
    g /= 255;
    b /= 255;
    let max: number = Math.max(r, g, b);
    let min: number = Math.min(r, g, b);
    let h: number = (max + min) / 2;
    let s: number = (max + min) / 2;
    let l: number = (max + min) / 2;

    if(max === min){
        h = s = 0; // achromatic
    }else{
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max){
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    const hsl: HSLColor = {
      h: h * 360,
      s: s * 100,
      l: l * 100,
      a: 1
    }
    return hsl;
  }

  static RandomColor = () => {
    var r, g, b, rg, gb, rb;
    var range = 255; // controls the range of r,g,b you would like
    //reduce the range if you want more darker colors
    var sep = range / 4; // controls the minimum separation for saturation
    //note- keep sep < range/3 otherwise may crash browser due to performance
    //reduce the sep if you do not mind pastel colors
    //generate r,g,b, values as long as any difference is < separation
    do {
      r = Math.floor(Math.random() * range);
      g = Math.floor(Math.random() * range);
      b = Math.floor(Math.random() * range);
  
      rg = Math.abs(r - g);
      gb = Math.abs(g - b);
      rb = Math.abs(r - b);
    } while (rg < sep || gb < sep || rb < sep);
  
    //convert the r,g,b numbers to hex code by calling the rgbto hex function
    var r_str = Colors.rgbtohex(r),
      g_str = Colors.rgbtohex(g),
      b_str = Colors.rgbtohex(b);
    //concatenate the final string for the output
    var final = '#' + r_str + g_str + b_str;
  
    //output random color
    return final;
  }

  static LightenDarken(colHex: string, amt: number) {
    let usePound = false;

    if (colHex[0] === "#") {
      colHex = colHex.slice(1);
      usePound = true;
    }

    let num = parseInt(colHex,16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  }
}

export const RandomRGBAColorString = (a: number) => {
  var r, g, b, rg, gb, rb;
  var range = 255; // controls the range of r,g,b you would like
  //reduce the range if you want more darker colors
  var sep = range / 5; // controls the minimum separation for saturation
  //note- keep sep < range/3 otherwise may crash browser due to performance
  //reduce the sep if you do not mind pastel colors
  //generate r,g,b, values as long as any difference is < separation
  do {
    r = Math.floor(Math.random() * range);
    g = Math.floor(Math.random() * range);
    b = Math.floor(Math.random() * range);

    rg = Math.abs(r - g);
    gb = Math.abs(g - b);
    rb = Math.abs(r - b);
  } while (rg < sep || gb < sep || rb < sep);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
  

export const RandomRGBAColor:(a: number) => RGBColor = (a: number) => {
  var r, g, b, rg, gb, rb;
  var range = 255; // controls the range of r,g,b you would like
  //reduce the range if you want more darker colors
  var sep = range / 5; // controls the minimum separation for saturation
  //note- keep sep < range/3 otherwise may crash browser due to performance
  //reduce the sep if you do not mind pastel colors
  //generate r,g,b, values as long as any difference is < separation
  do {
    r = Math.floor(Math.random() * range);
    g = Math.floor(Math.random() * range);
    b = Math.floor(Math.random() * range);

    rg = Math.abs(r - g);
    gb = Math.abs(g - b);
    rb = Math.abs(r - b);
  } while (rg < sep || gb < sep || rb < sep);

  return {
    r: r,
    g: g,
    b: b,
    a: a
  };
}
