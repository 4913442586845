import { mdiCheckboxBlankOutline, mdiCheckboxIntermediate, mdiEarth, mdiShield } from "@mdi/js";
import Icon from "@mdi/react";
import { DateTime } from "luxon";
import moment from "moment";
import React from "react";
import { InputGroupText } from "reactstrap";
import Localized from "../../Localization/Localized";
import { CustomSelect, CustomInput } from "../CustomInput";
import { CustomInputGroup } from "../CustomInputGroup";
import { TooltipPopup, TooltipItem } from "../TooltipPopup";
import { FilterType, ITextAndDateAdnPrivacyFilter } from "./Filter";

interface TFilterPopupContentProps<TFilter extends ITextAndDateAdnPrivacyFilter> {
    filter?: TFilter;
    filterChanged: (filter: TFilter) => void;
}

interface TFilterPopupContentState<TFilter extends ITextAndDateAdnPrivacyFilter> {
    filter: TFilter;
    startDate: string;
    endDate: string;
    skip: string;
    limit: string;
}

export abstract class FilterPopupContent<TFilter extends ITextAndDateAdnPrivacyFilter> extends React.Component<TFilterPopupContentProps<TFilter>, TFilterPopupContentState<TFilter>> {
    protected _textFilterOn: boolean = true;
    protected _dateFilterOn: boolean = true;
    protected _privacyFilterOn: boolean = true;
    constructor(props: TFilterPopupContentProps<TFilter> | Readonly<TFilterPopupContentProps<TFilter>>) {
        super(props);

        this.state = {
            filter: props.filter ?? this.DefaultFilter(),
            startDate: props.filter?.startDate?.toFormat("yyyy-MM-dd HH:mm:ss") ?? "",
            endDate: props.filter?.endDate?.toFormat("yyyy-MM-dd HH:mm:ss") ?? "",
            skip: props.filter?.skip.toString() ?? "0",
            limit: props.filter?.limit.toString() ?? "100",
        };
    }

    abstract get textFilterLabel(): string;

    abstract get publicFilterLabel(): string;

    abstract get privateFilterLabel(): string;

    abstract DefaultFilter(): TFilter;

    protected onChanged = () => {
        this.props.filterChanged(this.state.filter);
    }

    onPrivateToggle = () => {
        const filter = this.state.filter;
        filter.isPrivateOnly = !filter.isPrivateOnly;

        this.setState({
            filter: filter
        }, this.onChanged);
    }

    onPublicToggle = () => {
        const filter = this.state.filter;
        filter.isPublicOnly = !filter.isPublicOnly;

        this.setState({
            filter: filter
        }, this.onChanged);
    }

    private titleChange = (value: string) => {
        this.setState(state => {
            const f = state.filter;
            f.text = {
                filter: value,
                type: f.text?.type ?? FilterType.Contain
            }
            return  {
                filter: f
            }
        }, this.onChanged);
    }

    private titleTypeChange = (value: string) => {
        let v = FilterType.Contain
        if(value === "SW") v = FilterType.StartWith;
        if(value === "EW") v = FilterType.EndWith;
        if(value === "E") v = FilterType.Equal;

        this.setState(state => {
            const f = state.filter;
            f.text = {
                filter: f.text?.filter ?? "",
                type: v
            }
            return  {
                filter: f
            }
        }, this.onChanged);
    }

    protected filterTypeToString = (type?: FilterType) => {
        if(!type) return type;

        switch (type) {
            case FilterType.EndWith:
                return "EW";
            case FilterType.Equal:
                return "E";
            case FilterType.Contain:
                return "C";
            default:
                return "SW";
        }
    }

    private startDateChange = (value: string) => {
        var dt = moment(value);
        this.setState(state => {
            const filter = state.filter;
            
            if(dt.isValid()) {
                filter.startDate = DateTime.fromJSDate(dt.toDate());
            }
            else {
                filter.startDate = undefined;
            }
            return {
                startDate: value,
                filter: filter
            }
        }, this.onChanged);
    }

    private endDateChange = (value: string) => {
        var dt = moment(value);
        this.setState(state => {
            const filter = state.filter;
            
            if(dt.isValid()) {
                filter.endDate = DateTime.fromJSDate(dt.toDate());
            }
            else {
                filter.endDate = undefined;
            }
            return {
                endDate: value,
                filter: filter
            }
        }, this.onChanged);
    }

    private skipChange = (value: string) => {
        var skip = parseInt(value);
        this.setState(state => {
            const filter = state.filter;
            
            if(!isNaN(skip) && skip >= 0) {
                filter.skip = skip;
            }
            else {
                filter.skip = 0;
            }
            return {
                skip: filter.skip.toString(),
                filter: filter
            }
        }, this.onChanged);
    }

    private limitChange = (value: string) => {
        var limit = parseInt(value);
        this.setState(state => {
            const filter = state.filter;
            
            if(!isNaN(limit) && limit > 0) {
                filter.limit = limit;
            }
            else {
                filter.limit = 1;
            }
            return {
                limit: filter.limit.toString(),
                filter: filter
            }
        }, this.onChanged);
    }

    protected abstract beforeTitle(): JSX.Element[];

    protected abstract betweenTitleAndDate(): JSX.Element[];

    protected abstract afterDate(): JSX.Element[];

    render = () => {
        const bt = this.beforeTitle();
        const btd = this.betweenTitleAndDate();
        const ad = this.afterDate();
        return <div style={{ minWidth: "50vw"}}>
            <TooltipPopup>
                {this._privacyFilterOn && <TooltipItem noCursor>
                    <span onClick={this.onPublicToggle} style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Icon path={this.state.filter.isPublicOnly ? mdiCheckboxIntermediate : mdiCheckboxBlankOutline} size="1.5em" /> 
                        &nbsp;<Icon path={mdiEarth} size="1em" /> 
                        &nbsp;{this.publicFilterLabel}
                    </span>
                    <span onClick={this.onPrivateToggle} style={{
                        marginLeft: "1.5em",
                        cursor: "pointer",                       
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Icon path={this.state.filter.isPrivateOnly ? mdiCheckboxIntermediate : mdiCheckboxBlankOutline} size="1.5em" /> 
                        &nbsp;<Icon path={mdiShield} size="1em" /> 
                        &nbsp;{this.privateFilterLabel}</span>
                </TooltipItem>}
                {bt && bt.length > 0 && bt.map((e, i) => <TooltipItem key={i} noCursor>{e}</TooltipItem>)}
                {this._textFilterOn && <TooltipItem noCursor>
                    <CustomInputGroup>
                        <InputGroupText>{this.textFilterLabel}</InputGroupText>
                        <CustomSelect onChange={(evt) => this.titleTypeChange(evt.currentTarget.value)} value={this.filterTypeToString(this.state.filter.text?.type)}>
                            <option value={this.filterTypeToString(FilterType.Contain)}>{Localized.General.Contain}</option>
                            <option value={this.filterTypeToString(FilterType.StartWith)}>{Localized.General.StartWith}</option>
                            <option value={this.filterTypeToString(FilterType.EndWith)}>{Localized.General.EndWith}</option>
                            <option value={this.filterTypeToString(FilterType.Equal)}>{Localized.General.Equal}</option>
                        </CustomSelect>
                        <CustomInput value={this.state.filter.text?.filter ?? ""} onChange={(evt) => this.titleChange(evt.currentTarget.value)} />
                    </CustomInputGroup>
                </TooltipItem>}
                {btd && btd.length > 0 && btd.map((e, i) => <TooltipItem key={i} noCursor>{e}</TooltipItem>)}
                {this._dateFilterOn && <>
                    <TooltipItem noCursor>
                        <CustomInputGroup>
                            <InputGroupText>Start date</InputGroupText>
                            <CustomInput value={this.state.startDate} onChange={(evt) => this.startDateChange(evt.currentTarget.value)} />
                            <InputGroupText>
                                {(this.state.filter.startDate && this.state.startDate) && this.state.filter.startDate.toFormat("yyyy-MM-dd HH:mm")}
                                {(!this.state.filter.startDate && this.state.startDate) && Localized.GeneralError.DateInvalid}
                            </InputGroupText>
                        </CustomInputGroup>
                    </TooltipItem>
                    <TooltipItem noCursor>
                        <CustomInputGroup>
                            <InputGroupText>End date</InputGroupText>
                            <CustomInput value={this.state.endDate} onChange={(evt) => this.endDateChange(evt.currentTarget.value)} />
                            <InputGroupText>
                                {(this.state.filter.endDate && this.state.endDate) && this.state.filter.endDate.toFormat("yyyy-MM-dd HH:mm")}
                                {(!this.state.filter.endDate && this.state.endDate) && Localized.GeneralError.DateInvalid}
                            </InputGroupText>
                        </CustomInputGroup>
                    </TooltipItem>
                </>}
                {ad && ad.length > 0 && ad.map((e, i) => <TooltipItem key={i} noCursor>{e}</TooltipItem>)}
                <TooltipItem noCursor>
                    <CustomInputGroup>
                        <InputGroupText>Skip</InputGroupText>
                        <CustomInput value={this.state.skip} onChange={(evt) => this.skipChange(evt.currentTarget.value)} />
                        <InputGroupText>Limit</InputGroupText>
                        <CustomInput value={this.state.limit} onChange={(evt) => this.limitChange(evt.currentTarget.value)} />
                    </CustomInputGroup>
                </TooltipItem>
        </TooltipPopup>
        </div>;
    };
}
