import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCommands } from "@remirror/react";
import { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { Dialog } from "../../../../../utils/dialog/DialogManager";
import { ActionButton } from "../ActionButton";
import { mdiFormatFont, mdiMenuDown } from '@mdi/js'; 
import Icon, { Stack } from '@mdi/react';
import styled from "@emotion/styled";

export type TAction = {
    icon: JSX.Element | string;
    active: boolean;
    enabled: boolean;
    onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}

export const toAction: (icon: JSX.Element | string, active: boolean, enabled: boolean, onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void) => TAction 
= (icon: JSX.Element | string, active: boolean, enabled: boolean, onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void) => {
    return {
        icon,
        active,
        enabled,
        onClick
    }
}

const FontFamily = [
    { name: "Arial", group: "sans-serif" },
    { name: "Arial Black", group: "sans-serif" },
    { name: "Tahoma", group: "sans-serif" },
    { name: "Verdana", group: "sans-serif" },
    { name: "Trebuchet MS", group: "sans-serif" },
    { name: "Impact", group: "sans-serif" },
    { name: "Times New Roman", group: "serif" },
    { name: "Didot", group: "serif" },
    { name: "Georgia", group: "serif" },
    { name: "American Typewriter", group: "serif" },
    { name: "Andalé Mono", group: "monospace" },
    { name: "Courier", group: "monospace" },
    { name: "Lucida Console", group: "monospace" },
    { name: "Monaco", group: "monospace" },
    { name: "Bradley Hand", group: "cursive" },
    { name: "Brush Script MT", group: "cursive" },
    { name: "Luminari", group: "fantasy" },
    { name: "Comic Sans MS", group: "cursive" }
]

const OpenFontFamilySelector = (cmd: any, evt: React.MouseEvent<HTMLButtonElement>) => {
    const actions = FontFamily.map(m => toAction(<span style={{ fontFamily: m.name }}>{m.name} ({m.group})</span>, false, true, () => cmd.setFontFamily(m.name)));
    let dismiss: () => void = () => { };

    Dialog.tooltip(<div>
        {actions.map((m, i) => <div key={i}>
            <Button size="sm" onMouseDown={(event) => event.preventDefault()}
                onClick={(evt) => {
                    m.onClick(evt);
                    dismiss();
                }}
                active={m.active}
                disabled={m.enabled === false}
            >{m.icon}</Button>
        </div>)}
    </div>, {
        handle: evt.currentTarget,
        dispatchDismiss: (d) => dismiss = d
    });
}

export const AddFont = () => {
    const [open, setOpen] = useState(false);
    const commands = useCommands();

    return <ButtonGroup size="sm">
        <Button onClick={() => setOpen(!open)}>
            <Toggle size="1.5em" open={open}>
                <Icon path={mdiFormatFont} size="1.5em" />
                <Icon path={mdiMenuDown} />
            </Toggle>
        </Button>
        {open && <>
            <ActionButton enabled onClick={(evt) => OpenFontFamilySelector(commands, evt)}><Icon path={mdiFormatFont} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={() => commands.decreaseFontSize()}><span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="font" />
                <FontAwesomeIcon icon="compress-alt" transform="rotate--45 right-10" />
            </span></ActionButton>
            <ActionButton enabled onClick={() => commands.increaseFontSize()}><span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="font" />
                <FontAwesomeIcon icon="expand-alt" transform="rotate--45 right-10" />
            </span></ActionButton>
        </>}
    </ButtonGroup>
}

type TProps = {
    open: boolean;
}

const Toggle = styled(Stack)<TProps>`
    > g:first-of-type {
        transform: scale(0.85) translate(-5px, 0px)!important;
        transform-origin: center center;
    }
    > path:first-of-type {
        transform: ${props => `scale(1.125) translate(8px, 0px) rotate(${props.open ? "-" : ""}90deg) !important`};
        transform-origin: center center;
    }
`;
