import { RealmApp } from "./RealmApp";
import { IDocument } from "./schemas/Document/IDocument";
import IDocument_Group from "./schemas/Document/IDocument_Group";
import { ISpace } from "./schemas/ISpace";
import { IProfile } from "./schemas/Profile/IProfile";

enum mongodb {
    service = "unlimited-mongodb-atlas",
}

export class UMongoDB {
    public static get profiles() {
        return this.mongoClient?.collection<IProfile>("profiles");
    }

    public static get spaces() {
        return this.mongoClient?.collection<ISpace>("spaces");
    }

    public static get documents() {
        return this.mongoClient?.collection<IDocument>("documents");
    }

    public static get document_groups() {
        return this.mongoClient?.collection<IDocument_Group>("document_groups");
    }

    private static get mongoClient() {
        return RealmApp.currentUser?.mongoClient(mongodb.service).db("unlimited"); 
    }

    public static Functions(): any {
        return RealmApp.currentUser?.functions;
    }
};

export class FMongoDB {
    private static get mongoClient() {
        return RealmApp.currentUser?.mongoClient(mongodb.service).db("Finance"); 
    }

    public static Functions(): any {
        return RealmApp.currentUser?.functions;
    }
};
