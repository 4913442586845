import { ActionButton } from "../ActionButton";
import Icon, { Stack } from '@mdi/react';
import { mdiFormatHeader1, mdiFormatHeader2, mdiFormatHeader3, mdiFormatHeader4, mdiFormatHeader5, mdiFormatHeader6, mdiAlphaH, mdiMenuDown } from '@mdi/js';
import { useActive, useCommands } from "@remirror/react";
import { ButtonGroup } from "reactstrap";
import { useState } from "react";
import styled from "@emotion/styled";
import { SecondaryButton } from "../../../../../utils/CustomButton";

export const AddHeader = () => {
    const [open, setOpen] = useState(false);
    const commands = useCommands();
    const active = useActive(true);

    return <ButtonGroup size="sm">
        <SecondaryButton onClick={() => setOpen(!open)}>
            <Toggle size="1.5em" open={open}>
                <Icon path={mdiAlphaH} />
                <Icon path={mdiMenuDown} />
            </Toggle>
        </SecondaryButton>
        {open && <>
            <ActionButton active={active.heading({ level: 1 })} enabled onClick={() => commands.toggleHeading({ level: 1 })}><Icon path={mdiFormatHeader1} size="1.5em" /></ActionButton>
            <ActionButton active={active.heading({ level: 2 })} enabled onClick={() => commands.toggleHeading({ level: 2 })}><Icon path={mdiFormatHeader2} size="1.5em" /></ActionButton>
            <ActionButton active={active.heading({ level: 3 })} enabled onClick={() => commands.toggleHeading({ level: 3 })}><Icon path={mdiFormatHeader3} size="1.5em" /></ActionButton>
            <ActionButton active={active.heading({ level: 4 })} enabled onClick={() => commands.toggleHeading({ level: 4 })}><Icon path={mdiFormatHeader4} size="1.5em" /></ActionButton>
            <ActionButton active={active.heading({ level: 5 })} enabled onClick={() => commands.toggleHeading({ level: 5 })}><Icon path={mdiFormatHeader5} size="1.5em" /></ActionButton>
            <ActionButton active={active.heading({ level: 6 })} enabled onClick={() => commands.toggleHeading({ level: 6 })}><Icon path={mdiFormatHeader6} size="1.5em" /></ActionButton>
        </>}
    </ButtonGroup>
}

type TProps = {
    open: boolean;
}

const Toggle = styled(Stack)<TProps>`
    path:first-of-type {
        transform: scale(1.5) !important;
        transform-origin: center center;
    }
    path:last-of-type {
        transform: ${props => `scale(1.125) translate(8px, 0px) rotate(${props.open ? "-" : ""}90deg) !important`};
        transform-origin: center center;
    }
`;