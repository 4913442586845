import styled from "@emotion/styled";
import { mdiCloseOutline, mdiCloseThick, mdiContentSaveMove, mdiPlusThick } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import Localized from "../../../Localization/Localized";
import Colors, { ColorSections } from "../../../utils/Color/MainColors";
import { SuccessButton, WarningButton } from "../../../utils/CustomButton";
import { CustomInput } from "../../../utils/CustomInput";
import { CustomInputGroup } from "../../../utils/CustomInputGroup";
import { Msg } from "../../../utils/Messenger";

type KeysProps = {
    keys: string[];
    isEditable?: boolean;
    keysChanged?: (keys: string[]) => Promise<boolean>;
 }
 
 type KeysState = {
     keys: string[];
     editKeyIndex: number;
     adding: boolean;
     saving: boolean;
 }

export default class Keys extends React.Component<KeysProps, KeysState> {
    constructor(props: KeysProps | Readonly<KeysProps>) {
        super(props);

        this.state = {
            keys: this.props.keys,
            editKeyIndex: -1,
            adding: false,
            saving: false
        };
    }

    save = async (keys: string[]) => {
        await new Promise<void>(resolve => {
            this.setState({
                keys: keys,
                saving: true
            }, () => resolve());
        });

        if(this.props.keysChanged)
        {
            const result = await this.props.keysChanged(keys);

            if(result)
            {
                this.setState({
                    keys: keys,
                    editKeyIndex: -1,
                    saving: false,
                    adding: false
                });
            }
        } else {
            this.setState({
                editKeyIndex: -1,
                saving: false,
                adding: false
            });
        }
    }

    edit = async (index: number, key: string) => {
        let keys = this.state.keys;

        const found = keys.find((f, i) => i !== index && f === key);

        if(found) {
            Msg.error(Localized.Document.KeyAlreadyExist);
            return;
        }

        keys[index] = key;

        await this.save(keys);
    }



    add = () => {
        const keys = this.state.keys;
        keys.push(Localized.Document.NewKey);

        this.setState({
            keys: keys
        });
    };

    remove = async (index: number) => {
        let keys = this.state.keys;

        keys = keys.filter((f, i) => i !== index);

        await this.save(keys);
    }

    render = () => {
        const showAdd = this.props.isEditable && !this.state.adding;
        return <KeysContainerStyled>
            <div>Keys:</div>
            <div style={{ width: "100%" }}>
                <KeysStyled>
                    {this.state.keys.map((k, i) => <KeyStyled key={k}>
                        {this.state.editKeyIndex !== i && <span style={{
                            cursor: "pointer"
                        }} onDoubleClick={() => {
                            this.setState({
                                editKeyIndex: i
                            })
                        }}>{k}</span>}
                        {this.state.editKeyIndex === i && <EditKey value={k} close={() => {
                            this.setState({
                                editKeyIndex: -1
                            });
                        }} save={(v) => {
                            this.edit(i, v);
                        }} />}
                        {this.props.isEditable && this.state.editKeyIndex !== i && <RemoveKey onClick={() => this.remove(i)}><Icon path={mdiCloseThick} size="1.5em" /></RemoveKey>}
                    </KeyStyled>)}
                </KeysStyled>
            </div>
            {showAdd && <div>
                <KeyAddStyled onClick={this.add}><Icon path={mdiPlusThick} size="1.5em" /></KeyAddStyled>
            </div>}
        </KeysContainerStyled>;
    };
}

type EditKeyProps = {
    value: string;
    close: () => void;
    save: (key: string) => void;
}

const EditKey = (props: EditKeyProps) => {
    const [key, setKey] = useState(props.value);

    const change = (value: string) => {
        setKey(value);
    }

    const save = () => {
        props.save(key);
    }

    const cancel = () => {
        setKey(props.value);
        props.close();
    }

    return <CustomInputGroup>
    <CustomInput value={key} onChange={(evt) => change(evt.currentTarget.value)} />
    <SuccessButton onClick={save}><Icon path={mdiContentSaveMove} size="1.5em" /></SuccessButton>
    <WarningButton onClick={cancel}><Icon path={mdiCloseOutline} size="1.5em" /></WarningButton>
</CustomInputGroup>
}
 
const KeysContainerStyled = styled.div`
    display: flex;
    font-size: 0.8em;
    align-items: center;
    justify-content: start;
`;
 
const KeysStyled = styled.div`
    display: flex;
    flex-wrap: wrap;

    > * {
        margin-left: 0.25rem;
    }
`;

const KeyStyled = styled.div`
    padding: 0 0.25rem 0 0.25rem; 
    border: 1px solid ${Colors.get(ColorSections.Text)};
    border-radius: 0.25rem;
    white-space: nowrap;
`;

const KeyAddStyled = styled.span`
    cursor: pointer;
    width: 1em;
    color: ${Colors.toHSLAStringColor(Colors.rgbToHsl(Colors.getRGB(ColorSections.PrimaryColor)), 2.5)};
    :hover {
        color: ${Colors.toHSLAStringColor(Colors.rgbToHsl(Colors.getRGB(ColorSections.PrimaryColor)), 1.5)};
    }
`;
 
const RemoveKey = styled.span`
    cursor: pointer;
    color: ${Colors.get(ColorSections.DangerColor)};
    :hover {
        color: ${Colors.toHSLAStringColor(Colors.rgbToHsl(Colors.getRGB(ColorSections.DangerColor)), 1.5)};
    }
`;
