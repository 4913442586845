import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clipboard from 'react-clipboard.js';
import styled from "@emotion/styled";
import { Msg } from "./Messenger";
import Localized from "../Localization/Localized";

interface IDisplayProps {
    label?: string | JSX.Element | null | undefined;
    value: string;
    inline?: boolean;
}

interface IDisplayIdProps extends IDisplayProps {
    goTo?: boolean;
}

export class DisplayId extends React.Component<IDisplayIdProps, {}> {
    onSuccessHandle = () => {
        Msg.success(Localized.format(Localized.General.WasCopiedInClipboard, this.props.value));
    }

    render() {
        const id = `#row_${this.props.value}`;
        return (<DisplaySpan inline={this.props.inline}>
            {this.props.label ? <label>{this.props.label}</label> : null}
            <span style={{ cursor: "pointer"}}>
                <Clipboard component="a" data-clipboard-text={this.props.value} button-title={Localized.General.CopyInClipboard} onSuccess={this.onSuccessHandle}>
                    {!this.props.goTo && <span>{this.props.value}</span>}
                    {this.props.goTo && <span><a href={id}>{this.props.value}</a></span>}
                    &nbsp;                   
                    <FontAwesomeIcon icon="paste" />
                </Clipboard>
            </span> 
        </DisplaySpan>);
    }
}

export class Display extends React.Component<IDisplayProps, {}> {
    render() {
        return (<DisplaySpan inline={this.props.inline}>
            {this.props.label ? <label>{this.props.label}</label> : null}
            <span>{this.props.value}</span>
        </DisplaySpan>);
    }
}

type DisplaySpanProps = {
    inline?: boolean;
}

const DisplaySpan = styled.span<DisplaySpanProps>`
    display: ${props => props.inline ? "inline-flex": "flex"};
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > span {
        margin-right: 0.25rem;
    }

    > label {
        margin: 0;
        font-weight: bold;
        margin-right: 0.25rem;
    }

    > label:after {
        content:":";
    }

    a {
        color: inherit;
    }
`;
