import React, { useState } from "react";
import { AuthService } from "../../services/AuthService";
import { SuccessButton, WarningButton } from "../../utils/CustomButton";
import { CustomInput } from "../../utils/CustomInput";
import { CustomInputGroup } from "../../utils/CustomInputGroup";
import { Separator } from "../../utils/Separator";
import ResponsiveFormPage from "../ResponsiveFormPage";
import NeedProfilePage, { INeedProfilePageState } from "../profile/NeedProfilePage";
import Loading, { LoadingDiv } from "../../utils/Loading";
import { Spaces } from "./Space/Spaces";
import Icon from "@mdi/react";
import { mdiAccount, mdiContentSave, mdiUndoVariant } from "@mdi/js";
import HeaderService from "../../services/HeaderService";
import Localized from "../../Localization/Localized";
export default class ProfilePage extends NeedProfilePage<{}, INeedProfilePageState> {
    protected didMount(): void {
        HeaderService.setTitle(Localized.Profile.ProfilePageTitle, ProfilePage.Title());
    }

    public static Title = () => <><Icon path={mdiAccount} size="1em" /> {Localized.Profile.ProfilePageTitle}</>;

    protected initState(props: Readonly<{}>): INeedProfilePageState {
        return {
            currentProfile: AuthService.currentProfile ?? null
        }
    }
    protected profileChanged(): void {        
    }

    render = () => {
        return <>
            {!this.state.currentProfile && <LoadingDiv style={{
                fontSize: "3em"
            }}><Loading /></LoadingDiv>}
            {this.state.currentProfile && <ProfileContent />}
        </>
    }
}

const ProfileContent: React.FC = () => {
    const [displayName, setDisplayName] = useState(AuthService.currentProfile?.display_name ?? "");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const cancel = ()=> {
        setDisplayName(AuthService.currentProfile?.display_name ?? "");
    }

    const save = () => {
        setIsSubmitting(true);
        AuthService.updateDisplayName(displayName).then(result => {
            if(result) {
                setIsSubmitting(false);
            }
        });
    }
    
    return <ResponsiveFormPage>
        <h3>{Localized.Profile.DisplayNameLabel}</h3>
        <CustomInputGroup>
            <CustomInput value={displayName} onChange={(evt) => setDisplayName(evt.currentTarget.value)} maxLength={100} />
            <SuccessButton title={Localized.Profile.DisplayNameSaveButtonTooltip} onClick={save} disabled={isSubmitting}  maIcon={mdiContentSave} />
            <WarningButton title={Localized.Profile.DisplayNameCancelButtonTooltip}onClick={cancel} disabled={isSubmitting} maIcon={mdiUndoVariant} />
        </CustomInputGroup>
        <Separator />
        <Spaces />
        <Separator />
    </ResponsiveFormPage>
}
