import { mdiAllInclusive } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect } from "react";
import Localized from "../../Localization/Localized";
import HeaderService from "../../services/HeaderService";
import { DocumentList } from "../Document/DocumentList/DocumentList";

const Home = () => {
    useEffect(() => {
      HeaderService.setTitle(Localized.General.HomeTitle, <><Icon path={mdiAllInclusive} size="1em" /> {Localized.General.HomeTitle}</>);
    });
  
    return (
      <div style={{
        padding: "0.25rem"
      }}>
        <Icon path={mdiAllInclusive} size="33vh" style={{
          margin: "auto",
          display: "block",
          color: "#000000"
        }} />
        <DocumentList isFullScreen />
      </div>
    );
  }

export default Home;
