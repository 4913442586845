import { v4 } from "uuid";
import { IDialog } from "./interfaces/IDialog";
import { IFreeDialog, ITooltipDialog } from "./interfaces/IFreeDialog";
import { DialogType } from "./enums/DialogType";
import { DialogResult } from "./enums/DialogResult";
import { DialogSize } from "./enums/DialogSize";
import { IDialogOKOptions } from "./interfaces/IDialogOKOptions";
import { IDialogWithValidateOptions } from "./interfaces/IDialogWithValidateOptions";
import { IFreeDialogOptions } from "./interfaces/IFreeDialogOptions";
import { ITooltipDialogOptions } from "./interfaces/ITooltipDialogOptions";
import { TooltipItem, TooltipPopup } from "../TooltipPopup";
import Localized from "../../Localization/Localized";

class DialogManager {
    private stateHandler: (dialog: IDialog) => void = (dialog: IDialog) => {};

    confirm(msg: string | JSX.Element, options?: IDialogOKOptions): Promise<DialogResult>{
        const size = options && options.size ? options.size : DialogSize.M;
        return new Promise(resolve => {
            const d: IFreeDialog = {
                id: v4(),
                header: Localized.General.ConfirmPopupHeeader,
                body: msg,
                dismiss: () => resolve(DialogResult.Dismissed),
                cancel: () => resolve(DialogResult.Canceled),
                okLable: options ? options.okLable : undefined,
                ok: () => resolve(DialogResult.Ok),
                type: DialogType.Confirm,
                size: size,
                scrollable: options ? options.scrollable : undefined,
                notModal: false,
                baseZIndex: options ? options.baseZIndex : undefined
            };
            this.stateHandler(d);
        });
    }

    alert(msg: string | JSX.Element, options?: IDialogOKOptions): Promise<DialogResult>{
        const size = options && options.size ? options.size : DialogSize.M;
        return new Promise(resolve => {
            const d:IFreeDialog = {
                id: v4(),
                header: null,
                body: msg,
                dismiss: () => resolve(DialogResult.Dismissed),
                okLable: options ? options.okLable : undefined,
                ok: () => resolve(DialogResult.Ok),
                cancel: () => {},
                type: DialogType.Alert,
                size: size,
                scrollable: options ? options.scrollable : undefined,
                notModal: false,
                baseZIndex: options ? options.baseZIndex : undefined
            }
            this.stateHandler(d);
        });        
    }

    prompt(header: string | JSX.Element, body: string | JSX.Element, options?: IDialogWithValidateOptions): Promise<DialogResult>{
        const size = options && options.size ? options.size : DialogSize.M;
        return new Promise(resolve => {
            const d:IFreeDialog = {
                id: v4(),
                header: header,
                body: body,
                dismiss: () => resolve(DialogResult.Dismissed),
                cancel: () => resolve(DialogResult.Canceled),
                okLable: options ? options.okLable : undefined,
                ok: () => resolve(DialogResult.Ok),
                beforeOk: options ? options.onOk : undefined,
                validate: options ? options.validate : undefined,
                type: DialogType.Prompt,
                size: size,
                scrollable: options ? options.scrollable : undefined,
                notModal: false,
                baseZIndex: options ? options.baseZIndex : undefined
            }
            this.stateHandler(d);
        });      
    }

    config(header: string | JSX.Element, body: string | JSX.Element, options?: IDialogOKOptions): Promise<DialogResult>{
        const size = options && options.size ? options.size : DialogSize.M;
        return new Promise(resolve => {
            const d: IFreeDialog = {
                id: v4(),
                header: header,
                body: body,
                dismiss: () => resolve(DialogResult.Dismissed),
                cancel: () => {},
                okLable: options ? options.okLable : undefined,
                ok: () => {},
                type: DialogType.Config,
                size: size,
                scrollable: options ? options.scrollable : undefined,
                notModal: false,
                baseZIndex: options ? options.baseZIndex : undefined
            };
            
            this.stateHandler(d);
        });      
    }    

    free(header: string | JSX.Element | null, body: string | JSX.Element, options?: IFreeDialogOptions): Promise<DialogResult>{
        const size = options && options.size ? options.size : DialogSize.M;
        return new Promise(resolve => {
            const d : IFreeDialog = {
                id: v4(),
                header: header,
                body: body,
                dismiss: () => resolve(DialogResult.Dismissed),
                cancel: () => {},
                okLable:  undefined,
                ok: () => {},
                type: DialogType.Free,
                size: size,
                scrollable: options ? options.scrollable : undefined, 
                isDraggable: options?.isDraggable,
                notModal: options?.notModal,
                windowStyle: options?.windowStyle,
                headerStyle: options?.headerStyle,
                isResizable: options?.isResizable,
                dispatchDismisse: options?.dispatchDismiss,
                hideHeaderButton: options?.hideHeaderButton
            }
            this.stateHandler(d);
        });      
    } 
    
    tooltip(body: string | JSX.Element, options: ITooltipDialogOptions) {
        const size = options && options.size ? options.size : DialogSize.M;
        return new Promise(resolve => {
            const d : ITooltipDialog = {
                id: v4(),
                header: null,
                body: body,
                dismiss: () => resolve(DialogResult.Dismissed),
                cancel: () => {},
                okLable:  undefined,
                ok: () => {},
                type: DialogType.Tooltip,
                size: size,
                scrollable: options ? options.scrollable : undefined, 
                dispatchResize: options?.dispatchRePosition,
                handle: options?.handle,
                dispatchDismisse: options?.dispatchDismiss,
                windowStyle: options?.windowStyle,
                notModal: options?.notModal,
                baseZIndex: options?.likeWindow ? 20000 : 25000
            }
            this.stateHandler(d);
        }); 
    }

    setStateHandler(stateHandler: (dialog: IDialog) => void){
        this.stateHandler = stateHandler;
    }

    tooltipList<T>(handle: Element, items: T[],
        onChange: (item: T | null) => void,
        selected: (item: T | null) =>  boolean,
        display: (item: T) => string | JSX.Element,
        emptyText?: string | null): void {
        
            let dismiss = () => {};

            const dispatchDismiss = (d: () => void) => {
                dismiss = d;
            }
        
            const change = (item: T | null) => {
                dismiss();
                onChange(item);
            }   
            
            this.tooltip(<TooltipPopup>
                {emptyText && <TooltipItem onClick={() => change(null)} active={selected(null)}>{emptyText}</TooltipItem>}
                {items.map((m, i) => <TooltipItem key={i} onClick={() => change(m)} active={selected(m)}>
                    {display(m)}
                </TooltipItem>)}
            </TooltipPopup>, {
                handle: handle,
                dispatchDismiss: dispatchDismiss
            });             
    }

    picture(body: string | JSX.Element) {
        const size = DialogSize.Max;
        return new Promise(resolve => {
            const d : ITooltipDialog = {
                id: v4(),
                header: null,
                body: body,
                dismiss: () => resolve(DialogResult.Dismissed),
                cancel: () => {},
                ok: () => {},
                type: DialogType.Picture,
                size: size,
            }
            this.stateHandler(d);
        }); 
    }
}

const Dialog = new DialogManager();

export { Dialog };