import styled from "@emotion/styled";
import React from "react";
import Localized from "../../Localization/Localized";
import { RealmApp } from "../../realm/RealmApp";
import HeaderService from "../../services/HeaderService";

interface IRegisterState {
    message: string;
}

export default class EmailConfirmation extends React.Component<{}, IRegisterState> {
  constructor(props: Readonly<{}>){
    super(props);
    this.state = {
      message: Localized.EmailConfirmationView.InProgress,
    };
  }

  componentDidMount = () => {
    HeaderService.setTitle(Localized.EmailConfirmationView.Title);
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const tokenId = params.get('tokenId');

    if(!token || !tokenId) {
      this.setState({
        message: Localized.General.NoToken
      });
    } else {
      RealmApp.emailPasswordAuth
        .confirmUser({
          token: token ?? "",
          tokenId: tokenId ?? ""
        })
        .then(() => {
          this.setState({
            message: Localized.EmailConfirmationView.Confirmed
          });
        })
        .catch(err => {
          this.setState({
            message: Localized.EmailConfirmationView.Unabled
          });      
        });
    }
  }

  render() {
    return (<ContainerDiv>
        <div>{this.state.message}</div>
    </ContainerDiv>);
  }
}
const ContainerDiv = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
