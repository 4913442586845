export enum Direction {
    Start,
    End,
    Bottom,
    Top
}

export interface IOffcanvas {
    header: string | Element | JSX.Element;
    body: string | Element | JSX.Element;
    direction: Direction;
    backdrop?: boolean;
}

type TOpenHandle = (offcanvas: IOffcanvas) => void;
type TCloseHandle = () => void;

export default class OffcanvasService {
    private static _openHandle: TOpenHandle | null;
    private static _closeHandle: TCloseHandle | null;

    static init(openHandle: TOpenHandle | null, closeHandle: TCloseHandle | null) {
        OffcanvasService._openHandle = openHandle;
        OffcanvasService._closeHandle = closeHandle;
    }
    
    static open(offcanvas: IOffcanvas){
        if(OffcanvasService._openHandle) OffcanvasService._openHandle(offcanvas);
    }

    static close(){
        if(OffcanvasService._closeHandle) OffcanvasService._closeHandle();
    }
}