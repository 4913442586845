import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCommands } from "@remirror/react";
import { useState } from "react";
import { ButtonGroup } from "reactstrap";
import { ActionButton } from "../ActionButton";
import Icon, { Stack } from '@mdi/react';
import styled from "@emotion/styled";
import { mdiMenuDown, mdiFormatAlignJustify } from '@mdi/js';
import { SecondaryButton } from "../../../../../utils/CustomButton";

export const AddTextAlign = () => {
    const [open, setOpen] = useState(false);
    const commands = useCommands();

    return <ButtonGroup size="sm">
        <SecondaryButton onClick={() => setOpen(!open)}>
            <Toggle size="1.5em" open={open}>
                <Icon path={mdiFormatAlignJustify} size="1.5em" />
                <Icon path={mdiMenuDown} />
            </Toggle>
        </SecondaryButton>
        {open && <>
            <ActionButton enabled onClick={() => commands.leftAlign()}><FontAwesomeIcon icon="align-left" /></ActionButton>
            <ActionButton enabled onClick={() => commands.centerAlign()}><FontAwesomeIcon icon="align-center" /></ActionButton>
            <ActionButton enabled onClick={() => commands.rightAlign()}><FontAwesomeIcon icon="align-right" /></ActionButton>
            <ActionButton enabled onClick={() => commands.justifyAlign()}><FontAwesomeIcon icon="align-justify" /></ActionButton>
        </>}
    </ButtonGroup>
}

type TProps = {
    open: boolean;
}

const Toggle = styled(Stack)<TProps>`
    > g:first-of-type {
        transform: scale(0.85) translate(-5px, 0px)!important;
        transform-origin: center center;
    }
    > path:first-of-type {
        transform: ${props => `scale(1.125) translate(8px, 0px) rotate(${props.open ? "-" : ""}90deg) !important`};
        transform-origin: center center;
    }
`;