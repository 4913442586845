import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionButton } from "../ActionButton";
import Icon from '@mdi/react';
import { mdiCodeBraces } from '@mdi/js';
import { ButtonGroup } from "reactstrap";
import { useActive, useCommands } from "@remirror/react";

export const AddQuoteAndCode = () => {
    const commands = useCommands();
    const active = useActive(true);
    
    return <ButtonGroup size="sm">
        <ActionButton active={active.blockquote()} enabled={commands.toggleBlockquote.enabled()} onClick={() => commands.toggleBlockquote()}><FontAwesomeIcon icon="quote-left" /></ActionButton>
        <ActionButton active={active.codeBlock()} enabled={commands.toggleCodeBlock.enabled({ language: "txt" })} onClick={() => commands.toggleCodeBlock({ language: "txt" })}><Icon path={mdiCodeBraces} size="1.25em" style={{marginTop: "-4px" }} /></ActionButton>
        <ActionButton active={active.code()} enabled={commands.toggleCode.enabled()} onClick={() => commands.toggleCode()}><FontAwesomeIcon icon="code" /></ActionButton>
    </ButtonGroup>
}