import React, { useState } from "react";
import { IDocument } from "../../../realm/schemas/Document/IDocument";
import { Editor } from "../Editor/Editor";
import DocumentService from "../../../services/DocumentService";
import { Dialog } from "../../../utils/dialog/DialogManager";
import { ContentHistories } from "./ContentHistories";
import { DialogResult } from "../../../utils/dialog/enums/DialogResult";
import { DialogSize } from "../../../utils/dialog/enums/DialogSize";
import ReactDiffViewer from 'react-diff-viewer';
import Colors, { ColorSections } from "../../../utils/Color/MainColors";
import Localized from "../../../Localization/Localized";

var beautify_html = require('js-beautify');

type TDocumentProps = {
    document: IDocument;
    isReadOnly: boolean;
}

type TDocumentState = {
    showInfo: boolean;
    contentChanged: string;
}
export default class DocumentView extends React.Component<TDocumentProps, TDocumentState> {
    private _contentHandle: (content: string) => void = () => {};
    private _reanOnlyHandle: (readOnly: boolean) => void = () => {};
    constructor(props: Readonly<TDocumentProps>) {
        super(props);

        this.state = {
            contentChanged: props.document.content,
            showInfo: false
        }
    }

    componentDidMount = () => {
        this._contentHandle(this.state.contentChanged);
        this._reanOnlyHandle(this.props.isReadOnly);
    }

    contentChanged = (content: string) => {
        this.setState({
            contentChanged: content
        });
    }

    cleanContent = (content: string) => {
        return content.replaceAll(' style=""', "") ?? "";
    }

    saveContent: (comment?: string) => Promise<boolean> = async (comment?: string) => {
        const result = await DocumentService.updateContent(this.props.document._id, this.cleanContent(this.state.contentChanged.toString()), comment);

        return result;
    }

    saveContentWithOptions = async () => {
        let comment = "";
        const onChange = (value: string) => {
            comment = value;
        }

        const d = await DocumentService.GetById(this.props.document._id);

        const resultPrompt = await Dialog.prompt(Localized.Document.SaveContentPopupTitle, <div style={{
            position: "relative",
            maxHeight: "inherit"
        }}>
            <div style={{
                position: "fixed",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 1,
                backgroundColor: Colors.get(ColorSections.PopupBG)
            }}>
                <ContentSavePrompt onCommentChange={onChange} />
            </div>
            <div style={{
                paddingTop: "5.5rem"
            }}>
                <ReactDiffViewer oldValue={beautify_html.html_beautify(this.cleanContent(this.state.contentChanged.toString()))} newValue={beautify_html.html_beautify(d?.content ?? "")} splitView={true} showDiffOnly={true}/>
            </div>
        </div>,
        {
            size: DialogSize.Max
        });

        if(resultPrompt !== DialogResult.Ok) return false;

        return await this.saveContent(comment);
    }

    openHistories: () => Promise<boolean> = async () => {
        return new Promise<boolean>(resolve => {
            Dialog.config(Localized.Document.ContentHistoryPopupTitle, <ContentHistories documentId={this.props.document._id} />, {
                size: DialogSize.XS
            }).then(result => {
                resolve(true);
            });
        });
    }

    toogleInfo = () => {
        this.setState(state => {
            return {
                showInfo: !state.showInfo
            }
        });
    }

    render = () => {
        return <>
            {this.props.document && <>
                <div>
                    <Editor setContentHandle={(handle) => {
                            this._contentHandle = handle;
                        }} 
                        setReadOnlyHandle={(handle) => {
                            this._reanOnlyHandle = handle;
                        }}
                        readOnly={this.props.isReadOnly}
                        contentChanged={this.contentChanged}
                        save={this.saveContentWithOptions}
                        histories={this.openHistories}/>
                </div>                
            </>}
        </>
    }
}

type TContentSavePrompt = {
    onCommentChange: (value: string) => void;
}

const ContentSavePrompt = (props: TContentSavePrompt) => {
    const [value, setValue] = useState("");

    const onChange = (value: string) => {
        props.onCommentChange(value);
        setValue(value);
    }

    return <div>
        <label>Comment</label>
        <textarea value={value} onChange={(evt) => onChange(evt.currentTarget.value)} style={{
            width: "100%",
        }} maxLength={500} />
    </div>
}