import { mdiCloseOutline, mdiContentSaveMove } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { InputGroup } from "reactstrap";
import DocumentService from "../../../services/DocumentService";
import { ObjectId } from "bson";
import { SuccessButton, WarningButton } from "../../../utils/CustomButton";
import { CustomInput } from "../../../utils/CustomInput";

type TDocumentTitleEditorProps = {
    docId: ObjectId;
    title: string | null;
    close: () => void;
    isPublic?: boolean
}

export const DocumentTitleEditor:React.FC<TDocumentTitleEditorProps> = (props) => {
    const [title, setTitle] = useState(props.title ?? "");

    const change = (value: string) => {
        setTitle(value);
    }

    const save = () => {
        DocumentService.UpdateTitle(props.docId, title, props.isPublic).then(result => {
            if(result) props.close();
        });
    }

    const cancel = () => {
        setTitle(props.title ?? "");
        props.close();
    }
    return <InputGroup size="sm">
        <CustomInput value={title} onChange={(evt) => change(evt.currentTarget.value)} />
        <SuccessButton onClick={save}><Icon path={mdiContentSaveMove} size="1.5em" /></SuccessButton>
        <WarningButton onClick={cancel}><Icon path={mdiCloseOutline} size="1.5em" /></WarningButton>
    </InputGroup>
}