import styled from "@emotion/styled";
import React, { CSSProperties } from "react";
import Colors, { ColorSections } from "../../../utils/Color/MainColors";
import { ObjectId } from "bson";
import SpaceService from "../../../services/SpaceService";
import { DocumentFilterDefault, IDocumentFilter } from "../FilterAndOrder/IDocumentFilter";
import { NoButton, SecondaryButton } from "../../../utils/CustomButton";
import { mdiChevronDown, mdiChevronLeft, mdiCircleMedium } from "@mdi/js";
import Loading, { LoadingDiv } from "../../../utils/Loading";
import IDocumentInfo from "../../../realm/schemas/Document/IDocumentChange";
import { DocumentList } from "../DocumentList/DocumentList";
import { FilterType } from "../../../utils/List/Filter";
import PathGroupsService from "../../../services/PathGroupsService";
import { PathActions } from "./PathActions";
import GlobalStateService from "../../../services/GlobalStateService";
import IDocumentOrder from "../FilterAndOrder/IDocumentOrder";
import Localized from "../../../Localization/Localized";

type TPathListItemProps = {
    spaceId: ObjectId;
    path: string;
    filter?: IDocumentFilter;
    level: number;
    parentPath?: string;
    onChanged?: () => void;
    context?: string;
}

type TPathListItemState = {
    filter: IDocumentFilter;
    paths: { _id: string }[];
    isLoading?: boolean;
    isOpen?: boolean;
    hasChild: boolean;
    files: IDocumentInfo[];
    documentCount: number;
    pathCount: number;
    width: string | number;
}

export default class PathListItem extends React.Component<TPathListItemProps, TPathListItemState> {
    private _loadHandle?: () => Promise<void>;
    private _widthSetted: boolean = false;
    private readonly _documentDefaultOrder: IDocumentOrder = {
        fields: [
            { 
              label: Localized.General.Title,
              field: "title",
              direction: "A"
            },
            { 
                label: Localized.General.ModificationDate,
                field: "modifiedDateTimeUTC",
                direction: "N/A"
            },
            { 
                label: Localized.General.CreationDate,
                field: "createdDateTimeUTC",
                direction: "N/A"
            },
            { 
                label: Localized.General.PublicationDate,
                field: "publishedDateTimeUTC",
                direction: "N/A"
            }
        ]
    };

    constructor(props: TPathListItemProps | Readonly<TPathListItemProps>) {
        super(props);

        let open = GlobalStateService.Get(`${this.stateKey}_open`);
        open = open  == null ? false : open;
        this.state = {
            files: [],
            hasChild: true,
            paths: [],
            filter: props.filter ?? DocumentFilterDefault(),
            documentCount: 0,
            pathCount: 0,
            width: "100%",
            isOpen: open
        }
    }

    get stateKey() {
        return `PLI_${this.props.context}_${this.fullPath()}`;
    }

    componentDidMount = () => {
        if(this.state.isOpen) this.load();
    }

    toggleOpen = () => {
        this.setState(state => {
            return {
                isOpen: !state.isOpen,
                isLoading: !state.isOpen,
            };
        }, () => {
            GlobalStateService.Set(`${this.stateKey}_open`, this.state.isOpen)
            if(this.state.isOpen){
                this.load();
            }
        });
    };

    load = async () => {
        const results = await SpaceService.getPaths(this.props.spaceId, this.props.level, this.props.path, this.state.filter);

        this.setState(state => {
            return {
                paths: results,
                hasChild: state.documentCount > 0 || results.length > 0,
                isLoading: false,
                pathCount: results.length
            }
        });
    }

    documentCount = (count: number) => {
        this.setState(state => {
            return {
                hasChild: state.pathCount > 0 || count > 0,
                documentCount: count
            }
        });
    }

    docCreated = async () => {
        if(this._loadHandle) await this._loadHandle();
    }

    onHeaderRefSetted = (element: HTMLElement | null) => {
        if(element == null || this._widthSetted) return;

        this._widthSetted = true;
        const parent = $(element).closest(".u-container");
        
        const w = parent.width();

        if(w) {
            this.setState({
                width: w
            });
        }
    }
    
    fullPath = () => {
        let path = this.props.parentPath;

        if(path && path.length > 0) path = `${path} | ${this.props.path}`;
        else path = this.props.path;

        return path;
    }

    render = () => {
        const childrenParentPath = this.props.parentPath && this.props.parentPath.length > 0 ? `${this.props.parentPath} | ${this.props.path}` : this.props.path;

        const filter: IDocumentFilter = JSON.parse(JSON.stringify(this.state.filter));
        filter.isPublicOnly = false;
        filter.path = {
            filter: childrenParentPath,
            type: FilterType.Equal
        };
        filter.spaceId = this.props.spaceId;

        let path = this.fullPath();
    
        const g = PathGroupsService.GetGroup(path, false);

        const style: CSSProperties | undefined = g ? {
            border: `1px solid ${g.bgColor ? Colors.toRGBAStringColor(g.bgColor, 0.5) : Colors.get(ColorSections.Text, 0.25)}`,
            backgroundColor: g.bgColor ? Colors.toRGBAStringColor(g.bgColor, 0.3) : Colors.get(ColorSections.BG, 1),
            color: g.textColor ? Colors.toRGBAStringColor(g.textColor) : undefined
        } : {
            border: `1px solid ${Colors.get(ColorSections.Text, 0.25)}`,
            backgroundColor: Colors.get(ColorSections.BG, 1)
        };

        return <div style={style}>
            <PathTitleContainer>
                <div onClick={this.toggleOpen}>
                    {this.state.hasChild && <SecondaryButton maIcon={this.state.isOpen ? mdiChevronDown : mdiChevronLeft} link />}
                    {!this.state.hasChild && <NoButton maIcon={mdiCircleMedium} />}
                    {this.props.path}
                </div>
                <PathActions spaceId={this.props.spaceId} path={this.props.path} 
                parentPath={this.props.parentPath} pathChanged={async () => {
                    if(this.props.onChanged) this.props.onChanged();
                }} docCreated={this.docCreated} />
            </PathTitleContainer>           
            {this.state.isOpen && <>
                {this.state.isLoading && <LoadingDiv style={{
                    fontSize: "2em"
                }}><Loading /></LoadingDiv>} 
                {!this.state.isLoading &&<div style={{
                    position: "relative",
                    paddingLeft: "0.5rem"
                }} className="u-container">
                    {this.state.paths.map(p => <PathListItem key={p._id} spaceId={this.props.spaceId} path={p._id} filter={this.state.filter} level={this.props.level + 1} 
                    parentPath={childrenParentPath} />)}
                </div>}
                <div style={{
                    position: "relative",
                    fontSize: "0.75em",
                    paddingLeft: "0.5rem"
                }} className="u-container">
                    <DocumentList filter={filter} itemCount={this.documentCount} title={Localized.DocumentListView.Title} currentPath={path} 
                    loadHandler={(handle) => this._loadHandle =  handle} order={this._documentDefaultOrder} />
                </div>
            </>}
        </div>
    }
}


const PathTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > * {
        display: flex;
        align-items: center; 
    }
    cursor: pointer;
`;
