import React from "react";
import styled from "@emotion/styled";
import { ColorTabs } from "./ColorTabs";
import { RGBColor } from "react-color";
import { Dialog } from "../dialog/DialogManager";
import { DialogSize } from "../dialog/enums/DialogSize";
import { SecondaryButton } from "../CustomButton";

type TColorPickerProps = {
    label?: string | JSX.Element,
    color: RGBColor,
    onChange: (color: RGBColor) => void
    size?: string;
}

type TColorPickerState = {
    color: RGBColor,
    displayColorPicker: boolean,
}

export class ColorPickerBase extends React.Component<TColorPickerProps, TColorPickerState> {
    dispatchResize: () => void = () => {};
    constructor(props: Readonly<TColorPickerProps>){
        super(props);

        const c = this.props.color ? this.props.color : {
            r: 0,
            g: 0,
            b: 0,
            a: 1,
          };
        
        this.state = {
            displayColorPicker: false,
            color: c,
          }; 
    }

    toggleHandle = (evt: React.MouseEvent<Element, MouseEvent>) => {
        const dispatcher = (resize: () => void) => {
            this.dispatchResize = resize;
        }

        Dialog.tooltip(<ColorTabs color={this.state.color} colorChange={this.handleChange} tabChange={this.onTabChange} />, {
            size: DialogSize.Max,
            scrollable: true,
            handle: evt.currentTarget,
            dispatchRePosition: dispatcher
        });
    };     

    handleChange = (color: RGBColor) => {
        this.setState({ color: color });
        if(this.props.onChange) this.props.onChange(color);
    }

    onTabChange = () => {
        this.dispatchResize();
    }

    render() {
        return (<ColorHandle size={this.props.size ?? undefined} onClick={this.toggleHandle}>
        <ColorBox c={this.state.color} />{this.props.label && <span>{this.props.label}</span>}
    </ColorHandle>);
    }
}

export default class ColorPicker extends ColorPickerBase {
    render() {
        return (<Swatch>
            {super.render()}
        </Swatch>);
    }
}

const ColorHandle = styled(SecondaryButton)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > :first-of-type {
        margin-right: 0.25rem;
    }
    white-space: nowrap;
`;

type TColorBoxProps = {
    c: RGBColor
}

const ColorBox = styled.div<TColorBoxProps>`
    width: 2rem;
    height: 1rem;;
    border-radius: 2px;
    background-color: ${props => `rgba(${ props.c.r }, ${ props.c.g }, ${ props.c.b }, ${ props.c.a })`};
    display: inline-block;
`;

const Swatch = styled.div`
    position:relative;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    /* margin: 0.25rem; */
`;