import { OrderPopup } from "../../../../utils/List/OrderPopup";
import ISpaceOrder from "./SpaceOrder";
import SpaceOrderPopupContent from "./SpaceOrderPopupContent";

export class SpaceOrderPopup extends OrderPopup<ISpaceOrder> {
    getPopupContent(apply: (o: ISpaceOrder) => void): JSX.Element {
        return <SpaceOrderPopupContent order={this.props.order} 
        apply={apply} />;
    }
}
