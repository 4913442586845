import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { LoginContext } from './components/auth/LoginForm';
import * as Realm from "realm-web";
import { AuthService } from "./services/AuthService";
import { Header } from './components/Header/Header';
import styled from '@emotion/styled';
import Profile from './components/profile/ProfilePage';
import Colors, { ColorSections } from './utils/Color/MainColors';
import LocationService from './services/LocationService';
import ApplicationService from './services/ApplicationService';
import { MainMenuLeftCanvas } from "./components/Menus/MainMenuLeftCanvas";
import HeaderService from './services/HeaderService';
import Register from './components/auth/Register';
import PasswordReset from './components/auth/PasswordReset';
import EmailConfirmation from './components/auth/EmailConfirmation';
import SendPasswordReset from './components/auth/SendPasswordReset';
import ColorsProfile from './components/ColorsProfile';
import {style} from "typestyle";
import Constants from './Constants';
import Footer from './components/Document/Footer';
import Home from './components/Home/Home';
import { DocumentListPage } from './components/Document/DocumentList/DocumentList';
import SpaceList from './components/Document/Space/SpaceList';
import CustomListRightCanvas from './components/CustomList/CustomListRightCanvas';
import { CustomListsView } from './components/CustomList/CustomListsView';
import { DocumentViewById } from './components/Document/DocumentView/DocumentPage';
import Localized from "./Localization/Localized";

type TAppState = {
  colorsProfile: Colors;
}
export default class App extends React.Component<{}, TAppState>{
  constructor(props: Readonly<{}>) {
    super(props);
    MainMenuLeftCanvas.Set();
    CustomListRightCanvas.Set();
    this.state = {
      colorsProfile: AuthService.getCurrentColorsProfile()
    }
  }

  componentDidMount = () => {
    AuthService.subscribeChange("AppRoot", (f, v) => {
      this.setState({
        colorsProfile: AuthService.getCurrentColorsProfile()
      });
    });
  }

  componentWillUnmount = () => {
    AuthService.unsubscribeChange("AppRoot");
  }

  render = () => {
    const styleName = style({
      color: this.state.colorsProfile.get(ColorSections.Text),
      backgroundColor: this.state.colorsProfile.get(ColorSections.BG)
    });

    return (
      <LayoutContainer className={styleName} ref={(el) => ApplicationService.Container = el}>
        <Router>
          <Header />
          <Layout />
          <Footer />
        </Router>
      </LayoutContainer>
    );
  }
}


const Layout = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(AuthService.isLoggedIn);

  const search = useLocation().search; 
  let lang = "fr";
  if(search.length > 0) {
      const params = new URLSearchParams(search);
      lang = params.get("lang") ?? lang;
  }
  Localized.setLanguage(lang);
  
  useEffect(() => {
    if(AuthService.isLoggedIn) {
      AuthService.LoadCurrentProfile();
    }

    AuthService.subscribeChange("Application", (f, v) => {
      setIsLoggedIn(AuthService.isLoggedIn);
    });

    LocationService.subscribeChange("Application", (f, v) => {
      navigate(v);
    });

    return () => {
        AuthService.unsubscribeChange("Application");
        LocationService.unsubscribeChange("Application");
    }
  });

  const location = useLocation();

  return <LayoutContent>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/Login" element={<LoginContext from={location} />} />
      {!isLoggedIn && <>
        <Route path="/AuthRedirect" element={<AuthRedirect />} />
        <Route path="/Documents/Explorer" element={<LoginContext from={location} />} />
        <Route path="/Documents/List" element={<LoginContext from={location} />} />
        <Route path="/Profile" element={<LoginContext from={location} />} />
        <Route path="/PasswordReset" element={<PasswordReset />} />
        <Route path="/EmailConfirmation" element={<EmailConfirmation />} />
        <Route path="/SendPasswordReset" element={<SendPasswordReset />} />
        <Route path="/Register" element={<Register />} />
      </>}
      {isLoggedIn && <>
        <Route path="/Documents/CustomLists" element={<CustomListsView navigationOrigine isPage />} />
        <Route path="/Documents/Spaces/List" element={<SpaceList isFullScreen isPage />} />
        <Route path="/Documents/List" element={<DocumentListPage />} />
        <Route path="/Documents/:id" element={<DocumentViewById />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/ColorsProfile" element={<ColorsProfile/>} />
      </>}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </LayoutContent>
}

const LayoutContent = styled.div`
    height: ${`calc(100% - ${Constants.HeaderHeight} - ${Constants.FooterHeight})`};
    overflow-y: scroll;
`;

const LayoutContainer = styled.div`
  position: absolute;
  top:0;
  bottom: 0;
  width: 100%;
  * {
    font-family: sans-serif;
  }
`;

export const AuthRedirect: React.FC = () => {
  useEffect(() => {
    Realm.handleAuthRedirect();
  }, []);
  return <h1>{Localized.AuthService.SigningInPleaseWait}</h1>;
};

const NoMatch = () => {
  let location = useLocation();

  useEffect(() => {
    HeaderService.setTitle(Localized.General.NoMatch);
  });

  return (
    <div>
      <h3>
        {Localized.General.NoMatchFor} <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
