import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mdiInformationOutline } from "@mdi/js";
import { useState } from "react";
import { ButtonGroup } from "reactstrap";
import { InfoButton, NoButton, SecondaryButton, DangerButton } from "../../../utils/CustomButton";
import { CustomInputGroup } from "../../../utils/CustomInputGroup";
import { CustomInputGroupText } from "../../../utils/CustomInputGroupText";

type TDoccumentActionsBarProps = {
    fontSize: number;
    isPublished?: boolean;
    toggleInfo?: () => void;
    fontSizeIncrease?: () => number;
    fontSizeDecrease?: () => number;
    onClose?: () => void;
    beforeCloseGroupActions?: () => JSX.Element[];
    closeGroupActions?: () => JSX.Element[];
}

const DoccumentActionsBar = (props: TDoccumentActionsBarProps) => {
    const [fontSize, setFontSize] = useState(props.fontSize);

    const fontSizeIncrease = async () => {
        if(!props.fontSizeIncrease) return;
        const fs = await props.fontSizeIncrease();
        setFontSize(fs);
    }

    const fontSizeDecrease = async () => {
        if(!props.fontSizeDecrease) return;
        const fs = await props.fontSizeDecrease();
        setFontSize(fs);
    }

    return <>
        <ButtonGroup size="sm">
            {props.toggleInfo && <InfoButton onClick={props.toggleInfo} maIcon={mdiInformationOutline} />}
            {props.isPublished && <NoButton maIcon="mdiEarth" />}
        </ButtonGroup>
        <CustomInputGroup style={{ 
                display: "inline-flex", 
                width: "auto",
                flexWrap: "nowrap"
            }}>
            <CustomInputGroupText><FontAwesomeIcon icon="font" /></CustomInputGroupText>
            <SecondaryButton faIcon="plus" onClick={fontSizeIncrease} />
            <SecondaryButton faIcon="minus" onClick={fontSizeDecrease} />
            <CustomInputGroupText>{fontSize.toFixed(1)}em</CustomInputGroupText>
        </CustomInputGroup>
        {props.beforeCloseGroupActions && <ButtonGroup>
            {props.beforeCloseGroupActions().map(m => m)}
        </ButtonGroup>}
        <ButtonGroup>
            {props.closeGroupActions && props.closeGroupActions().map(m => m)}
            {props.onClose && <DangerButton faIcon="times" onClick={props.onClose} />}
        </ButtonGroup>
    </>
}

export default DoccumentActionsBar;