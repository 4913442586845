import styled from "@emotion/styled";
import React from "react";
import Localized from "../../Localization/Localized";
import { RealmApp } from "../../realm/RealmApp";
import HeaderService from "../../services/HeaderService";
import { PrimaryButton } from "../../utils/CustomButton";
import { CustomInput } from "../../utils/CustomInput";
import { Msg } from "../../utils/Messenger";
import { PasswordValidation } from "./Register";

interface IRegisterState {
    password: string;
    isSubmitted: boolean;
    isSubmitting: boolean;
}

type TErrors = {
  password: string | null
}

export default class PasswordReset extends React.Component<{}, IRegisterState> {
  constructor(props: Readonly<{}>){
    super(props);
    this.state = {
      password: "",
      isSubmitted: false,
      isSubmitting: false
    };
  }

  componentDidMount = () => {
    HeaderService.setTitle(Localized.PasswordResetView.Title);
  }

  submitHandle = () => {
    this.setState({
      isSubmitting: true
    }, () => {

      const errors = this.validate();

      if(errors) {
        console.log(errors);
        return;
      }
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      const tokenId = params.get('tokenId');

      if(!token || !tokenId) {
        Msg.error(Localized.General.NoToken);
      } else {
        RealmApp.emailPasswordAuth.resetPassword(token ?? "", tokenId ?? "", this.state.password).then(() => {
          console.log(Localized.PasswordResetView.Successful);
          this.setState({
            isSubmitted: true,
            isSubmitting: false
          });
        })
        .catch(err => {    
          console.log(err);  
          Msg.error(Localized.GeneralError.Unexpected);
  
          this.setState({
            isSubmitting: false
          });          
        });
      }
    });
  }

  validate(): TErrors | null {
    let errors: TErrors = {
      password: null      
    };

    if (!this.state.password) {
      errors.password = 'Required';
    }
    else {
      errors.password = PasswordValidation(this.state.password);
    }

    if(errors.password) return errors

    return null;
  }

  passwordChangeHandle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: evt.target.value
    });
  }

  render() {
    return (<ContainerDiv>
        {!this.state.isSubmitted && <RegisterForm>
            <CustomInput type="password" name="password" placeholder={Localized.PasswordResetView.Placeholder} onChange={this.passwordChangeHandle} />
            <RegisterButton isSubmit disabled={this.state.isSubmitting} onClick={this.submitHandle}>Reset Password</RegisterButton>
        </RegisterForm>}
        {this.state.isSubmitted && <div>
            {Localized.PasswordResetView.Successful}
        </div>}
    </ContainerDiv>);
  }
}
const ContainerDiv = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const RegisterButton = styled(PrimaryButton)`
  margin-top: 10px;
  :first-of-type {
    margin-top: 0px;
  }
`;

const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  > *{
    margin-bottom: 0.25rem;
  }
`;
