import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { AuthService } from "../../services/AuthService";
import { Link, Location } from 'react-router-dom';
import LocationService from "../../services/LocationService";
import ResponsiveFormPage from "../ResponsiveFormPage";
import HeaderService from "../../services/HeaderService";
import { PrimaryButton } from "../../utils/CustomButton";
import { CustomInput } from "../../utils/CustomInput";
import Localized from "../../Localization/Localized";
import { isMobile } from "react-device-detect";

type TLoginProps = {
    from: Location;
}

type TLoginFormState = {
    email:string;
    password: string;
    isSubmitting: boolean;
    isLoggedIn: boolean
}

export const LoginContext: React.FC<TLoginProps> = (props:TLoginProps) => {
    useEffect(() => {
        if(AuthService.isLoggedIn) {
            if(props.from) LocationService.goTo(props.from);
            else LocationService.goTo("/Home");
        }
    });

    return <LoginForm />
}

export default class LoginForm extends React.Component<{},TLoginFormState> {
    constructor(props: Readonly<{}>) {
        super(props);

        this.state = {
            email: "",
            password: "",
            isSubmitting: false,
            isLoggedIn: AuthService.isLoggedIn
        }
    }

    componentDidMount = () => {
        HeaderService.setTitle(Localized.LoginView.Title);
    }

    emailChangeHandle = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            email: evt.currentTarget.value
        });
    }

    passwordChangeHandle = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: evt.currentTarget.value
        });
    }

    onLoginHandle = async () => {
        this.setState({
            isSubmitting: true
        }, this.login);
    }

    onLoginGoogleHandle = async () => {
        this.setState({
            isSubmitting: true
        }, this.loginGoogle); 
    }

    loginGoogle = () => {
        AuthService.googleSignIn().then(() => {
            this.setState({
                isSubmitting: false
            });
        });
    }

    login = async () => {
        AuthService.emailSignIn(this.state.email, this.state.password).then(() => {
            this.setState({
                isSubmitting: false
            });
        });
    }

    render = () => {
        return (
            <ResponsiveFormPage>
                <EmailLoginFormDiv>
                    <CustomInput type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.emailChangeHandle} />
                    <CustomInput type="password" name="password" placeholder="Password" onChange={this.passwordChangeHandle} />
                    <EmailLoginButton isSubmit disabled={this.state.isSubmitting} onClick={this.onLoginHandle}>{Localized.LoginView.Title}</EmailLoginButton>
                    <Link to="/Register" >{Localized.LoginView.Register}</Link>
                    <Link to="/SendPasswordReset" >{Localized.LoginView.Reset}</Link>
                </EmailLoginFormDiv>
            </ResponsiveFormPage>
            );
    }
}

const EmailLoginFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > *{
    margin-bottom: 0.25rem;
  }
  margin-top: 2em;
  margin-left: ${isMobile ? "1%" : "25%"};
  margin-right: ${isMobile ? "1%" : "25%"};
`;

const EmailLoginButton = styled(PrimaryButton)`
  margin-top: 10px;
  :first-of-type {
    margin-top: 0px;
  }
`;