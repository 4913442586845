
interface ISubscribtion{
    key: string,
    callBack: (field?: string, value?: any) => void;
}

export default class Observer {
    protected subscribtions: Map<string, ISubscribtion[]> = new Map<string, ISubscribtion[]>();
    public subscribeChange(eventKey: string, observerKey: string, callBack: (field?: string, value?: any) => void): void {
        if(!this.subscribtions.has(eventKey)) this.subscribtions.set(eventKey, []);

        const sub = this.subscribtions.get(eventKey) ?? [];

        for (const s of sub) {
            if (s.key === observerKey) console.error(`The ${observerKey} is already registred for ${eventKey}`);
        }

        sub.push({
            key: observerKey,
            callBack: callBack
        });
    }
    
    public unsubscribeChange(eventKey: string, key: string): void {
        if(!this.subscribtions.has(eventKey)) return;
        
        this.subscribtions.set(eventKey, this.subscribtions.get(eventKey)?.filter(i => {
            return i.key !== key;
        }) ?? []);
    }

    public notifyChange(eventKey: string, field?: string, value?: any): void {
        if(!this.subscribtions.has(eventKey)) {
            return;
        }

        for (const s of this.subscribtions.get(eventKey) ?? []) {
            if (s.callBack)
            {
                s.callBack(field, value);
            }
        }
    }
}