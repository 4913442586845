import styled from "@emotion/styled";
import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import Constants from "../../../Constants";
import LeftOffcanvasService from "../../../services/LeftOffcanvasService";
import { OffcanvasChange } from "../../../services/OffcanvasChange";
import OffcanvasService, { Direction } from "../../../services/OffcanvasService";
import { HeaderActionStyled } from "../HeaderActionStyled";

export const LeftOffcanvas = () => {
    const open = () => {
        OffcanvasService.open({
            header: <LeftHeader />,
            body: <LeftBody />,
            direction: Direction.Start
        });
    }

    return <>
        <HeaderActionStyled onClick={open}><MenuIconStyled path={mdiMenu} size="1.5em" /></HeaderActionStyled>
    </>
}

const MenuIconStyled = styled(Icon)`
    opacity: ${Constants.InfoDefaultOpacity};

    :hover {
    opacity: 1
    }
`;

const LeftHeader = () => {
    const [header, setHeader] = useState(LeftOffcanvasService.Header);
    useEffect(() => {
        LeftOffcanvasService.subscribeChange("LeftOffcanvasHeader", (field) => {
            if(field === OffcanvasChange.Header) {
                setHeader(LeftOffcanvasService.Header);
            }
        });

        return () => {
            LeftOffcanvasService.unsubscribeChange("LeftOffcanvasHeader");
        }
    });

    return <>
        {header}
    </>
}

const LeftBody = () => {
    const [body, setBody] = useState(LeftOffcanvasService.Body);
    useEffect(() => {
        LeftOffcanvasService.subscribeChange("LeftOffcanvasBody", (field) => {
            if(field === OffcanvasChange.Body) {
                setBody(LeftOffcanvasService.Body);
            }
        });

        return () => {
            LeftOffcanvasService.unsubscribeChange("LeftOffcanvasBody");
        }
    });

    return <>
        {body}
    </>
}