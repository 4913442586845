import styled from "@emotion/styled";
import Colors, { ColorSections } from "../../utils/Color/MainColors";

export const HeaderActionStyled = styled.span`
    margin-left: 0.25rem;
    padding-left: 0.45rem;
    padding-right: 0.45rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    cursor: pointer;
    border: 1px transparent solid; 
    :hover {
        border: ${`1px ${Colors.get(ColorSections.HeaderText, 0.65)} solid`}; 
        border-radius: 0.25rem;
    }
`;