export enum DialogType {
    Confirm,
    Alert,
    Prompt,
    Config,
    iframe,
    Free,
    Tooltip,
    Picture
}
