import styled from "@emotion/styled";
import { mdiEarth, mdiShield, mdiSlashForward } from "@mdi/js";
import Icon, { Stack } from "@mdi/react";
import { ObjectId } from "bson";
import React from "react";

type TNodeTitleProps = {
    docId?: ObjectId;
    title: string;
    onOpen?: (id?: ObjectId) => void;
    isPublic?: boolean;
    isPublished?: boolean;
}

export class DisplayTitle extends React.Component<TNodeTitleProps, {}> {
    render = () => {
        const onOpen = this.props.onOpen ? () => { if(this.props.onOpen) this.props.onOpen(this.props.docId) ;} : undefined;

        return <span className="file-title" style={{ display: "flex", alignItems: "center"}} onDoubleClick={onOpen}>
            {this.props.isPublic && this.props.isPublished && <Icon path={mdiEarth}  size="1em"/>}
            {this.props.isPublic && !this.props.isPublished && <PublicNotPublished size="1em">
                <Icon path={mdiEarth} />
                <Icon path={mdiSlashForward}/>
            </PublicNotPublished>}
            {!this.props.isPublic && <Icon path={mdiShield}  size="1em"/>}
            <title title={this.props.title}>
                {this.props.title}
            </title>
        </span>;
    };
}

const PublicNotPublished = styled(Stack)`
    path:first-of-type {
        transform: scale(0.97) !important;
        transform-origin: center center;
    }
    path:last-of-type {
        transform: scale(1.5) !important;
        transform-origin: center center;
    }
`;
