import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FreeBodyElement = styled.div`
    overflow:auto;
    max-height: calc(95vh - 80px);
    width: 100%;
    > * {
        width: 100%;
    }
`;

export const FreeHeaderElement = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; 
    padding: 0.25rem; 
    font-size: 1.25rem;
`;

export const FreeFooterElement = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; 
    padding: 0.25rem; 
    font-size: 1.25rem;
`;

type TFreeMainElementProps = {
    isDraggable?: boolean;
}

export const FreeMainElement = styled.div<TFreeMainElementProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 95vw;
    pointer-events: auto; 
    cursor: ${props =>  props.isDraggable ? "move" : "auto"};
`;

type TFreeShadowProps = {
    notModal: boolean
}

export const FreeShadow = styled.div<TFreeShadowProps>`
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    height: 100vh;
    background-color: ${props => props.notModal ? "transparent" : "rgba(0,0,0,0.25)"};
    pointer-events: ${props => props.notModal ? "none" : "auto"};
`;

export const RelationStartIcon = (<span className="fa-layers fa-fw">         
<FontAwesomeIcon icon="chevron-left" transform="shrink-4 left-5" />       
<FontAwesomeIcon icon="link" transform="shrink-4 right-4"/>
</span>);

export const RelationEndIcon = (<span className="fa-layers fa-fw">         
<FontAwesomeIcon icon="chevron-right" transform="shrink-4 right-5" />       
<FontAwesomeIcon icon="link" transform="shrink-4 left-4"/>
</span>);

export const RowNumber = styled.div`
    position: absolute;
    top: -0.25rem;
    font-size: 0.75em;
    color: rgba(0,0,0,0.65);
`;