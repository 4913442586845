import styled from "@emotion/styled";
import Constants from "../../Constants";
import Localized from "../../Localization/Localized";
import Colors, { ColorSections } from "../../utils/Color/MainColors";

const Footer = () => {
    return <FooterDiv>
      <span style={{
          fontSize: "0.75em"
      }}>{Localized.format(Localized.General.OperationalFundingAt, "0")}</span>
    </FooterDiv>
  }
  
  const FooterDiv = styled.div`
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    position: absolute;
    height: ${Constants.FooterHeight};
    bottom: 0;
    left:0;
    right:0;
    background-color: ${Colors.get(ColorSections.FooterBG)};
    color: ${Colors.get(ColorSections.FooterText)};
    border-top: 2px solid ${Colors.get(ColorSections.FooterTopBorder)};
    border-bottom: 2px solid ${Colors.get(ColorSections.FooterBG)};
    overflow: hidden;
    box-sizing: border-box;
  `;

  export default Footer;