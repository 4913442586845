import React, { ReactNode } from "react";
import { Button } from "reactstrap";
import Colors, { ColorSections } from "./Color/MainColors";
import {style} from "typestyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Icon from "@mdi/react";
import { NestedCSSProperties } from "typestyle/lib/types";
import { isMobile } from "react-device-detect";
interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement>  {
    size?: string;
    isSubmit?: boolean;
    active?: boolean;
    faIcon?: IconProp; // FontAwesome Icon
    maIcon?: string; // Icon - Material Design Icons
    outlined?: boolean;
    link?: boolean;
    iconSpin?: boolean;
}

interface IButtonColor {
    backgroundColor: string;
    borderColor: string;
    color: string;
}

interface IButtonFocusColor extends IButtonColor {
    BoxShadowColor: string;
}

interface ICustomProps extends IProps {
    defaultColor: IButtonColor;
    hoverColor: IButtonColor;
    activeColor: IButtonColor;
    focusColor: IButtonFocusColor;
}

interface TNoButtonProps extends React.HTMLAttributes<HTMLSpanElement> {
    faIcon?: IconProp; // FontAwesome Icon
    maIcon?: string; // Icon - Material Design Icons
    children?: ReactNode | undefined;
    iconSpin?: boolean;
}
// padding: 0.25em 0.25em
// font-size: .875rem
// font-weight: 400
// line-height: 1.5
// border: 1px
export const NoButton = (props: TNoButtonProps) => {
    let p = { ...props };
    delete p.faIcon;
    delete p.maIcon;
    delete p.children;
    delete p.iconSpin;

    return <span style={{
        padding: "0.25em",
        fontSize: "0.875rem",
        fontWeight: "400",
        lineHeight: "1.5",
        border: "1px solid transparent"
    }} {...p}>
        {props.faIcon && <FontAwesomeIcon icon={props.faIcon} spin={props.iconSpin} />}
        {props.maIcon && <Icon path={props.maIcon} size="1.5em" spin={props.iconSpin} />}
        {props.children}
    </span>
}

export const CustomButton = (props: ICustomProps) => {
    const size = props.size ?? (isMobile ? "lg" : "sm");

    const cssProp: NestedCSSProperties = {
        backgroundColor: props.link || props.outlined ? "transparent" : props.defaultColor.backgroundColor, 
        borderColor: props.link ? "transparent" : props.defaultColor.borderColor,
        color: props.defaultColor.color,
        padding: `0.25em ${props.maIcon ? "0.25em" : "0.5em"}`,
        $nest: {
            '&:hover': {
                backgroundColor: props.link || props.outlined ? "transparent" : props.hoverColor.backgroundColor, 
                borderColor: props.hoverColor.borderColor,
                color: props.hoverColor.color                
            },
            '&:active': {
                backgroundColor: props.link || props.outlined ? "transparent" : props.activeColor.backgroundColor, 
                borderColor: props.activeColor.borderColor,
                color: props.activeColor.color  
            },
            '&:focus': {
                backgroundColor: props.link || props.outlined ? "transparent" : props.focusColor.backgroundColor, 
                borderColor: props.focusColor.borderColor,
                color: props.focusColor.color,
                boxShadow: `0 0 0 0.25rem ${Colors.toRGBAStringColor(Colors.hexToRgb(props.focusColor.BoxShadowColor), 0.5)}`
            }
        }
    };

    const buttonClass = style(cssProp);

    return <Button size={size} style={props.style} className={buttonClass}
    onClick={props.onClick} disabled={props.disabled} type={props.isSubmit ? "submit" : undefined}
    title={props.title}
    active={props.active}
    onMouseDown={props.onMouseDown}>
        {props.faIcon && <><FontAwesomeIcon icon={props.faIcon} spin={props.iconSpin} /> </>}
        {props.maIcon && <><Icon path={props.maIcon} size="1.5em" spin={props.iconSpin} /> </>}
        {props.children}
    </Button>
}

export const PrimaryButton = (props: IProps) => {

    return <CustomButton 
        defaultColor={{
            backgroundColor: Colors.get(ColorSections.BtnPrimaryBG),
            borderColor: Colors.get(ColorSections.BtnPrimaryBorder),
            color: Colors.get(ColorSections.BtnPrimaryColor) 
        }}
        hoverColor={{
            backgroundColor: Colors.get(ColorSections.BtnPrimaryHoverBG),
            borderColor: Colors.get(ColorSections.BtnPrimaryHoverBorder),
            color: Colors.get(ColorSections.BtnPrimaryHoverColor) 
        }}
        activeColor={{
            backgroundColor: Colors.get(ColorSections.BtnPrimaryActiveBG),
            borderColor: Colors.get(ColorSections.BtnPrimaryActiveBorder),
            color: Colors.get(ColorSections.BtnPrimaryActiveColor) 
        }}
        focusColor={{
            backgroundColor: Colors.get(ColorSections.BtnPrimaryFocusBG),
            borderColor: Colors.get(ColorSections.BtnPrimaryFocusBorder),
            color: Colors.get(ColorSections.BtnPrimaryFocusColor),
            BoxShadowColor: Colors.get(ColorSections.BtnPrimaryFocusBoxShadow)
        }}
        {...props} />
}

export const SecondaryButton = (props: IProps) => {
    return <CustomButton
            defaultColor={{
                backgroundColor: Colors.get(ColorSections.BtnSecondaryBG),
                borderColor: Colors.get(ColorSections.BtnSecondaryBorder),
                color: Colors.get(ColorSections.BtnSecondaryColor) 
            }}
            hoverColor={{
                backgroundColor: Colors.get(ColorSections.BtnSecondaryHoverBG),
                borderColor: Colors.get(ColorSections.BtnSecondaryHoverBorder),
                color: Colors.get(ColorSections.BtnSecondaryHoverColor) 
            }}
            activeColor={{
                backgroundColor: Colors.get(ColorSections.BtnSecondaryActiveBG),
                borderColor: Colors.get(ColorSections.BtnSecondaryActiveBorder),
                color: Colors.get(ColorSections.BtnSecondaryActiveColor) 
            }}
            focusColor={{
                backgroundColor: Colors.get(ColorSections.BtnSecondaryFocusBG),
                borderColor: Colors.get(ColorSections.BtnSecondaryFocusBorder),
                color: Colors.get(ColorSections.BtnSecondaryFocusColor),
                BoxShadowColor: Colors.get(ColorSections.BtnSecondaryFocusBoxShadow)
            }}
            {...props} />
}

export const SuccessButton = (props: IProps) => {
    return <CustomButton 
            defaultColor={{
                backgroundColor: Colors.get(ColorSections.BtnSuccessBG),
                borderColor: Colors.get(ColorSections.BtnSuccessBorder),
                color: Colors.get(ColorSections.BtnSuccessColor) 
            }}
            hoverColor={{
                backgroundColor: Colors.get(ColorSections.BtnSuccessHoverBG),
                borderColor: Colors.get(ColorSections.BtnSuccessHoverBorder),
                color: Colors.get(ColorSections.BtnSuccessHoverColor) 
            }}
            activeColor={{
                backgroundColor: Colors.get(ColorSections.BtnSuccessActiveBG),
                borderColor: Colors.get(ColorSections.BtnSuccessActiveBorder),
                color: Colors.get(ColorSections.BtnSuccessActiveColor) 
            }}
            focusColor={{
                backgroundColor: Colors.get(ColorSections.BtnSuccessFocusBG),
                borderColor: Colors.get(ColorSections.BtnSuccessFocusBorder),
                color: Colors.get(ColorSections.BtnSuccessFocusColor),
                BoxShadowColor: Colors.get(ColorSections.BtnSuccessFocusBoxShadow)
            }}
            {...props} />
}

export const DangerButton = (props: IProps) => {
    return <CustomButton
            defaultColor={{
                backgroundColor: Colors.get(ColorSections.BtnDangerBG),
                borderColor: Colors.get(ColorSections.BtnDangerBorder),
                color: Colors.get(ColorSections.BtnDangerColor) 
            }}
            hoverColor={{
                backgroundColor: Colors.get(ColorSections.BtnDangerHoverBG),
                borderColor: Colors.get(ColorSections.BtnDangerHoverBorder),
                color: Colors.get(ColorSections.BtnDangerHoverColor) 
            }}
            activeColor={{
                backgroundColor: Colors.get(ColorSections.BtnDangerActiveBG),
                borderColor: Colors.get(ColorSections.BtnDangerActiveBorder),
                color: Colors.get(ColorSections.BtnDangerActiveColor) 
            }}
            focusColor={{
                backgroundColor: Colors.get(ColorSections.BtnDangerFocusBG),
                borderColor: Colors.get(ColorSections.BtnDangerFocusBorder),
                color: Colors.get(ColorSections.BtnDangerFocusColor),
                BoxShadowColor: Colors.get(ColorSections.BtnDangerFocusBoxShadow)
            }}
            {...props} />
}

export const WarningButton = (props: IProps) => {
    return <CustomButton
            defaultColor={{
                backgroundColor: Colors.get(ColorSections.BtnWarningBG),
                borderColor: Colors.get(ColorSections.BtnWarningBorder),
                color: Colors.get(ColorSections.BtnWarningColor) 
            }}
            hoverColor={{
                backgroundColor: Colors.get(ColorSections.BtnWarningHoverBG),
                borderColor: Colors.get(ColorSections.BtnWarningHoverBorder),
                color: Colors.get(ColorSections.BtnWarningHoverColor) 
            }}
            activeColor={{
                backgroundColor: Colors.get(ColorSections.BtnWarningActiveBG),
                borderColor: Colors.get(ColorSections.BtnWarningActiveBorder),
                color: Colors.get(ColorSections.BtnWarningActiveColor) 
            }}
            focusColor={{
                backgroundColor: Colors.get(ColorSections.BtnWarningFocusBG),
                borderColor: Colors.get(ColorSections.BtnWarningFocusBorder),
                color: Colors.get(ColorSections.BtnWarningFocusColor),
                BoxShadowColor: Colors.get(ColorSections.BtnWarningFocusBoxShadow)
            }}
            {...props} />
}

export const InfoButton = (props: IProps) => {
    return <CustomButton 
            defaultColor={{
                backgroundColor: Colors.get(ColorSections.BtnInfoBG),
                borderColor: Colors.get(ColorSections.BtnInfoBorder),
                color: Colors.get(ColorSections.BtnInfoColor) 
            }}
            hoverColor={{
                backgroundColor: Colors.get(ColorSections.BtnInfoHoverBG),
                borderColor: Colors.get(ColorSections.BtnInfoHoverBorder),
                color: Colors.get(ColorSections.BtnInfoHoverColor) 
            }}
            activeColor={{
                backgroundColor: Colors.get(ColorSections.BtnInfoActiveBG),
                borderColor: Colors.get(ColorSections.BtnInfoActiveBorder),
                color: Colors.get(ColorSections.BtnInfoActiveColor) 
            }}
            focusColor={{
                backgroundColor: Colors.get(ColorSections.BtnInfoFocusBG),
                borderColor: Colors.get(ColorSections.BtnInfoFocusBorder),
                color: Colors.get(ColorSections.BtnInfoFocusColor),
                BoxShadowColor: Colors.get(ColorSections.BtnInfoFocusBoxShadow)
            }}
            {...props} />
}
