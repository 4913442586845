import { Dialog } from "../../../utils/dialog/DialogManager";
import CreateDocumentPopup from "../CreateDocument";
import { PathsEditor } from "../PathsEditor";
import { ObjectId } from "bson";
import { PrimaryButton, SecondaryButton } from "../../../utils/CustomButton";
import { mdiFilePlusOutline, mdiFolderEditOutline, mdiGroup } from "@mdi/js";
import styled from "@emotion/styled";
import PathGroupStyleEditor from "../DocumentGroupEditor";
import Constants from "../../../Constants";
import { FilterType } from "../../../utils/List/Filter";
import Localized from "../../../Localization/Localized";

type TPathActionsProps = {
    spaceId: ObjectId;
    path: string;
    parentPath?: string;
    pathChanged: () => Promise<void>;
    docCreated: () => void;
}

export const PathActions = (props: TPathActionsProps) => {
    const createDocument = async (spaceId: ObjectId, path?: string) => {
        const result = await CreateDocumentPopup.open(path ? {
            filter: path,
            type: FilterType.Equal
        }: undefined, spaceId);

        if(result) props.docCreated();
    }

    const editPath = async (path: string, spaceId?: ObjectId) => {
        const renamed = async () => {
            await props.pathChanged();
        }

        await Dialog.config(Localized.Document.RenamePathPopupTitle, <PathsEditor path={path} spaceId={spaceId} onRenamed={renamed} />);
    }

    const openPathGroupEditor = async () => {
        const changed = async () => {
            await props.pathChanged();
        }

        await Dialog.config(Localized.Document.PathGroupStylePopupTitle, <PathGroupStyleEditor name={props.path} 
        path={props.parentPath ?? ""} onChanged={changed} />);
    }

    const path = props.parentPath ? `${props.parentPath} | ${props.path}` : props.path;

    return  <PathActionsStyled>
    <PrimaryButton maIcon={mdiFilePlusOutline} onClick={(evt) => {
        evt.stopPropagation();
        createDocument(props.spaceId, path);
    }} link title={Localized.Document.AddNewDocumentTooltip} />
    <SecondaryButton maIcon={mdiFolderEditOutline} onClick={(evt) => {
        evt.stopPropagation();
        editPath(path, props.spaceId);
    }} link title={Localized.Document.EditPathNameTooltip} />
    <SecondaryButton onClick={(evt) => {
            evt.stopPropagation();
            openPathGroupEditor();
        }} maIcon={mdiGroup} link title={Localized.Document.EditPathGroupStyleTooltip} />
</PathActionsStyled>
}

const PathActionsStyled = styled.div`
    > * {
        opacity: ${Constants.ActionDefaultOpacity};
    }

    > *:hover {
        opacity: 1;
    }
`;