import { InputGroupText } from "reactstrap";
import Localized from "../../../Localization/Localized";
import { CustomInput, CustomSelect } from "../../../utils/CustomInput";
import { CustomInputGroup } from "../../../utils/CustomInputGroup";
import { FilterType } from "../../../utils/List/Filter";
import { FilterPopupContent } from "../../../utils/List/FilterPopupContent";
import { DocumentFilterDefault, IDocumentFilter } from "./IDocumentFilter";
export class DocumentFilterPopupContent extends FilterPopupContent<IDocumentFilter> {
    get publicFilterLabel(): string {
        return Localized.General.PublishedOnly;
    }
    get privateFilterLabel(): string {
        return Localized.General.MineOnly;
    }
    get textFilterLabel(): string {
        return Localized.General.Title;
    }

    DefaultFilter(): IDocumentFilter {
        return DocumentFilterDefault();
    }

    onPrivateToggle = () => {
        const filter = this.state.filter;
        filter.isPrivateOnly = !filter.isPrivateOnly;

        this.setState({
            filter: filter
        }, this.onChanged);
    }

    onPublicToggle = () => {
        const filter = this.state.filter;
        filter.isPublicOnly = !filter.isPublicOnly;

        this.setState({
            filter: filter
        }, this.onChanged);
    }

    pathChange = (value: string) => {
        this.setState(state => {
            const f = state.filter;
            f.path = {
                filter: value,
                type: f.path?.type ?? FilterType.StartWith
            }
            return  {
                filter: f
            }
        }, this.onChanged);
    }

    pathTypeChange = (value: string) => {
        console.log("pathTypeChange", value);
        let v = FilterType.StartWith
        if(value === "C") v = FilterType.Contain;
        if(value === "EW") v = FilterType.EndWith;
        if(value === "E") v = FilterType.Equal;

        this.setState(state => {
            const f = state.filter;
            f.path = {
                filter: f.path?.filter ?? "",
                type: v
            }
            return  {
                filter: f
            }
        }, this.onChanged);
    }

    keysChange = (value: string) => {
        this.setState(state => {
            const f = state.filter;
            f.keys = {
                filter: value
            }
            return  {
                filter: f
            }
        }, this.onChanged);
    }

    protected beforeTitle(): JSX.Element[] {
        return []
    }
    protected betweenTitleAndDate(): JSX.Element[] {
        return [
            <CustomInputGroup>
                <InputGroupText>{Localized.General.Path}</InputGroupText>
                <CustomSelect onChange={(evt) => this.pathTypeChange(evt.currentTarget.value)} value={this.filterTypeToString(this.state.filter.path?.type)}>
                    <option value={this.filterTypeToString(FilterType.StartWith)}>{Localized.General.StartWith}</option>
                    <option value={this.filterTypeToString(FilterType.EndWith)}>{Localized.General.EndWith}</option>
                    <option value={this.filterTypeToString(FilterType.Equal)}>{Localized.General.Equal}</option>
                    <option value={this.filterTypeToString(FilterType.Contain)}>{Localized.General.Contain}</option>
                </CustomSelect>
                <CustomInput value={this.state.filter.path?.filter ?? ""} onChange={(evt) => this.pathChange(evt.currentTarget.value)} />
            </CustomInputGroup>,
            <CustomInputGroup>
                <InputGroupText>{Localized.General.KeysLabel}</InputGroupText>
                <InputGroupText>{Localized.General.Contain}</InputGroupText>
                <CustomInput value={this.state.filter.keys?.filter ?? ""} onChange={(evt) => this.keysChange(evt.currentTarget.value)} />
            </CustomInputGroup>
        ]
    }
    protected afterDate(): JSX.Element[] {
        return [];
    }
}
