import { ObjectId } from "bson";
import { Msg } from "../../../utils/Messenger";
import { Dialog } from "../../../utils/dialog/DialogManager";
import ReactDiffViewer from 'react-diff-viewer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import DateHelper from "../../../utils/date/DateHelper";
import Loading from "../../../utils/Loading";
import { DialogSize } from "../../../utils/dialog/enums/DialogSize";
import React from "react";
import { PrimaryButton } from "../../../utils/CustomButton";
import DisplayName from "../../../utils/DisplayName";
import DocumentService from "../../../services/DocumentService";
import IDocumentChange from "../../../realm/schemas/Document/IDocumentChange";
import Localized from "../../../Localization/Localized";

var beautify_html = require('js-beautify');

type TContentHistoriesProps = {
    documentId: ObjectId;
}

type TContentHistoriesState = {
    histories: IDocumentChange[];
    selected: ObjectId[];
    running: boolean;
}

export class ContentHistories extends React.Component<TContentHistoriesProps, TContentHistoriesState> {
    constructor(props: TContentHistoriesProps | Readonly<TContentHistoriesProps>) {
        super(props);

        this.state = {
            histories: [],
            selected: [],
            running: false 
        }
    }

    componentDidMount = () => {
        this.load();
    }

    isSelected = (id: ObjectId) => {
        const found = this.state.selected.find(f => f.toHexString() === id.toHexString());
        if(found) return true;
        return false;
    }

    toggleSelect = (id: ObjectId) => {
        if(this.isSelected(id)) {
            this.setState(state => {
                return  {
                    selected: state.selected.filter(f => f.toHexString() !== id.toHexString())
                }
            })
        }
        else {
            if(this.state.selected.length >= 2) {
                Msg.warning(Localized.Document.CompareOnly2DocumentSelected);
                return;
            }
            const selected = this.state.selected;
            selected.push(id);
            this.setState({
                selected: selected
            });
        }
    }

    diff = async () => {
        if(this.state.selected.length !== 2) return;

        await new Promise<void>(resolve => {
            this.setState({
                running: true
            },() => {
                resolve();
            });
        });

        let contentOne = "";
        if(this.state.selected[0].toString() === this.props.documentId.toString()) {
            const d = await  DocumentService.GetById(this.state.selected[0]);
            contentOne = d?.content ?? "";
        } else {
            const d = await DocumentService.GetChange(this.props.documentId, this.state.selected[0]);
            contentOne = d?.content ?? "";
        }

        let contentTwo = "";
        if(this.state.selected[1].toString() === this.props.documentId.toString()) {
            const d = await DocumentService.GetById(this.state.selected[1]);
            contentTwo = d?.content ?? "";
        } else {
            const d = await DocumentService.GetChange(this.props.documentId, this.state.selected[1]);
            contentTwo = d?.content ?? "";
        }

        await new Promise<void>(resolve => {
            this.setState({
                running: false
            },() => {
                resolve();
            });
        });

        Dialog.config("Diff", <ReactDiffViewer oldValue={beautify_html.html_beautify(contentOne)} newValue={beautify_html.html_beautify(contentTwo)} splitView={true} showDiffOnly={true}/>,
        {
            size: DialogSize.Max
        });
    }

    load = async () => {
        DocumentService.GetChanges(this.props.documentId, 'Update Content').then(result => {
            this.setState({
                histories: result ?? []
            });
        });
    }

    render = () => {
        return <div style={{
            position:"relative",
            padding: "0.5rem",
            fontSize: "1.5em"
        }}>
        <div style={{position: "fixed", display: "flex"}}>
            <PrimaryButton onClick={this.diff} disabled={this.state.running || this.state.selected.length !== 2} >{this.state.running ? <Loading /> : Localized.General.Compare}</PrimaryButton>
        </div>
        <div style={{marginTop: "calc(1.8em + 0.25rem)"}}>
            <span onClick={() => this.toggleSelect(this.props.documentId)}>{this.isSelected(this.props.documentId) && <FontAwesomeIcon icon="check-square" />}{!this.isSelected(this.props.documentId) && <FontAwesomeIcon icon="square" />}</span>
            &nbsp;{Localized.General.LastVersion}
        </div>
        {this.state.histories.map(m => <div key={m._id.toHexString()}>
            <span onClick={() => this.toggleSelect(m._id)}>{this.isSelected(m._id) && <FontAwesomeIcon icon="check-square" />}{!this.isSelected(m._id) && <FontAwesomeIcon icon="square" />}</span>
            &nbsp;{DateTime.fromJSDate(m.createdDateTimeUTC).toFormat(DateHelper.dateTimeFormatShort)}
            &nbsp;{Localized.General.Version.toLowerCase()}&nbsp;{m.version}
            &nbsp;{Localized.General.By.toLowerCase()}&nbsp;<DisplayName id={m.createdById} />
            {m.comment && m.comment.length > 0 && <>&nbsp;-&nbsp;{m.comment}</>}
        </div>)}
    </div>
    }
}