import { mdiPlusThick } from "@mdi/js";
import Localized from "../../../Localization/Localized";
import { SecondaryButton } from "../../../utils/CustomButton";
import { CustomInput } from "../../../utils/CustomInput";
import { CustomInputGroup } from "../../../utils/CustomInputGroup";
import { CustomInputGroupText } from "../../../utils/CustomInputGroupText";
import { OrderPopupContent, TOrderPopupContentState } from "../../../utils/List/OrderPopupContent";
import IDocumentOrder, { DocumentOrderDefault } from "./IDocumentOrder";

interface TState  extends TOrderPopupContentState<IDocumentOrder> {
    newKeyIndex?: string;
}
export default class DocumentOrderPopupContent extends OrderPopupContent<IDocumentOrder, TState> {
    initState(): TState {
        return {
            order: this.props.order ?? this.DefaultOrder()
        }
    }

    DefaultOrder(): IDocumentOrder {
        return DocumentOrderDefault();
    }

    newKeyIndexChenged = (index: string) => {
        let i = parseInt(index);

        if(isNaN(i)) i = 0;

        this.setState({
            newKeyIndex: i.toString()
        });
    }

    customItems(): JSX.Element[] {
        const disable = !this.state.newKeyIndex || this.state.newKeyIndex.length === 0;
        return [
            <CustomInputGroup>
                <CustomInputGroupText>Add key</CustomInputGroupText>
                <CustomInput value={this.state.newKeyIndex} placeholder={Localized.Document.OrderKeysPlaceholder} onChange={(evt) => this.newKeyIndexChenged(evt.currentTarget.value)} />
                <SecondaryButton onClick={this.addKeysOrder} maIcon={mdiPlusThick} disabled={disable}/>
            </CustomInputGroup>
        ]
    }

    addKeysOrder = async () => {
        if(!this.state.newKeyIndex || parseInt(this.state.newKeyIndex) < 0) return;
        let index = this.state.newKeyIndex;

        const order = this.state.order;

        const found = order?.fields.find(f => f.field === `keys.${index}`);

        if(found) return;

        order?.fields.push({
            label: `${Localized.General.KeysLabel} ${index}`,
            field: `keys.${index}`,
            direction: "A"
        });

        this.setState({
            order: order,
            newKeyIndex: undefined
        });
    }
}