import { mdiFormatListText } from "@mdi/js";
import Icon from "@mdi/react";
import RightOffcanvasService from "../../services/RightOffcanvasService";
import { CustomListsView } from "./CustomListsView";

export default class CustomListRightCanvas {
    static Set() {
        RightOffcanvasService.Push([
            <div><Icon path={mdiFormatListText} size="1.5em" /></div>,
            <CustomListsView orientation="V" isCollapsible />
        ]);
    }
}