import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import HeaderService from "../../services/HeaderService";
import LocationService from "../../services/LocationService";
import { AuthService } from "../../services/AuthService";
import { IProfile } from "../../realm/schemas/Profile/IProfile";
import { Dialog } from "../../utils/dialog/DialogManager";
import Colors, { ColorSections } from "../../utils/Color/MainColors";
import { LeftOffcanvas } from "./Offcanvas/LeftOffcanvas";
import { RightOffcanvas } from "./Offcanvas/RightOffcanvas";
import { INeedProfilePageState } from "../profile/NeedProfilePage";
import {style} from "typestyle";
import Constants from "../../Constants";
import ProfileMenu from "./ProfileMenu";
import { HeaderActionStyled } from "./HeaderActionStyled";
import Localized from "../../Localization/Localized";

interface THeaderState extends INeedProfilePageState {
    currentProfile: IProfile | null;
    title: string | JSX.Element;
    colorsProfile: Colors;
}
export class Header extends React.Component<{},THeaderState> {
    private _profileMenuDismiss: () => void = () => {};

    constructor(props: THeaderState | Readonly<THeaderState>) {
        super(props);

        this.state = {
            currentProfile: AuthService.currentProfile ?? null,
            title: HeaderService.title,
            colorsProfile: Colors.Current
        }
    }

    componentDidMount = () => {
        AuthService.subscribeChange("Header", () => {
            this.setState({
                currentProfile: AuthService.currentProfile ?? null,
                colorsProfile: AuthService.getCurrentColorsProfile()
            });
        });

        HeaderService.subscribeChange("Header", () => {
            this.setState({
                title: HeaderService.title
            });
        });
    }

    componentWillUnmount = () => {
        AuthService.unsubscribeChange("Header");
        HeaderService.unsubscribeChange("Header");
    }

    signIn = () => {
        LocationService.goTo("/Login");
    }

    goTo = (path: string) => {
        if(this._profileMenuDismiss) this._profileMenuDismiss();
        LocationService.goTo(path);
    }

    signOut = async () => {
        if(this._profileMenuDismiss) this._profileMenuDismiss();
        await AuthService.signOut();
        LocationService.goTo("/");
    }

    openProfileMenu = (evt: React.MouseEvent<Element>) => {
        Dialog.tooltip(<ProfileMenu signOut={this.signOut} goTo={this.goTo} />, {
            handle: evt.currentTarget,
            dispatchDismiss: (dismiss) => { this._profileMenuDismiss = dismiss },
            notModal: false
        })
    }

    render = () => {
        const headerStyle = style({
            backgroundColor: this.state.colorsProfile.get(ColorSections.HeaderBG),
            color: this.state.colorsProfile.get(ColorSections.HeaderText),
            borderBottomColor: this.state.colorsProfile.get(ColorSections.HeaderBottomBorder)
        });

        return <HeaderContainer className={headerStyle}>
            <div style={{
                minWidth: 0
            }}>
                <LeftOffcanvas />
                <HeaderTitle>
                    {HeaderService.title}
                </HeaderTitle>
            </div>
            <div style={{
                whiteSpace: "nowrap"
            }}>
                {!AuthService.isLoggedIn && <HeaderActionStyled onClick={this.signIn}>{Localized.LoginView.SignIn} <FontAwesomeIcon icon="sign-in-alt" /></HeaderActionStyled>}
                {AuthService.isLoggedIn && <>
                    <HeaderActionStyled onClick={this.openProfileMenu}>{AuthService.currentProfile?.display_name} <FontAwesomeIcon icon="user" /></HeaderActionStyled>
                    <RightOffcanvas />
                </>}
            </div>
        </HeaderContainer>
    }
}

const HeaderTitle = styled.h1`
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 0;
    min-width: 0;
    > * {
        margin-right: 0.25rem;
        min-width: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    height: ${Constants.HeaderHeight};
    overflow: hidden;
    box-sizing: border-box;

    > * {
        display: flex;
        align-items: center; 
    }
`;