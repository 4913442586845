import { OrderPopupContent, TOrderPopupContentState } from "../../../../utils/List/OrderPopupContent";
import ISpaceOrder, { SpaceOrderDefault } from "./SpaceOrder";

interface TState  extends TOrderPopupContentState<ISpaceOrder> {

}

export default class SpaceOrderPopupContent extends OrderPopupContent<ISpaceOrder, TState> {
    initState(): TState {
        return {
            order: this.props.order ?? this.DefaultOrder()
        }
    }
    DefaultOrder(): ISpaceOrder {
        return SpaceOrderDefault();
    }

    customItems(): JSX.Element[] {
        return [];
    }
}