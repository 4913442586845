import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Localized from "../../../Localization/Localized";
import SpaceService from "../../../services/SpaceService";
import { SuccessButton } from "../../../utils/CustomButton";
import { CustomInput } from "../../../utils/CustomInput";
import { CustomInputGroup } from "../../../utils/CustomInputGroup";
import { CustomInputGroupText } from "../../../utils/CustomInputGroupText";

export type TAddSpacesProps = {
    spacerAdded: ()=> void
}

export type TAddSpaceState = {
    newSpaceName: string;
    isAddDisabled?: boolean;
    isAdding?: boolean;
}

export class AddSpaces extends React.Component<TAddSpacesProps, TAddSpaceState> {
    constructor(props: Readonly<TAddSpacesProps>) {
        super(props);

        this.state = {
            newSpaceName: "",
            isAddDisabled: true
        };
    }

    add = () => {
        this.setState({
            isAddDisabled: true,
            isAdding: true
        }, () => {
            SpaceService.create(this.state.newSpaceName).then(result => {
                if (result) {
                    this.setState({
                        newSpaceName: "",
                        isAddDisabled: false,
                        isAdding: false
                    }, this.props.spacerAdded);
                }
            });
        });
    };

    onChange = (value: string) => {
        if (!value || value.length === 0) {
            this.setState({
                newSpaceName: "",
                isAddDisabled: true
            });

            return;
        }

        if (/^[A-Z0-9-:_ ]+$/.test(value.toUpperCase())) {
            this.setState({
                newSpaceName: value.toUpperCase(),
                isAddDisabled: false
            });
        }
    };

    render = () => {
        return <>
            <CustomInputGroup>
                <CustomInputGroupText>Add a space</CustomInputGroupText>
                <CustomInput value={this.state.newSpaceName}
                    onChange={(evt) => this.onChange(evt.currentTarget.value)}
                    placeholder="A-Z 0-9-:_#."
                    maxLength={100} disabled={this.state.isAdding} />
                <SuccessButton title={Localized.Space.AddNewSpaceTooltip} onClick={this.add} disabled={this.state.isAddDisabled}><FontAwesomeIcon icon="plus" /></SuccessButton>
            </CustomInputGroup>
            <div style={{
                fontSize: "0.8em"
            }}>
                <div>
                    {Localized.Space.CharacterConstraint}
                </div>
                <div>
                    {Localized.Space.NoSpaceStartAndEnd}
                </div>
                <div>
                    {Localized.Space.NbCharacters}
                </div>
            </div>
        </>;
    };
}
