import React from "react";
import Loading, { LoadingDiv } from "../../../utils/Loading";
import PathListItem from "./PathListItem";
import { ObjectId } from "bson";
import { IDocumentFilter } from "../FilterAndOrder/IDocumentFilter";
import SpaceService from "../../../services/SpaceService";

type TPathListProps = {
    spaceId: ObjectId;
    filter?: IDocumentFilter;
    filterChangeHandler?: (handle: (filter: IDocumentFilter) => void) => void;
    reloadHandler?: (handle: () => Promise<void>) => void;
    context?: string;
}

type TPathListState = {
    paths: { _id: string }[]
    isLoading: boolean;
    filter?: IDocumentFilter;
}

export default class PathList extends React.Component<TPathListProps, TPathListState> {
    constructor(props: Readonly<TPathListProps>) {
        super(props);

        this.state = {
            paths: [],
            isLoading: true
        }

        if(props.filterChangeHandler) props.filterChangeHandler((filter: IDocumentFilter) => {
            this.setState({
                filter: filter,
                paths: [],
                isLoading: true
            }, this.load);
        });

        if(props.reloadHandler) props.reloadHandler(async () => {
            await this.load();
        });
    }

    load = async () => {
        const results = await SpaceService.getPaths(this.props.spaceId, 0, undefined, this.state.filter);

        this.setState({
            paths: results,
            isLoading: false
        });
    }

    componentDidMount = () => {
        this.load();
    }

    render = () => {
        return <div style={{
                position: "relative",
                width: "100%" 
            }}>
            {this.state.isLoading && <LoadingDiv style={{
                fontSize: "2em"
            }}><Loading /></LoadingDiv>}
            {!this.state.isLoading && this.state.paths.map(p => <PathListItem key={p._id} 
            spaceId={this.props.spaceId} path={p._id} level={1} filter={this.state.filter} onChanged={this.load} context={this.props.context} />)}
        </div>
    }
}


