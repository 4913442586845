import { mdiContentSave } from "@mdi/js";
import React from "react";
import { PrimaryButton } from "../../utils/CustomButton";
import { DocumentList } from "../Document/DocumentList/DocumentList";
import styled from "@emotion/styled";
import { ICustomList } from "../../realm/schemas/ICustomList";
import Colors, { ColorSections } from "../../utils/Color/MainColors";
import SpaceList from "../Document/Space/SpaceList";
import { ISort } from "../../utils/List/Order";
import { IFilter } from "../../utils/List/Filter";
import { v4 } from "uuid";
import GlobalStateService from "../../services/GlobalStateService";

export type TCustomListItemProps = {
    orientation?: "H" | "V";
    customList: ICustomList;
    save: (fo: [IFilter, ISort]) => Promise<boolean>;
    isCollapsible?: boolean;
    collapsed?: boolean;
}

export type TCustomListItemState = {
    saving: boolean;
    collapsed?: boolean;
}

export class CustomListItem extends React.Component<TCustomListItemProps, TCustomListItemState> {
    private _getFilterAndOrderHandle?: () => [IFilter, ISort];
    constructor(props: TCustomListItemProps | Readonly<TCustomListItemProps>) {
        super(props);

        const c = GlobalStateService.Get(`CLI_${this.props.customList._id.toHexString()}_collapsed`);

        this.state = {
            saving: false,
            collapsed: c !== null ? c : props.collapsed
        };
    }

    save = async () => {
        if (!this._getFilterAndOrderHandle)
            return;

        const fo = this._getFilterAndOrderHandle();

        await this.props.save(fo);

        this.setState({
            saving: false
        });
    };

    toggleCollapse = () => {
        this.setState(state => {
            return {
                collapsed: !state.collapsed
            }
        }, () => {
            GlobalStateService.Set(`CLI_${this.props.customList._id.toHexString()}_collapsed`, this.state.collapsed);
        });
    }

    render = () => {
        const l = this.props.customList;
        const maxItemsHeight = this.props.isCollapsible ? undefined : "20em";
        const listTitle = this.props.isCollapsible ? undefined : l.name;
        const collapsed = this.props.isCollapsible ? this.state.collapsed : false;
        return <>
            <ItemStyled orientation={this.props.orientation} className="u-container">
                {this.props.isCollapsible && <CollapsibleHeaderStyled onClick={this.toggleCollapse}>
                    <span style={{ fontSize: "1.5em" }}>{l.name}</span>
                </CollapsibleHeaderStyled>}
                {!collapsed && <>
                    {l.list === "Documents" && <DocumentList filter={l.filter} order={l.order} title={listTitle} maxItemsHeight={maxItemsHeight} filterAndOrderHandler={(handle) => {
                        this._getFilterAndOrderHandle = handle;
                    }} customActions={() => {
                        return [
                            <PrimaryButton key={v4()} maIcon={mdiContentSave} onClick={this.save} disabled={this.state.saving} link />
                        ];
                    }} context="CustomList" />}
                    {l.list === "Spaces" && <SpaceList filter={l.filter} order={l.order} title={listTitle} maxItemsHeight={maxItemsHeight} filterAndOrderHandler={(handle) => {
                        this._getFilterAndOrderHandle = handle;
                    }} customActions={() => {
                        return [
                            <PrimaryButton key={v4()} maIcon={mdiContentSave} onClick={this.save} disabled={this.state.saving} link />
                        ];
                    }} context="CustomList" />} 
                </>}      
            </ItemStyled>
        </>;
    };
}

const CollapsibleHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    paddingLeft: 0.25rem;
    width: 100% 
`;

type TItemStyledProps = {
    orientation?: "H" | "V";
}

export const ItemStyled = styled.div<TItemStyledProps>`
    position: relative;
    display : flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    width: ${p => p.orientation === "V" ? "100%" : "30em" };
    height: ${p => p.orientation === "H" ? "100%" : undefined };
    maxHeight: ${p => p.orientation === "H" ? "100%" : "20em" };
    border: 1px solid ${Colors.get(ColorSections.SectionBorder)};
    background-color: ${Colors.get(ColorSections.SectionBG)};
    color: ${Colors.get(ColorSections.SectionText)};
    border-radius: 0.25rem;
    margin:0.25rem;
    overflow: hidden;
`;

export const AddItemStyled = styled(ItemStyled)`
    justify-content: center;
`;
