import { ObjectId } from "bson";
import { DateTime } from "luxon";
import { FilterType, ITextAndDateAdnPrivacyFilter, ITextFiltred } from "../../../utils/List/Filter";

export interface IDocumentFilter extends ITextAndDateAdnPrivacyFilter {
    keys: {
        filter: string;
    },
    path: ITextFiltred,
    spaceId?: ObjectId;
}

export const DocumentFilterDefault: () => IDocumentFilter = () => {
    return {
        isPrivateOnly: false,
        isPublicOnly: false,
        text: {
            filter: "",
            type: FilterType.Contain
        },
        path: {
            filter: "",
            type: FilterType.StartWith              
        },
        keys: {
            filter: ""            
        },
        skip: 0,
        limit: 100
    }
}

export const DocumentFilterToSearch:(filter: IDocumentFilter, searchParams:URLSearchParams) => void = (filter: IDocumentFilter, searchParams:URLSearchParams) => {
    searchParams.append("private", filter.isPrivateOnly ? "true" : "false");
    searchParams.append("public", filter.isPublicOnly ? "true" : "false");

    if(filter.text.filter && filter.text.filter.length > 0) {
        searchParams.append("text_filter", filter.text.filter);
        searchParams.append("text_type", FromFilterType(filter.text.type)); 
    }

    if(filter.path.filter && filter.path.filter.length > 0) {
        searchParams.append("path_filter", filter.path.filter);
        searchParams.append("path_type", FromFilterType(filter.path.type)); 
    }

    if(filter.keys.filter && filter.keys.filter.length > 0) {
        searchParams.append("keys_contain", filter.keys.filter);
    }

    if(filter.startDate) searchParams.append("start_date", filter.startDate.toISO());

    if(filter.endDate) searchParams.append("end_date", filter.endDate.toISO());

    if(filter.spaceId) searchParams.append("space_id", filter.spaceId.toHexString());

    searchParams.append("skip", filter.skip.toString());

    searchParams.append("limit", filter.limit.toString());
}

export const DocumentFilterFromSearch:(params: URLSearchParams) => IDocumentFilter = (params: URLSearchParams) => {
    const f = DocumentFilterDefault();

    const pr = params.get("private");
    if(pr && pr.toLowerCase() === "true") f.isPrivateOnly = true;

    const pu = params.get("public");
    if(pu && pu.toLowerCase() === "true") f.isPublicOnly = true;

    const tf = params.get("text_filter");
    if(tf) f.text.filter = tf;

    const tt = params.get("text_type");
    if(tf) f.text.type = ToFilterType(tt);

    const pf = params.get("path_filter");
    if(pf) f.path.filter = pf;

    const pt = params.get("path_type");
    if(pf) f.path.type = ToFilterType(pt);

    const kc = params.get("keys_contain");
    if(kc) f.keys.filter = kc;

    f.skip = ToSkip(params.get("skip"));

    f.limit = ToLimit(params.get("limit"));

    const sd = params.get("start_date");
    if(sd) f.startDate = DateTime.fromISO(sd);

    const ed = params.get("end_date");
    if(ed) f.endDate = DateTime.fromISO(ed);

    const sId = params.get("space_id");
    if(sId) {
        try {
            f.spaceId = new ObjectId(sId);
        } catch (error) {
            console.error(`${sId} is not an space id`)
        }
    }

    return f;
}

const ToSkip = (skip: string | null) => {
    if(!skip) return 0;

    const s = parseInt(skip);

    if(isNaN(s)) return 0;

    return s;
}

const ToLimit = (limit: string | null) => {
    if(!limit) return 100;

    const s = parseInt(limit);

    if(isNaN(s) || s <= 0) return 100;

    return s;
}

const ToFilterType = (type: string | null) => {
    switch (type) {
        case "contain":
            return FilterType.Contain;
        case "start_with":
            return FilterType.StartWith;
        case "end_with":
            return FilterType.EndWith;
        case "equal":
            return FilterType.Equal;
    }

    return FilterType.Contain;
}

const FromFilterType = (type: FilterType) => {

    switch (type) {
        case FilterType.Contain:
            return "contain";
        case FilterType.StartWith:
            return "start_with";
        case FilterType.EndWith:
            return "end_with";
        case FilterType.Equal:
            return "equal";
    }
    return "contain";
}

