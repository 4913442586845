import React from "react";
import { InputGroupText } from "reactstrap";
import Colors, { ColorSections } from "./Color/MainColors";
import {style} from "typestyle";

enum InputState {
    Default,
    Success,
    Warning,
    Error
}

interface IProps {
    style?: React.CSSProperties;
    state?: InputState
    children: React.ReactNode;
}

interface ICustomProps extends IProps {
    state?:InputState;
}

export const CustomInputGroupText = (props: ICustomProps) => {
    //const state = props.state ?? InputState.Default;

    const inputClass = style({
        backgroundColor: Colors.get(ColorSections.InputGroupTextBG), 
        borderColor: Colors.get(ColorSections.InputGroupTextBorder),
        color: Colors.get(ColorSections.InputGroupTextColor),
    });

    return <InputGroupText 
        className={inputClass}>{props.children}</InputGroupText>
}