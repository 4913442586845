import { IProfile } from "../realm/schemas/Profile/IProfile";
import { ObjectId } from "bson";
import { UMongoDB } from "../realm/MongoDB";
import { RealmApp } from "../realm/RealmApp";
import Observer from "../utils/Observer";
import * as Realm from "realm-web";
import { Msg } from "../utils/Messenger";
import Colors from "../utils/Color/MainColors";
import { IProfilePreference } from "../realm/schemas/Profile/IProfilePreference";
import { ICustomList } from "../realm/schemas/ICustomList";
import { CacheContainer } from 'node-ts-cache'
import { MemoryStorage } from 'node-ts-cache-storage-memory'
import Constants from "../Constants";
import Localized from "../Localization/Localized";

const authServiceGetByIdCache = new CacheContainer(new MemoryStorage())

export interface IProfileProps {
    currentProfile: IProfile | null;
}

export enum ProfilePreferenceKeys {
    ColorsProfile
}
export class AuthService extends Observer {
    public static readonly ColorsProfilePreferenceKey = "ColorsProfile";
    public static readonly ProfileLoadedEventKey = "ProfileLoadedEvent";

    private static readonly _instance: AuthService = new AuthService();
    public static get Instance() {
        return AuthService._instance;
    }

    private _currentProfile?: IProfile | null;

    static get isLoggedIn() {
        return RealmApp.currentUser?.state === "active";
    }

    static get currentProfile() {
        return this.Instance._currentProfile;
    }

    static isOwner = (ownerId?: ObjectId) => {
        return ownerId?.toHexString() === this.Instance._currentProfile?._id.toHexString();
    }

    static LoadCurrentProfile = async (forceReloald: boolean = false) => {
        if(!forceReloald && this.Instance._currentProfile) return this.Instance._currentProfile;

        if (this.isLoggedIn) {
            if (!forceReloald && this.Instance._currentProfile) return this.Instance._currentProfile;
            const userId = new ObjectId(RealmApp.currentUser?.id);
            this.Instance._currentProfile = await this.GetById(userId);
            if(this.Instance._currentProfile && this.Instance._currentProfile.preferences) {
                const found = this.Instance._currentProfile.preferences.find(f => f.key === this.ColorsProfilePreferenceKey);
                if(found) {
                    Colors.LoadFromJson(found.value);
                }
            }
        }
        
        AuthService.Instance.notifyChange(this.ProfileLoadedEventKey, undefined, this.Instance._currentProfile);
        return AuthService.Instance._currentProfile;
    }

    static getColorsPreference = (profile: IProfile) => {
        const found = profile.preferences?.find(f => f.key === this.ColorsProfilePreferenceKey);
        if(found) {
            Colors.LoadFromJson(found.value);
            return Colors.Current.clone();
        }
    }

    static getCurrentColorsProfile = () => {
        return AuthService.currentProfile ? AuthService.getColorsPreference(AuthService.currentProfile) ?? Colors.Current : Colors.Current;
    }

    static signOut = async () => {
        this.Instance._currentProfile = null;
        await RealmApp.currentUser?.logOut();
        await this.LoadCurrentProfile();
    }

    static emailSignIn = async (email: string, password: string) => {
        const credentials = Realm.Credentials.emailPassword(email, password);

        try {
            await RealmApp.logIn(credentials);
            await this.LoadCurrentProfile();
        } catch (error) {
            console.log(error);
        }
    }

    static googleSignIn = async () => {
        const redirectUri = `${window.location.origin}/AuthRedirect`
        const credentials = Realm.Credentials.google(redirectUri);
        await RealmApp.logIn(credentials);
        await this.LoadCurrentProfile();
    }

    static async updateDisplayName(value: string) {
        return UMongoDB.Functions().profileUpdateDisplayName(value).then((result: any) => {
            if(result === "OK" || result === "Same display name") {
                Msg.success(Localized.AuthService.UpdateDisplayNameSaved);
                this.LoadCurrentProfile();
                return true;
            }
            if(result === "Profile not found") Msg.error(Localized.AuthService.ProfileNotFound);
            if(result === "<100") Msg.error(Localized.AuthService.LessThan100Caracters);
            if(result === "<30") Msg.error(Localized.AuthService.LessThen30Days);
            if(result === "FAIL") Msg.unexpectedError();

            return false;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });
    }

    static async updateDefaultLanguage(languageCode: string) {
        return UMongoDB.Functions().profileUpdateDefaultLanguage(languageCode).then((result: any) => {
            if(result === "OK" || result === "Same language code") {
                Msg.success(Localized.AuthService.UpdateDisplayNameSaved);
                if(result !== "Same language code") this.LoadCurrentProfile();
                return true;
            }

            if(result === "CodeNotSupported") 
                Msg.error(Localized.format(
                    Localized.AuthService.LanguageCodeNotSupported, 
                    `fr ${Localized.General.Or.toLocaleLowerCase()} en`));

            if(result === "FAIL") Msg.unexpectedError();

            return false;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });
    }

    static async updateColorsPreferences(value: any): Promise<boolean>{
        return await this.updatePreferences(ProfilePreferenceKeys.ColorsProfile, value, Localized.AuthService.UpdateColorsPreferenceSaved);
    }

    private static async updatePreferences(pkey: ProfilePreferenceKeys, value: any, successMsg: string): Promise<boolean>{
        if(!this.currentProfile) return false;

        let key = pkey === ProfilePreferenceKeys.ColorsProfile ? this.ColorsProfilePreferenceKey : "NoKey";

        let preferences: IProfilePreference[] | undefined = this.currentProfile?.preferences;
        
        if(!Array.isArray(preferences)) preferences = [];
    
        let flag = false;
        for(let pp of preferences){
            if(pp.key === key) {
                pp.value = value;
                flag = true;
            };
        }

        if(!flag) preferences.push({
            key: key,
            value: value
        });

        const state = await UMongoDB.profiles?.updateOne({ "_id" : new ObjectId(this.currentProfile._id) }, { $set: { preferences: preferences }})
        .then(result => {
            if(result.modifiedCount > 0) {
                Msg.success(successMsg);
                this.LoadCurrentProfile(true);
            }
            return true;
        }).catch(err => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });

        return state ?? false;
    }

    static async updateCustomLists(customLists: ICustomList[]): Promise<boolean>{
        if(!this.currentProfile) return false;

        const state = await UMongoDB.profiles?.updateOne({ "_id" : new ObjectId(this.currentProfile._id) }, { $set: { customLists: customLists }})
        .then(result => {
            if(result.modifiedCount > 0) {
                Msg.success(Localized.AuthService.UpdateCustomListsSaved);
                this.LoadCurrentProfile(true);
            }
            return true;
        }).catch(err => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        });

        return state ?? false;
    }

    static GetById =  async (id: ObjectId) => {
        const cachedUsers = await authServiceGetByIdCache.getItem<IProfile>(`${id.toHexString()}`)

        if (cachedUsers) {
            return cachedUsers
        }

        const result = await UMongoDB.profiles?.findOne({ _id : id});

        if(result) await authServiceGetByIdCache.setItem(`${id.toHexString()}`, result, {ttl: Constants.CacheTimoutInSec})

        return result
    }

    static subscribeChange(key: string, callBack: (field?: string, value?: any) => void): void {
        this.Instance.subscribeChange(this.ProfileLoadedEventKey, key, callBack);
    }
    
    static unsubscribeChange(key: string): void {
        this.Instance.unsubscribeChange(this.ProfileLoadedEventKey, key);
    }
}
