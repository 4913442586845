import { EditorComponent, Remirror, ThemeProvider, useRemirror, useRemirrorContext } from "@remirror/react";
import { prosemirrorNodeToHtml } from 'remirror';
import {
    BoldExtension, ItalicExtension, HeadingExtension, HorizontalRuleExtension, HardBreakExtension, UnderlineExtension,
    StrikeExtension, SubExtension, SupExtension, BlockquoteExtension, CodeBlockExtension, CodeExtension, FontFamilyExtension, TableExtension, 
    FontSizeExtension, TextColorExtension, TextHighlightExtension, TextCaseExtension, ImageExtension, NodeFormattingExtension, ShortcutsExtension,
    BulletListExtension, OrderedListExtension, TaskListExtension, MentionAtomExtension
} from 'remirror/extensions';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import 'remirror/styles/all.css';
import styled from "@emotion/styled";
import Colors, { ColorSections } from "../../../utils/Color/MainColors";
import { EditorButton, FloatingButtons } from "./EditorButtons/EditorButton";
import Localized from "../../../Localization/Localized";
import {isMobile} from 'react-device-detect';

type TEditorProps = {
    setContentHandle?: (handle: (content: string) => void) => void;
    setReadOnlyHandle?: (handle: (readOnly: boolean) => void) => void;
    setContentChangedHandle?: (handle: (contentChanged: boolean) => void) => void;
    readOnly?: boolean;
    contentChanged: (content: string) => void;
    save: () => Promise<boolean>;
    histories: () => Promise<boolean>;
}

export interface EditorRef {
    setContent: (content: any) => void;
}

const ImperativeHandle = forwardRef((_: unknown, ref: Ref<EditorRef>) => {
    const { setContent } = useRemirrorContext({
        autoUpdate: true,
    });

    // Expose content handling to outside
    useImperativeHandle(ref, () => ({ setContent }));

    return <></>;
});

type TEditorState = {
    contentChanged: boolean;
    readOnly: boolean;
}

export const Editor: React.FC<TEditorProps> = (props: TEditorProps) => {
    const [editorState, setEditorState] = useState<TEditorState>({
        readOnly: props.readOnly ?? false,
        contentChanged: props.setContentChangedHandle ? false : true
    });

    console.log("editorState", editorState);
    const editorRef = useRef<EditorRef | null>(null);

    const mae = new MentionAtomExtension({
        matchers: [
            {
                name: "maProfile",
                char: "@",
                matchOffset: 0
            },
            {
                name: "maTag",
                char: "#",
                matchOffset: 0
            },
            {
                name: "maDoc",
                char: "&",
                matchOffset: 0
            }
        ]
    });

    mae.addHandler("onChange", ({ range, query, changeReason, exitReason, view, addIgnored, suggester, ...rest }) => {
        console.log(range, query, suggester, changeReason, exitReason, view, addIgnored, rest);
    })

    const { manager, state, setState } = useRemirror({
        extensions: () => [
            new BoldExtension(),
            new ItalicExtension(),
            new HeadingExtension(),
            new HorizontalRuleExtension(),
            new HardBreakExtension(),
            new UnderlineExtension(),
            new StrikeExtension(),
            new SubExtension(),
            new SupExtension(),
            new BlockquoteExtension(),
            new CodeBlockExtension(),
            new CodeExtension(),
            new FontFamilyExtension(),
            new FontSizeExtension(),
            new TableExtension(),
            new TextColorExtension(),
            new TextHighlightExtension(),
            new TextCaseExtension(),
            new ImageExtension({ enableResizing: true }),
            new NodeFormattingExtension(),
            new ShortcutsExtension(),
            new BulletListExtension({ enableSpine: true }),
            new OrderedListExtension(),
            new TaskListExtension(),
            mae
        ],
        builtin: { persistentSelectionClass: 'selection' },
        stringHandler: "html",
        selection: 'end'
    });

    if(props.setContentHandle) props.setContentHandle((content) => {
        editorRef.current?.setContent(content);

        setEditorState({
            readOnly: editorState.readOnly,
            contentChanged: editorState.contentChanged 
        })
    });

    if(props.setReadOnlyHandle) {
        props.setReadOnlyHandle((readOnly) => {
            setEditorState({
                readOnly: readOnly,
                contentChanged: editorState.contentChanged
            });
        });
    }

    if(props.setContentChangedHandle) {
        props.setContentChangedHandle((contentChanged) => {
            setEditorState({
                readOnly: editorState.readOnly,
                contentChanged: contentChanged
            });
        });
    } 

    const tools: string[] = ["F", "H", "TA", "ID", "LST", "Q", "L", "TC", "I", "T", editorState.contentChanged ? "S" : "", "HI"];
    if(isMobile) tools.unshift("B");
    return <StyleContainer readOnly={props.readOnly}>
        <ThemeProvider>
            <Remirror manager={manager} placeholder={Localized.Document.EditorPlaceHolder} state={state} onChange={(p) => {
                setState(p.state);
                setEditorState({
                    readOnly: editorState.readOnly,
                    contentChanged: editorState.contentChanged
                });
                props.contentChanged(prosemirrorNodeToHtml(p.state.doc));
            }} editable={!editorState.readOnly} >
                {!editorState.readOnly && <EditorButton group={tools} fixed 
                saveHandle={props.save} openHistories={props.histories}/>}
                <ImperativeHandle ref={editorRef} />
                {!editorState.readOnly && !isMobile && <FloatingButtons />}
                <EditorComponent />
            </Remirror>
        </ThemeProvider>
    </StyleContainer>
}

type TStyleContainerProps = {
    readOnly?: boolean;
}

const StyleContainer = styled.div<TStyleContainerProps>`
    .remirror-theme .ProseMirror:focus {
        box-shadow: none;
    }
    .remirror-editor-wrapper {
        padding-top: ${props => props.readOnly ? "0" : "1.25rem"};
        font-size: 1.5em;
    }

    .remirror-suggest-atom {
        color: ${Colors.get(ColorSections.Text, 0.6)};
    }

    .mark, mark {
        padding:0;
    }

    code {
        display: inline-block;
        border: ${`1px solid ${Colors.get(ColorSections.Text, 0.5)}`};
        border-radius: 0.25rem;
        padding-right: 0.125rem;
        padding-left: 0.125rem;
        background-color: ${Colors.get(ColorSections.Text, 0.25)};
        color: ${Colors.get(ColorSections.Text)};
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${Colors.get(ColorSections.Text)}
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.65em;
        font-style: italic;
    }

    h3 {
        font-size: 1.45em;
    }

    h4 {
        font-size: 1.35em;
        font-style: italic;
    }

    h5 {
        font-size: 1.15em;
        font-style: italic;
    }

    h6 {
        font-size: 1.15em;
        font-style: bold;
    }

    ul:hover {
        .remirror-list-spine {
            border-left-color: ${Colors.get(ColorSections.Text, 0.75)}
            border-left-style: dashed;
        }
    }

    .remirror-list-spine {
        border-left-color: ${Colors.get(ColorSections.Text, 0.50)};
        border-left-style: dashed;
    }

    .remirror-collapsible-list-item-button {
        background-color: ${Colors.get(ColorSections.Text, 0.75)}
    }

    .remirror-collapsible-list-item-closed {
        > .remirror-list-item-marker-container {
            .remirror-collapsible-list-item-button:not(.disabled) {
                background-color: transparent;
                width:0;
                height:0;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid ${Colors.get(ColorSections.Text, 0.75)};
                border-radius: 0;
            }
        }
    }

    .remirror-collapsible-list-item-button:not(.disabled) {
        background-color: transparent;
        width:0;
        height:0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid ${Colors.get(ColorSections.Text, 0.75)};
        border-radius: 0;
    }

    .remirror-collapsible-list-item-closed {
        > .remirror-list-item-marker-container {
            padding-left: 8px;
        }
    }
`;
