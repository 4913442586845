import React from "react";
import Loading, { LoadingAbsDiv } from "../../utils/Loading";
import { InputGroup } from "reactstrap";
import Colors, { ColorSections } from "../../utils/Color/MainColors";
import { PrimaryButton } from "../../utils/CustomButton";
import { CustomInput } from "../../utils/CustomInput";
import { CustomInputGroupText } from "../../utils/CustomInputGroupText";
import { ObjectId } from "bson";
import styled from "@emotion/styled";
import DocumentService from "../../services/DocumentService";
import Localized from "../../Localization/Localized";

type TPathsEditorProps = {
    path: string;
    spaceId?: ObjectId;
    onRenamed?: () => void;
}

type TPathsEditorState = {
    newPath: string;
    running?: boolean;
    succesCount: number;
    failCount: number;
}

export class PathsEditor extends React.Component<TPathsEditorProps, TPathsEditorState> {
    private _prefixPath: string;
    private _original: string;
    constructor(props: TPathsEditorProps | Readonly<TPathsEditorProps>) {
        super(props);

        const splited = this.props.path.split("|").map(m => m.trim());

        let p = "";
        if (splited.length === 1) {
            p = splited[0];
        } else {
            p = splited[splited.length - 1];
        }

        splited.pop();

        this._prefixPath = splited.join(" | ");
        this._original = p;

        this.state = {
            newPath: p,
            succesCount: 0,
            failCount: 0
        };
    }

    onChange = (value: string) => {
        this.setState({
            newPath: value
        });
    };

    renamePaths = () => {
        if (this._original === this.state.newPath)
            return;

        const progress = (path: string, flag: boolean) => {
            this.setState(state => {
                return {
                    succesCount: flag ? state.succesCount + 1 : state.succesCount,
                    failCount: !flag ? state.failCount + 1 : state.failCount,
                };
            });
        };

        this.setState({
            running: true
        }, () => {
            DocumentService.UpdatePaths(this.props.path, this.state.newPath, progress).then((result) => {
                this.setState({
                    running: false
                }, () => {
                    if(this.props.onRenamed) this.props.onRenamed();
                });
            });
        });
    };

    render = () => {
        return <DivStiled>
            <InputGroup>
                <CustomInputGroupText>{Localized.General}</CustomInputGroupText>
                <CustomInputGroupText>{this.props.path}</CustomInputGroupText>
            </InputGroup>
            <InputGroup>
                <CustomInputGroupText>{Localized.Document.PathToRename}</CustomInputGroupText>
                {this._prefixPath && <CustomInputGroupText>{this._prefixPath}</CustomInputGroupText>}
                <CustomInput value={this.state.newPath} onChange={(evt => this.onChange(evt.currentTarget.value))} />
            </InputGroup>
            <InputGroup>
                <PrimaryButton onClick={this.renamePaths}>{Localized.General.Rename}</PrimaryButton>
                <CustomInputGroupText>(<span>{this.state.succesCount}</span>:<span style={{ color: Colors.get(ColorSections.DangerColor) }}>{this.state.failCount}</span>)</CustomInputGroupText>
            </InputGroup>
            {this.state.running && <LoadingAbsDiv><Loading /></LoadingAbsDiv>}
        </DivStiled>;
    };
}

const DivStiled = styled.div`
    position: "relative"
    > * {
        margin-top: 0.25rem;
    }
`;
