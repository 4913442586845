import { DocumentListItem } from "./DocumentListItem";
import DocumentPopup from "../DocumentView/Popup/DocumentPopup";
import { DocumentFilterDefault, DocumentFilterFromSearch, DocumentFilterToSearch, IDocumentFilter } from "../FilterAndOrder/IDocumentFilter";
import { DocumentFilterPopup } from "../FilterAndOrder/DocumentFilterPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentOrderPopup } from "../FilterAndOrder/DocumentOrderPopup";
import IDocumentOrder, { DocumentOrderDefault, DocumentOrderFromSearch, DocumentOrderToSearch } from "../FilterAndOrder/IDocumentOrder";
import Icon from "@mdi/react";
import { mdiFormatListText, mdiLocationEnter, mdiLocationExit, mdiPlusThick } from "@mdi/js";
import DocumentService from "../../../services/DocumentService";
import { TextFilterDisplay } from "../../../utils/List/TextFilterDisplay";
import { ListView, TListViewProps } from "../../../utils/List/ListView";
import { v4 } from "uuid";
import IDocumentInfo from "../../../realm/schemas/Document/IDocument";
import { useLocation } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../utils/CustomButton";
import PathGroupsService from "../../../services/PathGroupsService";
import CreateDocumentPopup from "../CreateDocument";
import { DisplaySpace } from "./DisplaySpace";
import Localized from "../../../Localization/Localized";

export const DocumentListPage = () => {
    const search = useLocation().search;
    
    let filter: IDocumentFilter | undefined = undefined;
    let order: IDocumentOrder | undefined = undefined;

    if(search.length > 0) {
        const params = new URLSearchParams(search);
        filter = DocumentFilterFromSearch(params);
        order = DocumentOrderFromSearch(params);
    }

    return <>
        <DocumentList filter={filter} order={order} isFullScreen isPage />
    </>
}

interface IDocumentListProps extends TListViewProps<IDocumentFilter, IDocumentOrder> {
    currentPath?: string;
    loadHandler?: (loadHandle: () => Promise<void>) => void;
    context?: string;
}

export class DocumentList extends ListView<IDocumentInfo, IDocumentFilter, IDocumentOrder, IDocumentListProps> {
    public static PageTitle = () => <><Icon path={mdiFormatListText} size="1em" /> {Localized.DocumentListView.Title}</>;

    get Title(): { simple: string; rich: JSX.Element; } {
        let path = "";
        if(this.state.filter.path && this.state.filter.path.filter) {
            const splited = this.state.filter.path.filter.split("|");
            if(splited.length > 1) {
                path = splited.reverse().join(" | ");
            } else {
                path = this.state.filter.path.filter;
            }
        }

        return {
            simple: path ?? Localized.DocumentListView.Title,
            rich: DocumentList.PageTitle()
        }
    }

    constructor(props: Readonly<IDocumentListProps>) {
        super(props);

        if(props.loadHandler) {
            props.loadHandler(async () => {
                await this.load();
            });
        }

        if(this.props.isPage) PathGroupsService.LoadGroup();
    }

    stateKey(): string {
        return `DL_${this.props.context}_${this.props.currentPath}`;
    }

    defaultOrder(): IDocumentOrder {
        return DocumentOrderDefault();
    }
    defaultFilter(): IDocumentFilter {
        const d = DocumentFilterDefault();
        d.isPublicOnly = true;
        return d;
    }
    getFullScreenElement(exit: () => void): JSX.Element {
        return <DocumentList filter={this.state.filter} order={this.state.order} isFullScreen exitFullScreen={exit} currentPath={this.props.currentPath} title={this.props.title} context={this.props.context} />
    }

    getItems = async () => {
        const results = await DocumentService.All(this.state.filter, this.state.order);
        return results;
    }

    open = async (documentInfo:IDocumentInfo) => {
        await new DocumentPopup(documentInfo).open();

        this.load();
    }

    getItemElement(item: IDocumentInfo): JSX.Element {
        return <DocumentListItem key={item._id.toHexString()} document={item}
        onTitleClick={() => this.open(item)} currentPath={this.props.currentPath} context={this.props.context} />;
    }

    goTo = (replace: boolean = false) => {
        const params = Localized.GetQueryString();
        DocumentFilterToSearch(this.state.filter, params);
        DocumentOrderToSearch(this.state.order, params);

        let qs = params.toString();
        if(qs && qs.length > 0) qs = `?${qs}`;
        else qs = "";

        window.open(`http://${window.location.host}/Documents/List${qs}`, replace ? "_self" : "_blank");
    }

    createDocument = async () => {
        const result = await CreateDocumentPopup.open(this.state.filter.path, this.state.filter.spaceId);

        if(result) this.reload();
    }

    getCustomeActions(): JSX.Element[] {
        const actions = [
            <DocumentOrderPopup key={v4()} order={this.state.order} orderChanged={this.orderChanged} />,
            <DocumentFilterPopup key={v4()} filter={this.state.filter} filterChanged={this.filterChanged} />,
            <SecondaryButton key={v4()} maIcon={this.props.isPage ? mdiLocationEnter : mdiLocationExit} link onClick={() => this.goTo(this.props.isPage)} />
        ];

        if(this.props.isPage) actions.unshift(<PrimaryButton key={v4()} maIcon={mdiPlusThick} onClick={this.createDocument} />);

        return actions;
    }

    //mdiArrowLeftTop

    getDisplayFilterElements(): JSX.Element[] {
        const showTitleFilter = this.state.filter?.text && this.state.filter?.text.filter && this.state.filter?.text.filter.length > 0;
        const showPathFilter = this.state.filter?.path && this.state.filter?.path.filter && this.state.filter?.path.filter.length > 0;
        const startDateFilter = this.state.filter?.startDate;
        const endDateFilter = this.state.filter?.endDate;
        const isPublicOnlyFilter = this.state.filter?.isPublicOnly;
        const isPrivateOnlyFilter = this.state.filter?.isPrivateOnly;

        const filterElements: JSX.Element[] = [];
        if(this.state.filter.spaceId) filterElements.push(<span key={v4()}>{Localized.General.Space} [ <DisplaySpace spaceId={this.state.filter.spaceId} /> ]</span>);
        if(startDateFilter) filterElements.push(<span key={v4()}>{Localized.General.From} {startDateFilter.toFormat("yyyy-MM-dd HH:mm:ss")}</span>);
        if(endDateFilter) filterElements.push(<span key={v4()}>{Localized.General.To} {endDateFilter.toFormat("yyyy-MM-dd HH:mm:ss")}</span>);
        if(showTitleFilter) filterElements.push(<TextFilterDisplay key={v4()} label={Localized.General.Title} filter={this.state.filter?.text} />);
        if(showPathFilter) filterElements.push(<TextFilterDisplay key={v4()} label={Localized.General.Path} filter={this.state.filter?.path} />);
        if(isPublicOnlyFilter) filterElements.push(<FontAwesomeIcon key={v4()} icon="earth" title={Localized.DocumentListView.TooltipPublished} />);
        if(isPrivateOnlyFilter) filterElements.push(<FontAwesomeIcon key={v4()} icon="shield" title={Localized.DocumentListView.TooltipPersonnal} />);

        return filterElements;
    }
}
