import styled from "@emotion/styled";
import { mdiSortVariant, mdiSortReverseVariant } from "@mdi/js";
import React from "react";
import Localized from "../../Localization/Localized";
import { SecondaryButton } from "../CustomButton";
import { TooltipItem, TooltipPopup } from "../TooltipPopup";
import { ISort } from "./Order";

type TOrderPopupContentProps<TOrder extends ISort> = {
    order?: TOrder;
    apply: (order: TOrder) => void;
}

export interface TOrderPopupContentState<TOrder extends ISort> {
    order?: TOrder;
}

export abstract class OrderPopupContent<TOrder extends ISort, TState extends TOrderPopupContentState<TOrder>> extends React.Component<TOrderPopupContentProps<TOrder>, TState> {
    constructor(props: TOrderPopupContentProps<TOrder> | Readonly<TOrderPopupContentProps<TOrder>>) {
        super(props);

        this.state = this.initState();
    }

    abstract initState(): TState;

    abstract DefaultOrder(): TOrder;

    toggleDirection = (code: string) => {
        if(!this.state.order) return;
        this.setState(state => {
            const order = state.order;

            if(order) {
                const o = order.fields.find(f => f.label === code);

                if(o?.direction === "A") o.direction = "D"
                else if(o?.direction === "D") o.direction = "N/A"
                else if(o?.direction === "N/A") o.direction = "A"
            }

            return {
                order: order
            }
        }, this.apply);
    }

    levelUp = (index: number) => {
        if(!this.state.order) return;
        const order = this.state.order ?? this.DefaultOrder();
    
        const o = order.fields.splice(index, 1);

        order.fields.splice(index - 1, 0, o[0]);

        this.setState({
            order: undefined
        }, () => {
            this.setState({
                order: order
            }, this.apply);
        });
    }

    apply = () => {
        if(!this.state.order) return;
        this.props.apply(this.state.order as any);
    }

    abstract customItems(): JSX.Element[];

    render = () => {
        return <div style={{
            minWidth: "15em"
        }}>
            <TooltipPopup>
            {this.state.order?.fields.map((f, i) => <TooltipItemStyled key={f.label} noCursor>
                {f.label}
                <span>
                    <span onClick={() => this.toggleDirection(f.label)}>
                        {f.direction === "D" && <SecondaryButton maIcon={mdiSortVariant} />}
                        {f.direction === "A" && <SecondaryButton maIcon={mdiSortReverseVariant} />}
                    </span>
                    <span style={{
                        marginLeft: "0.25rem"
                    }}>
                        {f.direction === "N/A" && <SecondaryButton onClick={() => this.toggleDirection(f.label)}>{Localized.General.NA}</SecondaryButton>}
                        {f.direction !== "N/A" && i > 0 && <SecondaryButton outlined faIcon="level-up-alt" onClick={() => this.levelUp(i)} />}
                    </span>
                </span>
            </TooltipItemStyled>)}
            {this.customItems().map((c, i) => <TooltipItemStyled key={i}>{c}</TooltipItemStyled>)}
        </TooltipPopup>
        </div>
    }
}

const TooltipItemStyled = styled(TooltipItem)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;