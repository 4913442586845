import styled from "@emotion/styled";
import Constants from "../../../Constants";
import IDocumentInfo from "../../../realm/schemas/Document/IDocument";
import { BgPath, ExplorerDivBG } from "../../../styles/Explorer";
import { EllipsisSpanStyled, RFSNDivStyled } from "../../../styles/ListStyled";
import SectionContainer from "../../../styles/Section";
import UserAndTimeInfos from "./UserAndTimeInfos";
import _ from "lodash";

type TDocumentProps = {
    document: IDocumentInfo;
    onTitleClick?: () => void;
    currentPath?: string;
    context?: string;
}

export const DocumentListItem = (props: TDocumentProps) => {
    let formatedPath: string | undefined = Array.isArray(props.document.publicPath) ? props.document.publicPath[0] : props.document.publicPath;

    if(!formatedPath) formatedPath = Array.isArray(props.document.path) ? props.document.path[0] : props.document.path;

    let nbPath = 0;
    if(Array.isArray(props.document.publicPath)) nbPath = props.document.publicPath.length;
    else if(props.document.publicPath) nbPath = 1;

    if(Array.isArray(props.document.path)) nbPath += props.document.path.length;
    else if(props.document.path) nbPath += 1;

    const hasPath = formatedPath && formatedPath.length > 0;

    const onTitleClick = () => {
        if(props.onTitleClick) props.onTitleClick();
    }

    const openPaths = () => {
        
    }

    let paths: string[] = props.document.publicPath ? (Array.isArray(props.document.publicPath) ? props.document.publicPath : [ props.document.publicPath ]) : [];

    let ppaths: string[] = props.document.path ? (Array.isArray(props.document.path) ? props.document.path : [ props.document.path ]) : [];

    paths = _.union(paths, ppaths)

    return <DocumentContainer>
        <ExplorerDivBG>
            {paths.filter(f => props.currentPath ? f !== props.currentPath : true).map(m => <BgPath key={m} path={m} withSpace={false} />)}
        </ExplorerDivBG>
        <TitleStyled onClick={props.onTitleClick ? onTitleClick : undefined} >{props.document.publicTitle ? props.document.publicTitle : props.document.title}</TitleStyled>
        <KeysStyled>
            {props.document.keys && props.document.keys.map(k => <div key={k}>{k}</div>)}
        </KeysStyled>
        <DocumentDetail>
            {hasPath && <>
                <DocumentPath style={{
                    cursor: "pointer"
                }} onClick={openPaths}>
                    <EllipsisSpanStyled title={formatedPath}>{formatedPath}</EllipsisSpanStyled>
                    {nbPath > 1 && <span> ({nbPath})</span>}
                </DocumentPath>
            </>}
            <DocumentDetailInfo>
                <UserAndTimeInfos 
                    userId={props.document.modifiedById} 
                    dateTimeUTC={props.document.modifiedDateTimeUTC} />
            </DocumentDetailInfo>
        </DocumentDetail>
    </DocumentContainer>
}

const KeysStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 0.95em;
    > div:not(:first-of-type){
        margin-left: 0.25rem;
        ::before {
            content: "—";
            margin-right: 0.25rem;
        }
    }
`;

const TitleStyled = styled.div`
    position: relative;
    cursor: pointer;
    font-size: 1.25em;
`;

const DocumentDetailInfo = styled(RFSNDivStyled)`
    opacity: ${Constants.InfoDefaultOpacity};
    :hover {
        opacity: 1;
    }
`;

const DocumentPath = styled(DocumentDetailInfo)`
    font-style: italic;
`;

const DocumentDetail = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;

    > div:not(:first-of-type){
        margin-left: 0.25rem;
    }
`;

const DocumentContainer = styled(SectionContainer)`
    position: relative;
    padding-left: 0.5rem;
`;