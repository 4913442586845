import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCommands } from "@remirror/react";
import { useState } from "react";
import { ButtonGroup, InputGroup } from "reactstrap";
import { ImageAttributes } from "remirror/extensions";
import { TooltipPopup } from "../../../../../utils/TooltipPopup";
import { Dialog } from "../../../../../utils/dialog/DialogManager";
import { ActionButton } from "../ActionButton";
import { SecondaryButton } from "../../../../../utils/CustomButton";
import { CustomInput } from "../../../../../utils/CustomInput";

const OpenImageSelector = (cmd: any, evt: React.MouseEvent<HTMLButtonElement>) => {
    // let dismiss: () => void = () => { };
    // const dispatcher = (resize: () => void) => {
    //     dismiss = resize;
    // };

    const insert = (base64: string) => {

        const ia:ImageAttributes = {
            src : base64,
        }

        ia.height = "100px";
        ia.align = "right"

        cmd.insertImage(ia);
    }


    Dialog.tooltip(<ImagePopup insert={insert} />, {
        handle: evt.currentTarget//,
        //dispatchDismiss: dispatcher
    });
}

export const AddImage = () => {
    const commands = useCommands();
    
    return <ButtonGroup size="sm">
            <ActionButton enabled onClick={(evt) => OpenImageSelector(commands, evt)}><FontAwesomeIcon icon="image" /></ActionButton>
        </ButtonGroup>
}

type TImagePopupProps = {
    insert: (base64: string) => void
}

const ImagePopup:React.FC<TImagePopupProps> = (props: TImagePopupProps) => {
    const [ base64, setBase64 ] = useState("");

    const disabled = () => {
        if(!base64 || base64.length === 0) return true;
        return false;
    }

    return <ImageTooltip>
        <div>Insert an image in Base64</div>
        <InputGroup size="sm">
            <CustomInput value={base64} onChange={(evt) => setBase64(evt.currentTarget.value)} placeholder="Base64" />
        </InputGroup>
        <pre>See <a href="https://www.base64-image.de" target="_blank" rel="noreferrer">www.base64-image.de</a></pre>
        <img src={base64} style={{maxHeight: "100px", maxWidth: "100px"}} alt="" />
        <div><SecondaryButton onClick={() => props.insert(base64)} disabled={disabled()} >Add</SecondaryButton></div>
</ImageTooltip>
}

const ImageTooltip = styled(TooltipPopup)`
    padding: 0.25rem;
`;