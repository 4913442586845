import { IconName } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import ColorService from "../../../../../services/ColorService";
import Colors, { ColorSections } from "../../../../../utils/Color/MainColors";
import { ActionButton } from "../ActionButton";
import { ColorTabs } from "../../../../../utils/Color/ColorTabs";
import { RGBColor } from "react-color";
import { Dialog } from "../../../../../utils/dialog/DialogManager";
import { useActive, useCommands } from "@remirror/react";
import { ButtonGroup } from "reactstrap";

const OpenFontColorSelector = (evt: React.MouseEvent<HTMLButtonElement>) => {
    let dispatchResize: () => void = () => { };
    const dispatcher = (resize: () => void) => {
        dispatchResize = resize;
    };

    const colorChange = (color: RGBColor) => {
        ColorService.CurrentTextColor = color;
    }

    Dialog.tooltip(<ColorTabs color={ColorService.CurrentTextColor} colorChange={colorChange} tabChange={dispatchResize} />, {
        handle: evt.currentTarget,
        dispatchRePosition: dispatcher
    });
}

const OpenFontHighlighterSelector = (evt: React.MouseEvent<HTMLButtonElement>) => {
    let dispatchResize: () => void = () => { };
    const dispatcher = (resize: () => void) => {
        dispatchResize = resize;
    };

    const colorChange = (color: RGBColor) => {
        ColorService.CurrentHighlighterColor = color;
    }

    Dialog.tooltip(<ColorTabs color={ColorService.CurrentHighlighterColor} colorChange={colorChange} tabChange={dispatchResize} />, {
        handle: evt.currentTarget,
        dispatchRePosition: dispatcher
    });
}

export const AddMark = () => {
    const commands = useCommands();
    const active = useActive(true);
    
    const getTColor = () => Colors.toRGBAStringColor(ColorService.CurrentTextColor);
    const tKey = ColorService.TextColorChangedKey;

    const getHColor = () => Colors.toRGBAStringColor(ColorService.CurrentHighlighterColor);
    const hKey = ColorService.HighlighterColorChangedKey;
    return <>
        <ButtonGroup size="sm">
            <ActionButton active={active.bold()} enabled={commands.toggleBold.enabled()} onClick={() => commands.toggleBold()}><FontAwesomeIcon icon="bold" /></ActionButton>
            <ActionButton active={active.italic()} enabled={commands.toggleItalic.enabled()} onClick={() => commands.toggleItalic()}><FontAwesomeIcon icon="italic" /></ActionButton>
            <ActionButton active={active.underline()} enabled={commands.toggleUnderline.enabled()} onClick={() => commands.toggleUnderline()}><FontAwesomeIcon icon="underline" /></ActionButton>
            <ActionButton active={active.strike()} enabled={commands.toggleStrike.enabled()} onClick={() => commands.toggleStrike()}><FontAwesomeIcon icon="strikethrough" /></ActionButton>
        </ButtonGroup>
        <ButtonGroup size="sm">
            <ActionButton active={active.sub()} enabled={commands.toggleSubscript.enabled()} onClick={() => commands.toggleSubscript()}><FontAwesomeIcon icon="subscript" /></ActionButton>
            <ActionButton active={active.sup()} enabled={commands.toggleSuperscript.enabled()} onClick={() => commands.toggleSuperscript()}><FontAwesomeIcon icon="superscript" /></ActionButton>
        </ButtonGroup>
        <ButtonGroup size="sm">
            <ActionButton enabled onClick={() => commands.setTextColor(getTColor())}><ColorButton icon="tint" getColor={getTColor} changeKey={tKey} /></ActionButton>
            <ActionButton enabled onClick={() => commands.removeTextColor()}>
                <span className="fa-layers fa-fw">
                    <ColorButton icon="tint" getColor={getTColor}  changeKey={tKey} />
                    <FontAwesomeIcon icon="slash" style={{ color: Colors.get(ColorSections.Text)}} />
                </span>
            </ActionButton>
            <ActionButton enabled onClick={(evt) => OpenFontColorSelector(evt)}><FontAwesomeIcon icon="angle-down" /></ActionButton>
        </ButtonGroup>
        <ButtonGroup size="sm">
            <ActionButton enabled onClick={() => commands.setTextColor(getHColor())}><ColorButton icon="highlighter" getColor={getHColor} changeKey={hKey} /></ActionButton>
            <ActionButton enabled onClick={() => commands.removeTextHighlight()}>
                <span className="fa-layers fa-fw">
                    <ColorButton icon="highlighter" getColor={getHColor}  changeKey={hKey} />
                    <FontAwesomeIcon icon="slash" style={{ color: Colors.get(ColorSections.Text)}} />
                </span>
            </ActionButton>
            <ActionButton enabled onClick={(evt) => OpenFontHighlighterSelector(evt)}><FontAwesomeIcon icon="angle-down" /></ActionButton>
        </ButtonGroup>
    </>
}

type TColorButtonProps = {
    icon: IconName;
    getColor: () => string;
    changeKey: string;
}

const ColorButton:React.FC<TColorButtonProps> = (props:TColorButtonProps) => {
    const [ color, setColor] = useState(props.getColor());

    useEffect(() => {
        const guid = v4();
        ColorService.subscribeChange(`EditorButton${guid}`, (f, v) => {
            if(f === props.changeKey) {
                setColor(props.getColor());
            }
        });
        return () => {
            ColorService.unsubscribeChange(`EditorButton${guid}`);
        }
    });

    return <FontAwesomeIcon icon={["fas", props.icon ]} style={{ color: color}} />
}