import styled from "@emotion/styled";
import { CSSProperties } from "react";
import Constants from "../Constants";
import PathGroupsService from "../services/PathGroupsService";
import Colors from "../utils/Color/MainColors";

export const ExplorerHeaderDiv = styled.div`
    position: relative;
    width: 100%;
`;

export const ExplorerHeaderAction = styled.div`
    display: inline-flex;
    > * {
        opacity: ${Constants.ActionDefaultOpacity};
        cursor: pointer;
        margin-left: 0.125rem;

        :hover {
            opacity: 1;
        }
    }

    .input-group-text, .btn {
        font-size: 0.75em !important;
        padding: 0.0625rem 0.30rem !important;
    }
`;

type TExplorerDivProps = {
    active: boolean;
}

export const ExplorerDivBG = styled.div`
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
`;

export const ExplorerDivDisplay = styled.div<TExplorerDivProps>`
position: relative;
    > label {
        background-color: rgba(255,255,255,0.0625);
        margin-bottom: 0;
        display: block;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
    }
    .file-title > title {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .file-title:hover {
        background-color: rgba(255,255,255,0.125);
    }

    .file-title {
        cursor: pointer;
        background-color: ${p => p.active ? "rgba(255,255,255, 0.6)" : "transparent"};
    }

    > div {
        margin-left: 1.125em;
    }
`;

type TBgPathProps = {
    path: string;
    withSpace?: boolean;
}

export const BgPath:React.FC<TBgPathProps> = (props: TBgPathProps) => {
    let g = PathGroupsService.GetGroup(props.path, props.withSpace);

    let style:CSSProperties | undefined = undefined;
    if(g && g.bgColor && (g.bgColor.a ?? 0) > 0) {
        style = g ? {
            backgroundColor: g.bgColor ? Colors.toRGBAStringColor(g.bgColor, 0.5) : undefined
        } : undefined;

        return <BGTuile style={style} />
    }

    return <></>;
}

const BGTuile = styled.div`
    width: 100%;
`; 

export const ExplorerItemActions = styled.span`
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;

    > * {
        opacity: ${Constants.ActionDefaultOpacity};
        margin-left: 0.25rem;

        :hover {
            opacity: 1;
        }
    }

`;