import Localized from "../../../../Localization/Localized";
import IDocumentInfo from "../../../../realm/schemas/Document/IDocument";
import { Dialog } from "../../../../utils/dialog/DialogManager";
import { DialogSize } from "../../../../utils/dialog/enums/DialogSize";
import { DocumentInfosView } from "../DocumentInfosView";
import DocumentPopupBody from "./DocumentPopupBody";
import { DocumentPopupControlBar } from "./DocumentPopupControlBar";

export default class DocumentPopup {
    private _documentInfo: IDocumentInfo;
    private _fontSize = 1;
    private _fontSizeHandle: (fontSize: number) => void = () => {};
    private _closeHandle: () => void = () => {};
    private _parentNeedReload?: boolean;
    private _removed?: boolean;
    constructor(documentInfo: IDocumentInfo) {
        this._documentInfo = documentInfo;
    }

    onClose = () => {
        this._closeHandle();
    }

    fontSizeDecrease = () => {
        this._fontSize -= 0.1;
        this._fontSizeHandle(this._fontSize);
        return this._fontSize;
    }

    fontSizeIncrease = () => {
        this._fontSize += 0.1;
        this._fontSizeHandle(this._fontSize);
        return this._fontSize;
    }

    toogleInfo = async () => {
        await Dialog.config(Localized.Document.DocInfoTitle, <DocumentInfosView document={this._documentInfo} onChange={() => {
            this._parentNeedReload = true;
        } } onRemove={() => {
            this._parentNeedReload = true;
            this._removed = true;
        }} />,  {
            size: DialogSize.L
        });

        if(this._removed){
            this.onClose();
        }
    }

    open = async () => {
        await Dialog.free(<>
            <span style={{
                minWidth: 0,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
            }}>{this._documentInfo.publicTitle ? this._documentInfo.publicTitle : this._documentInfo.title}</span>
            <DocumentPopupControlBar fontSize={this._fontSize} onClose={this.onClose}
                toggleInfo={this.toogleInfo}
                fontSizeDecrease={this.fontSizeDecrease}
                fontSizeIncrease={this.fontSizeIncrease}
                documentId={this._documentInfo._id} />
        </>, 
        <DocumentPopupBody documentId={this._documentInfo._id} fontSize={this._fontSize} fontSizeHandler={(handle) => {
            this._fontSizeHandle = handle;
        }} />, {
            size: DialogSize.Max,
            dispatchDismiss: (handle) => {
                this._closeHandle = handle;
            },
            hideHeaderButton: true,
            headerStyle: {
                fontSize: "1em"
            }
        });

        if(this._parentNeedReload) return true;

        return false;
    }
}
