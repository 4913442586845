import Observer from "../utils/Observer";

export default class ApplicationService extends Observer {
    private static readonly _instance: ApplicationService = new ApplicationService();
    public static get Instance() {
        return this._instance;
    }

    static Container: HTMLElement | null;

    static subscribeChange(eventKey: string, key: string, callBack: (field?: string, value?: any) => void): void {
        this.Instance.subscribeChange(eventKey, key, callBack);
    }
    
    static unsubscribeChange(eventKey: string, key: string): void {
        this.Instance.unsubscribeChange(eventKey, key);
    }
}