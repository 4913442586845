import { mdiSortReverseVariant, mdiSortVariant, mdiReload, mdiFullscreenExit, mdiFullscreen, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { DateTime } from "luxon";
import moment from "moment";
import React, { CSSProperties } from "react";
import { ButtonGroup } from "reactstrap";
import { OnSizeOnly } from "../../components/ResponsiveFormPage";
import Localized from "../../Localization/Localized";
import GlobalStateService from "../../services/GlobalStateService";
import HeaderService from "../../services/HeaderService";
import { ListHeaderStyled, ListFilterStyled, ListFiltredItem, ListActions } from "../../styles/ListStyled";
import Colors, { ColorSections } from "../Color/MainColors";
import { SecondaryButton } from "../CustomButton";
import { Dialog } from "../dialog/DialogManager";
import { DialogSize } from "../dialog/enums/DialogSize";
import Loading, { LoadingDiv } from "../Loading";
import { IFilter } from "./Filter";
import { ISort } from "./Order";

export type TListViewProps<TFilter extends IFilter, TOrder extends ISort> = {
    title?: string;
    filter?: TFilter;
    itemCount?: (count: number) => void;
    hideHeader?: boolean;
    order?: TOrder;
    exitFullScreen?: () => void;
    isFullScreen?: boolean;
    maxItemsHeight?: string;
    filterAndOrderHandler?: (handle:() => [TFilter, TOrder]) => void;
    customActions?: () => JSX.Element[];
    context?: string;
    isPage?: boolean;
}

type TListViewState<TItem, TFilter extends IFilter, TOrder extends ISort> = {
    items: TItem[];
    isLoading: boolean;
    filter: TFilter;
    order: TOrder;
    isReloading?: boolean;
    lastLoaded?: DateTime;
    fullScreen?: boolean;
    lastLoadedDisplay?: string;
    width: string | number;
}

export abstract class ListView<TItem, TFilter extends IFilter, TOrder extends ISort, TProp extends TListViewProps<TFilter, TOrder>> extends React.Component<TProp, TListViewState<TItem, TFilter, TOrder>> {
    private _lastLoadedProcessorHandle: any;
    private _widthSetted: boolean = false;
    constructor(props: Readonly<TProp>) {
        super(props);
        const sd = GlobalStateService.Get(`${this.stateKey}_filter`);
        const d = sd != null ? sd : (this.props.filter ?? this.defaultFilter());

        const so = GlobalStateService.Get(`${this.stateKey}_order`);
        const o = so != null ? so : (this.props.order ?? this.defaultOrder());

        this.state = {
            items: [],
            isLoading: true,
            filter: d,
            order:  o,
            width: "100%"
        };

        if(props.filterAndOrderHandler) props.filterAndOrderHandler(() => {
            return [
                this.state.filter, 
                this.state.order
            ]
        })
    }

    abstract get Title(): { simple: string, rich: JSX.Element };

    abstract stateKey(): string;

    abstract defaultOrder(): TOrder;

    abstract defaultFilter(): TFilter;

    componentDidMount = () => {
        this._widthSetted = false;
        const t = this.Title;
        if(this.props.isPage) HeaderService.setTitle(t.simple, t.rich);
        this.load();
    };

    componentWillUnmount = () => {
        if(this._lastLoadedProcessorHandle) clearInterval(this._lastLoadedProcessorHandle);
    }

    abstract getItems(): Promise<TItem[]>;

    protected load = async () => {
        const results = await this.getItems();

        this.setState({
            isLoading: false,
            isReloading: false,
            items: results,
            lastLoaded: DateTime.now(),
            lastLoadedDisplay: moment().fromNow()
        }, () => {
            if(this.props.itemCount) this.props.itemCount(results.length); 
        });
    };

    filterChanged = (filter: TFilter) => {
        this.setState({
            filter: filter
        }, () => {
            GlobalStateService.Set(`${this.stateKey}_filter`, this.state.filter);
            this.load();
        });
    }

    orderChanged = (order: TOrder) => {
        this.setState({
            order: order
        }, () => {
            GlobalStateService.Set(`${this.stateKey}_order`, this.state.order);
            this.load();
        });
    }

    reload = () => {
        this.setState({
            isReloading: true
        }, this.load);
    }

    toggleFullScreen = async () => {
        if(this.props.isFullScreen && this.props.exitFullScreen) {
            this.props.exitFullScreen();
            return;
        }

        this.setState({
            fullScreen: true
        }, this.openFullScreen);
    }

    abstract getFullScreenElement(exit: () => void) : JSX.Element;

    protected openFullScreen = async () => {
        let closeHandle: () => void = () => {};

        const exit = () => {
            closeHandle();
        }

        await Dialog.free(null, this.getFullScreenElement(exit), {
            size: DialogSize.FullScreen,
            dispatchDismiss: (handle) => {
                closeHandle = handle;
            },
            hideHeaderButton: true,
            windowStyle: {
                backgroundColor: Colors.get(ColorSections.BG),
                width: "100vw",
                height: "100vh",
                color: Colors.get(ColorSections.Text)
            }
        });

        this.setState({
            fullScreen: false
        });
    }

    abstract getDisplayFilterElements(): JSX.Element[];

    abstract getCustomeActions(): JSX.Element[];

    abstract getItemElement(item: TItem): JSX.Element;

    lastLoadedProcessor = () => {
        if(this._lastLoadedProcessorHandle) clearInterval(this._lastLoadedProcessorHandle);

        this._lastLoadedProcessorHandle = setInterval(() => {
            this.setState(state => {
                return {
                    lastLoadedDisplay: moment(state.lastLoaded?.toJSDate()).fromNow()
                }
            });
        }, 60 * 1000);
    }

    next = () => {
        const filter = this.state.filter;        
        filter.skip = (filter?.skip ?? 0) + (this.state.filter?.limit ?? 0);
        this.filterChanged(filter);
    }

    precedent = () => {
        const filter = this.state.filter;
            
        filter.skip = (filter.skip ?? 0) - (this.state.filter.limit ?? 0);
        if(filter.skip < 0) filter.skip = 0;
        this.filterChanged(filter);
    }

    onHeaderRefSetted = (element: HTMLElement | null) => {
        if(element == null || this.props.isFullScreen || this._widthSetted) return;

        this._widthSetted = true;
        const parent = $(element).closest(".u-container");
        
        const w = parent.width();

        if(w) {
            this.setState({
                width: w
            });
        }
    }

    render = () => {
        this.lastLoadedProcessor();
        const filters = this.getDisplayFilterElements();
        const filterOn = filters.length > 0;
        const order = this.state.order?.fields.filter(f => f.direction !== "N/A") ?? [];
        const orderOn = order.length > 0;

        const headerStyle: CSSProperties | undefined = this.props.isFullScreen ? {
            position: "fixed",
            zIndex: "1",
            width: "100%",
            backgroundColor: Colors.get(ColorSections.BG)
        } : {
            maxWidth: this.state.width
        };

        const listStyle: CSSProperties = {
            position: "relative",
            maxHeight: this.props.maxItemsHeight ? this.props.maxItemsHeight : undefined,
            overflow: this.props.maxItemsHeight ? "scroll" : undefined,
            maxWidth: this.state.width
        };

        if(this.props.isFullScreen) listStyle.paddingTop = "2em";

        const firstNumber = (this.state.filter?.skip ?? 0) + 1;

        const baseMinSize = this.props.title ? 80 : 60;

        return <div style={{position: "relative"}}>
            {this.state.isLoading && <LoadingDiv style={{
                fontSize: "2em"
            }}><Loading /></LoadingDiv>}
            {!this.state.isLoading && <>
                {!this.props.hideHeader && <ListHeaderStyled style={headerStyle} ref={(el) => this.onHeaderRefSetted(el)}>
                    {this.props.title && <title>{this.props.title}</title>}
                    {!this.props.title && <span />}
                    <OnSizeOnly minWidth={`${baseMinSize}em`}>
                        <ListFilterStyled>
                            <OnSizeOnly minWidth={`${baseMinSize + 15}em`}>
                                {this.state.lastLoaded && <span>{Localized.General.Loaded} : {this.state.lastLoadedDisplay}</span>}
                            </OnSizeOnly>
                            <OnSizeOnly minWidth={`${baseMinSize + 5}em`}>
                                {orderOn && <ListFiltredItem>
                                    <span>{Localized.General.Ordered} :</span>
                                    {order.map(o => <span key={o.label}>
                                        {o.label}&nbsp;
                                        {o.direction === "A" && <Icon path={mdiSortReverseVariant} size="1.5em" />}
                                        {o.direction === "D" && <Icon path={mdiSortVariant} size="1.5em" />}
                                    </span>)}
                                </ListFiltredItem>}
                            </OnSizeOnly>
                            {filterOn && <ListFiltredItem>
                                <span>{Localized.General.Filtred} :</span>
                                {this.getDisplayFilterElements()}
                            </ListFiltredItem>}
                        </ListFilterStyled>
                    </OnSizeOnly>
                    <ListActions style={{ marginLeft: "0.5rem"}}>
                        <ButtonGroup>
                            {(this.state.filter?.skip ?? 0) > 0 && <SecondaryButton maIcon={mdiChevronLeft} link onClick={this.precedent} />}
                            <SecondaryButton link disabled >[{firstNumber}..{this.state.items.length + firstNumber - 1}]</SecondaryButton>
                            {this.state.items.length >= (this.state.filter?.limit ?? 0) && <SecondaryButton maIcon={mdiChevronRight} link onClick={this.next} />}
                        </ButtonGroup>
                        {this.props.customActions && <ButtonGroup>
                            {this.props.customActions().map(a => a)}
                        </ButtonGroup>}
                        <ButtonGroup>
                            {this.getCustomeActions().map(a => a)}
                        </ButtonGroup>
                        <ButtonGroup>
                            <SecondaryButton maIcon={mdiReload} link onClick={this.reload} iconSpin={this.state.isReloading} />
                            <SecondaryButton maIcon={this.props.isFullScreen ? mdiFullscreenExit : mdiFullscreen} link onClick={this.toggleFullScreen} />
                        </ButtonGroup>
                    </ListActions>
                </ListHeaderStyled>}
                {!this.state.fullScreen && <div style={listStyle} className="u-container">
                    {this.state.items.map(d => this.getItemElement(d))}
                </div>}
            </>}
        </div>;
    };
}