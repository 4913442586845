import { DateTime } from "luxon";

export default class DateHelper {
    static get momentDateTimeFormatLong(): string {
        return "YYYY-MM-DD HH:mm:ss";
    }

    static get dateTimeFormatLong(): string {
        return "yyyy-MM-dd HH:mm:ss.SSS ZZZZ";
    }

    static get dateTimeFormatLongWithoutTimezone(): string {
        return "yyyy-MM-dd HH:mm:ss.SSS";
    }

    static get dateTimeFormatShort(): string {
        return "yyyy-MM-dd HH:mm";
    }

    static toShort = (datetime: DateTime) => {
        if(datetime.toFormat("yyyy-MM-dd") === DateTime.now().toFormat("yyyy-MM-dd")) {
            return datetime.toFormat("HH:mm:ss");
        }

        return datetime.toFormat("yyyy-MM-dd HH:mm:ss");
    }
}