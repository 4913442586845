import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Localized from "../../Localization/Localized";
import { TooltipSeparator } from "../../utils/Separator";
import { TooltipItem, TooltipPopup } from "../../utils/TooltipPopup";
import ColorsProfile from "../ColorsProfile";
import ProfilePage from "../profile/ProfilePage";

type TProfileMenuProps = {
    signOut: () => void;
    goTo: (path: string) => void;
}

const ProfileMenu: React.FC<TProfileMenuProps> = (props: TProfileMenuProps) => {
    const toProfile = () => {
        props.goTo("/Profile");
    }

    const toColorsProfile = () => {
        props.goTo("/ColorsProfile");
    }

    return <TooltipPopup>
        <TooltipItem onClick={toProfile}>{ProfilePage.Title()}</TooltipItem>
        <TooltipItem onClick={toColorsProfile}>{ColorsProfile.PageTitle()}</TooltipItem>
        <TooltipSeparator />
        <TooltipItem onClick={props.signOut}>{Localized.LoginView.SignOut} <FontAwesomeIcon icon="sign-out-alt" /></TooltipItem>
    </TooltipPopup>
}

export default ProfileMenu;