export default class Constants {
    static readonly FooterHeight = "1.65em";
    static readonly HeaderHeight = "3.5em";
    static readonly ProfileIDPathKey = "ProfileID";
    static readonly SpaceIDPathKey = "SpaceID";
    static readonly ActionDefaultOpacity = 0.25;
    static readonly InfoDefaultOpacity = 0.5;
    static readonly CacheTimoutInSec = 300;
}

/*
□ △ ▷ ▽ ◁ ◸ ◹ ◺ ◿ ◬ ◊ ◇ ○ ◎
十	百	千   	万
亿 100 million
𐄇 = 1, 𐄐 = 10, 𐄙 = 100, 𐄢 = 1000, and 𐄫 = 10000
10 : ٣ 100 : ٤ 1000 : ٥ 10 000: 100 000 : ٦ 1 000 000 : ٩
10 : ۱ 100 : ۲ 1000 :	۳ 10 000 : ۴ 100 000 : ۵ 1 000 000 : ۶

۱۶1 = 10 000 001
٣ ٤ ٥ ٦ ٧ ٨ ٩
10 : ۱ 100 : ۲ 1 000 :	۳ 10 000 : ۴ 100 000 : ٦ 1 000 000 : ۶
Default:	0	1	2	3	4	5	6	7	8	9	10	100	1000	10000
Arabic, Kurdish, Persian, Sindhi, Urdu
   :	-	١	۲	۳	۴	۵	٦	٧	٨	٩
Chinese:	〇	一	二	三	四	五	六	七	八	九	十	百	千   	万
Malayalam :	-	൧	 ൨	൩	൪	൫	൬	൭	൮	൯	൰
Kannada:	-	೧	೨	೩	೪	೫	೬	೭	 ೮	೯

*/