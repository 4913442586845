import { mdiCloseOutline, mdiContentSaveMove } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { InputGroup } from "reactstrap";
import DocumentService from "../../../services/DocumentService";
import { ObjectId } from "bson";
import { SuccessButton, WarningButton } from "../../../utils/CustomButton";
import { CustomInput } from "../../../utils/CustomInput";

type TDocumentPathEditorProps = {
    docId: ObjectId;
    path: string;
    index: number;
    close: () => void;
    isPublic?: boolean;
}

export const DocumentPathEditor:React.FC<TDocumentPathEditorProps> = (props) => {
    const [path, setPath] = useState(props.path);

    const change = (value: string) => {
        setPath(value);
    }

    const save = () => {
        DocumentService.UpdatePath(props.docId, path, props.index, props.isPublic).then(result => {
            if(result) props.close();
        });
    }

    const cancel = () => {
        setPath(props.path ?? "");
        props.close();
    }
    console.log("DocumentPathEditor", props.isPublic);
    return <InputGroup size="sm">
        <CustomInput value={path} onChange={(evt) => change(evt.currentTarget.value)} />
        <SuccessButton onClick={save}><Icon path={mdiContentSaveMove} size="1.5em" /></SuccessButton>
        <WarningButton onClick={cancel}><Icon path={mdiCloseOutline} size="1.5em" /></WarningButton>
    </InputGroup>
}