import { ObjectId } from "bson";
import { UMongoDB } from "../realm/MongoDB";
import IDocument_Group from "../realm/schemas/Document/IDocument_Group";
import DocumentService from "./DocumentService";

export default class PathGroupsService {
    private static readonly _instance: PathGroupsService = new PathGroupsService();
    public static get Instance() {
        return this._instance;
    }

    private _group_pk: Map<ObjectId, number> = new Map<ObjectId, number>();

    private _group_name: Map<string, number> = new Map<string, number>();

    private _groups: IDocument_Group[] = [];
    
    public static LoadGroup = async (force: boolean = true) => {
        if(this.Instance._groups.length <= 0 || force) {
            let results: IDocument_Group[];

            results = await UMongoDB.document_groups?.find() ?? [];
    
            this.Instance._groups = [];
            this.Instance._group_pk = new Map<ObjectId, number>();
            for (const d of results) {
                var l = this.Instance._groups.push(d);
                const index = l - 1;
                this.Instance._group_pk.set(d._id, index);
                this.Instance._group_name.set(d.name, index);
            }
        }
    }

    public static GetGroupById: (id: ObjectId) => IDocument_Group | null = (id: ObjectId) => {
        const index = this.Instance._group_pk.get(id) ?? null;

        if(index === null) return null;

        return this.Instance._groups[index];
    }

    private static GetGroupByName: (name: string) => IDocument_Group | null = (name: string) => {
        const index = this.Instance._group_name.get(name) ?? null;

        if(index === null) return null;

        return this.Instance._groups[index];
    }

    public static GetGroup:(path: string, withSpace?: boolean) => IDocument_Group | null = (path: string, withSpace: boolean = true) => {
        if(!path || path.length === 0) return null;

        if(withSpace && path.indexOf("|") > 0) {
            path = DocumentService.RemoveSpaceFromPath(path);
        }

        let g = this.GetGroupByName(path);
    
        if(!g) {
            const splited = path.split("|").map(m => m.trim());
            if(splited.length > 1) {
                splited.pop();
                return this.GetGroup(splited.join(" | "), withSpace);
            }
        }
    
        return g;
    }
}