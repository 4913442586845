import { DateTime } from "luxon";

export enum FilterType {
    StartWith,
    EndWith,
    Contain,
    Equal
}

export interface IFilter {
    skip: number;
    limit: number;
}

export interface ITextFiltred {
    filter: string;
    type: FilterType
}

export interface ITextFilter extends IFilter {
    text: ITextFiltred
}

export interface IDateFilter extends IFilter {
    startDate?: DateTime;
    endDate?: DateTime;
}

export function isIDateFilter(filter: IFilter): filter is IDateFilter  {
    return (filter as IDateFilter).startDate !== undefined;
}

export interface IPrivacyFilter extends IFilter {
    isPublicOnly?: boolean;
    isPrivateOnly?: boolean;
}

export interface ITextAndDateFilter extends ITextFilter, IDateFilter {
    startDate?: DateTime;
    endDate?: DateTime;
}

export interface ITextAndDateAdnPrivacyFilter extends ITextFilter, IDateFilter, IPrivacyFilter {

}