import React from "react";
import { SketchPicker, SwatchesPicker, ColorResult, RGBColor } from 'react-color';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "@emotion/styled";

type TColorTabsProps = {
    color: RGBColor,
    colorChange: (color: RGBColor) => void,
    tabChange: () => void
}

type TColorTabsState = {
    color: RGBColor,
    activeTab: string
}

export class ColorTabs extends React.Component<TColorTabsProps, TColorTabsState> {
    constructor(props: Readonly<TColorTabsProps>) {
        super(props);
        this.state = {
            activeTab: "1",
            color: props.color
        };
    }
    
    toggleHandle = (tab: string) => {
        this.setState({
            activeTab: tab
        }, this.props.tabChange);
    };

    handleChange = (color: ColorResult) => {
        this.setState({ color: color.rgb });
        if (this.props.colorChange)
            this.props.colorChange(color.rgb);
    };

    render() {
        return (<ColorTabsContainer>
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => this.toggleHandle('1')}>
                        <FontAwesomeIcon icon="swatchbook" />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => this.toggleHandle('2')}>
                        <FontAwesomeIcon icon="palette" />
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                    <SwatchesPicker color={this.state.color} onChange={this.handleChange} />
                </TabPane>
                <TabPane tabId="2">
                    <SketchPicker color={this.state.color} onChange={this.handleChange} />
                </TabPane>
            </TabContent>
        </ColorTabsContainer>);
    }
}

const ColorTabsContainer = styled.div`  
    li {
        background-color:white;
    }

    ul {
        position: fixed;
        z-index: 10;
    }

    .tab-pane {
        padding-top: 42px;
    }
    .swatches-picker div[style*="overflow-y: scroll"]{
        height: 100% !important;
        overflow-y: visible !important;
    }
`;