import { useParams } from "react-router-dom";
import { ObjectId } from "bson";
import { IDocument } from "../../../realm/schemas/Document/IDocument";
import DocumentView from "./DocumentView";
import Loading, { LoadingDiv } from "../../../utils/Loading";
import { DocumentInfosView } from "./DocumentInfosView";
import { Dialog } from "../../../utils/dialog/DialogManager";
import DoccumentActionsBar from "./DoccumentActionInfoBar";
import styled from "@emotion/styled";
import Constants from "../../../Constants";
import { mdiFile } from "@mdi/js";
import Icon from "@mdi/react";
import HeaderService from "../../../services/HeaderService";
import NeedProfilePage, { INeedProfilePageState } from "../../profile/NeedProfilePage";
import { AuthService } from "../../../services/AuthService";
import DocumentService from "../../../services/DocumentService";
import Localized from "../../../Localization/Localized";
import { isMobile } from "react-device-detect";
import { DialogSize } from "../../../utils/dialog/enums/DialogSize";

export const DocumentViewById = () => {
    let { id } = useParams();

    return <>
        <DocumentPage documentId={new ObjectId(id)} />
    </>
}

type TDocumentLoaderProps = {
    documentId: ObjectId;
}

interface TDocumentLoaderState extends INeedProfilePageState {
    document?: IDocument;
    isLoading: boolean;
    fontSize: number;
    isOwner: boolean;
}

export default class DocumentPage extends NeedProfilePage<TDocumentLoaderProps, TDocumentLoaderState> {
    protected didMount(): void {
        HeaderService.setTitle("Document loading", <><Icon path={mdiFile} size="1em" /> <span>Document loading</span></>);
    }
    protected initState(props: Readonly<{}>): TDocumentLoaderState {
        return {
            isOwner: false,
            currentProfile: AuthService.currentProfile ?? null,
            isLoading: true,
            fontSize: 1
        }
    }
    protected profileChanged(): void {
        this.setState({
            isOwner: AuthService.isOwner(this.state.document?._id)
        });
    }

    componentDidMount = () => {
        this.setTitle();
        this.Load();
    };

    Load = async () => {
        const doc = await DocumentService.GetById(this.props.documentId);

        if (doc) {
            this.setState({
                document: doc,
                isLoading: false,
                isOwner: AuthService.isOwner(this.state.document?._id)
            }, this.setTitle);
        }
        else {
            this.setState({
                isLoading: false,
                isOwner: false
            }, this.setTitle);
        }
    };

    private setTitle() {
        const title = this.state.document?.publicTitle ? this.state.document?.publicTitle : this.state.document?.title;

        const no = this.state.isLoading ? Localized.Document.Loading : Localized.Document.NotFound;
        const nor = this.state.isLoading ? <><Loading /> {Localized.General.Loading}</> : Localized.Document.NotFound;

        HeaderService.setTitle(title ?? no, <><Icon path={mdiFile} size="1em" /> <span>{title ?? nor}</span></>);
    }

    fontSizeDecrease = () => {
        const fs = this.state.fontSize - 0.1;
        this.setState({
            fontSize: fs
        });
        return fs;
    }

    fontSizeIncrease = () => {
        const fs = this.state.fontSize + 0.1;
        this.setState({
            fontSize: fs
        });
        return fs;
    }

    toogleInfo = async () => {
        if(!this.state.document) return;
        let removed = false;
        await Dialog.config(Localized.Document.DocInfoTitle, <DocumentInfosView document={this.state.document} onRemove={() => {
            removed = true;
        }} />, {
            size: isMobile ? DialogSize.Max : DialogSize.L
        });

        if(removed) this.setState({
            document: undefined
        });
    }

    render = () => {
        return <div style={{
            position: "relative"
        }}>
            {this.state.isLoading && <LoadingDiv style={{ fontSize: "2em" }}><Loading /></LoadingDiv>}
            <DocumentActionsBarStyled>
                <DoccumentActionsBar fontSize={this.state.fontSize}
                    toggleInfo={this.toogleInfo}
                    fontSizeDecrease={this.fontSizeDecrease}
                    fontSizeIncrease={this.fontSizeIncrease} />
            </DocumentActionsBarStyled>
            {!this.state.isLoading && this.state.document && <div style={{
                    position: "relative",
                    fontSize: `${this.state.fontSize}em`
                }}>
                <DocumentView document={this.state.document} isReadOnly={this.state.isOwner} />
            </div>}
            {!this.state.isLoading && !this.state.document && <div>{Localized.Document.NotFound}</div>}
        </div>
    }
}

const DocumentActionsBarStyled = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 2000;
    right: 0;
    font-size: ${isMobile ? "1.95em" : "1em"};
    > * {
        opacity: ${Constants.ActionDefaultOpacity};
        :hover {
            opacity:1
        }
    }

    * {
        margin: 0;
        padding: 0 0.25rem 0 0.25rem !important;
    }
    
` 