import React from "react";
import FreeDialog from "./FreeDialog";
import { IDialog } from "./interfaces/IDialog";
import { DialogType } from "./enums/DialogType";
import { Dialog } from "./DialogManager";
import TooltipDialog from "./TooltipDialog";
import PictureDialog from "./PictureDialog";
import ZI from "../../styles/ZIndex";

type TModalDialogState = {
    dialogs: IDialog[],
    isOpen: boolean,
    resize: Date
}

export default class Dialogs extends React.Component<{}, TModalDialogState>{
    constructor(props: Readonly<{}>){
        super(props);

        this.state = {
            dialogs: [],
            isOpen: true,
            resize: new Date()
        };

        Dialog.setStateHandler((dialog) => {
            const dialogs = this.state.dialogs;
            dialogs.push(dialog);

            this.setState({
                dialogs: dialogs,
            });
        });
    }

    private _resize = () => {
        this.setState({
            resize: new Date()
        });
    }

    componentDidMount = () => {
        $(window).on("resize", this._resize);
    }

    componentWillUnmount = () => {
        $(window).off("resize", this._resize);
    }

    dismissHandle = (d: IDialog) => {
        this.setState({
            dialogs: this.state.dialogs.filter(f => f !== d)
        });
    }

    render(){
        return (<div style={{
            position: "fixed",
            top:0,
            right:0,
            left:0,
            zIndex: ZI.Dialog
        }}>
            {this.state.dialogs && this.state.dialogs.map((d, i) =><DialogFactory key={d.id} index={i} dialog={d} dismissHandle={this.dismissHandle}/>)}          
        </div>);
    }
}

type TDialogFactoryProps = {
    dialog: IDialog;
    dismissHandle: (dialog: IDialog) => void;
    index: number;
}

type TDialogFactoryState = {
    onAction: boolean;
    onOkAction: boolean;
    onCancelAction: boolean;
    onDismissAction: boolean
}

class DialogFactory extends React.Component<TDialogFactoryProps, TDialogFactoryState> {
    constructor(props: Readonly<TDialogFactoryProps>){
        super(props);

        this.state = {
            onAction: false,
            onOkAction: false,
            onCancelAction: false,
            onDismissAction: false
        }
    }

    dismissHandle = (d: IDialog) => {
        this.setState({
            onAction: true,
            onDismissAction: true
        }, () => {
            if(d.dismiss) d.dismiss();
            this.props.dismissHandle(d);
        });
    }

    cancelHandle = (d: IDialog) => {
        this.setState({
            onAction: true,
            onCancelAction: true
        }, () => {
            if(d.cancel) d.cancel();
            this.props.dismissHandle(d);
        });        
    }

    okHandle = (d: IDialog) => {
        this.setState({
            onOkAction: true,
            onAction: true
        }, () => {
            const errors = d.validate ? d.validate() : [];
            if(errors.length === 0){
                if(d.beforeOk) {
                    d.beforeOk().then(result => {
                        if(result && d.ok) d.ok();
    
                        if(result) {
                            this.props.dismissHandle(d);
                        } else {
                            this.setState({
                                onOkAction: false,
                                onAction: false                                
                            });
                        }
                    });
                } else if(d.ok) {
                    d.ok();
                    this.props.dismissHandle(d);
                } else {
                    this.setState({
                        onOkAction: false,
                        onAction: false                                
                    });
                }       
            } else {
                this.setState({
                    onOkAction: false,
                    onAction: false                                
                });
            }
        });        
    }

    render(){
        if(this.props.dialog.type === DialogType.Tooltip) return (<TooltipDialog index={this.props.index} dialog={this.props.dialog} 
            okHandle={this.okHandle}
            cancelHandle={this.cancelHandle}
            dismissHandle={this.dismissHandle}
            onAction={this.state.onAction}
            onOkAction={this.state.onOkAction}
            onCancelAction={this.state.onCancelAction}
            onDismissAction={this.state.onDismissAction} />);

        if(this.props.dialog.type === DialogType.Picture) return (<PictureDialog index={this.props.index} dialog={this.props.dialog} 
            okHandle={this.okHandle}
            cancelHandle={this.cancelHandle}
            dismissHandle={this.dismissHandle}
            onAction={this.state.onAction}
            onOkAction={this.state.onOkAction}
            onCancelAction={this.state.onCancelAction}
            onDismissAction={this.state.onDismissAction} />);

        return (<FreeDialog index={this.props.index} dialog={this.props.dialog} 
            okHandle={this.okHandle}
            cancelHandle={this.cancelHandle}
            dismissHandle={this.dismissHandle}
            onAction={this.state.onAction}
            onOkAction={this.state.onOkAction}
            onCancelAction={this.state.onCancelAction}
            onDismissAction={this.state.onDismissAction} />);
    }
}