import { UMongoDB } from "../realm/MongoDB";
import { ISpace } from "../realm/schemas/ISpace";
import { Msg } from "../utils/Messenger";
import { ObjectId } from "bson";
import { AuthService } from "./AuthService";
import { IDocumentFilter } from "../components/Document/FilterAndOrder/IDocumentFilter";
import { FilterType } from "../utils/List/Filter";
import { ISpaceFilter, SpaceFilterDefault } from "../components/Document/Space/FilterAndOrder/SpaceFilter";
import ISpaceOrder, { SpaceOrderDefault } from "../components/Document/Space/FilterAndOrder/SpaceOrder";
import { CacheContainer } from 'node-ts-cache'
import { MemoryStorage } from 'node-ts-cache-storage-memory'
import Constants from "../Constants";
import Localized from "../Localization/Localized";

const spacesCache = new CacheContainer(new MemoryStorage())

export default class SpaceService {
    static async create(name: string): Promise<ISpace|null> {
        return UMongoDB.Functions().space_create(name).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Space.CreatedSuccessfully);
                return result.space;
            }

            if((!result.msg || result.msg.length === 0)) {
                Msg.unexpectedError();
                return null;
            }
            
            let msg = "";
            switch (result.msg) {
                case "AlreadyExist":
                    msg = Localized.Space.NameAlreadyExist;
                    break;
                case "Empty":
                    msg = Localized.Space.NameIsMandatory;
                    break;
                case "10<length<100":
                    msg = Localized.Space.Greater10Less100;
                    break;
                case "NotValid":
                    msg = Localized.Space.NameNotValid;
                    break;         
                default:
                    msg = Localized.GeneralError.Unexpected;
                    break;
            }

            Msg.error(msg);

            return null;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return null;
        }); 
    } 

    static async update(id: ObjectId, name: string): Promise<ISpace|null> {
        return UMongoDB.Functions().space_update(id, name).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Space.UpdatedSuccessfully);
                const s = {
                    spaceId: result.spaceId,
                    name: result.name
                };
                return s;
            }

            if(result.state === "Fail" && (!result.msg || result.msg.length === 0)) {
                Msg.unexpectedError();
                return null;
            }
            
            let msg = "";
            switch (result.msg) {
                case "AlreadyExist":
                    msg = Localized.Space.NameAlreadyExist
                    break;
                case "Empty":
                    msg = Localized.Space.NameIsMandatory;
                    break;
                case "10<length<100":
                    msg = Localized.Space.Greater10Less100;
                    break;
                case "NotValid":
                    msg = Localized.Space.NameNotValid;
                    break;         
                default:
                    msg = Localized.GeneralError.Unexpected;
                    break;
            }

            Msg.error(msg);

            return null;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return null;
        }); 
    } 

    static async publish(id: ObjectId, published: boolean): Promise<boolean> {
        return UMongoDB.Functions().space_publish(id, published).then((result:any) => {
            if(result.state === "OK") {
                Msg.success(Localized.Space.UpdatedSuccessfully);
                return true;
            }

            if(result.msg === "Unauthorized") Msg.error(Localized.General.Unauthorized);
            
            Msg.error(Localized.GeneralError.Unexpected);

            return false;
        }).catch((err: any) => {
            Msg.unexpectedError();
            console.log(err);
            return false;
        }); 
    } 

    static getPaths = async (spaceId: ObjectId, level: number = 0, startWith?: string, filter?: IDocumentFilter) => {
        const isPublicOnly = filter?.isPublicOnly ?? false;
        const isPrivateOnly = filter?.isPrivateOnly ?? false;

        const pipline: any = [];

        // Need to be first
        pipline.unshift({
            '$match': {
                'spaceId': spaceId
            }
        });

          // Need to be second first
        if(startWith && startWith.length > 0) {
            try {
                new RegExp(startWith);

                pipline.push({
                    '$match': {
                        '$or': [
                            {
                                'path': {
                                    '$regex': startWith,
                                    '$options': "i"
                                }
                            },
                            {
                                'publicPath': {
                                    '$regex': startWith,
                                    '$options': "i"
                                }
                            }
                        ]
                    }
                });

            } catch (error) {
                Msg.error("The filter fail the regex!");
            }
        }

        if(isPrivateOnly) {
            pipline.push({
                '$match': {
                    'createdById': AuthService.currentProfile?._id
                }
            });
        }

        if(isPublicOnly) {
            pipline.push({
                '$match': {
                    'isPublished': true
                }
            });
        };

        pipline.push({
            '$match': {
                '$or': [
                    {
                        'referenceId': {
                            '$exists': false
                        }
                    }, {
                        'referenceId': {
                            '$type': 10
                        }
                    }
                ]
            }
        });

        if(filter?.path && filter?.path.filter && filter?.path.filter.length > 0) {
            try {
                let f = filter?.path.filter;

                if(filter?.path.type === FilterType.StartWith) f = `^${f}`;
                if(filter?.path.type === FilterType.EndWith) f = `${f}$`;
                if(filter?.path.type === FilterType.Equal) f = `^${f}$`;

                new RegExp(f);

                pipline.push({
                    '$match': {
                        '$or': [
                            {
                                'path': {
                                    '$regex': f,
                                    '$options': "i"
                                }
                            },
                            {
                                'publicPath': {
                                    '$regex': f,
                                    '$options': "i"
                                }
                            }
                        ]
                    }
                });

            } catch (error) {
                Msg.error("The filter fail the regex!");
            }
        }

        if(filter?.text && filter?.text.filter && filter?.text.filter.length > 0) {
            try {
                let f = filter?.text.filter;

                if(filter?.text.type === FilterType.StartWith) f = `^${f}`;
                if(filter?.text.type === FilterType.EndWith) f = `${f}$`;
                if(filter?.text.type === FilterType.Equal) f = `^${f}$`;

                new RegExp(f);

                pipline.push({
                    '$match': {
                        '$or': [
                            {
                                'path': {
                                    '$regex': f,
                                    '$options': "i"
                                }
                            },
                            {
                                'publicPath': {
                                    '$regex': f,
                                    '$options': "i"
                                }
                            }
                        ]
                    }
                });

            } catch (error) {
                Msg.error("The filter fail the regex!");
            }
        }

        pipline.push({
            '$addFields': {
              'path': {
                '$ifNull': [
                  '$path', []
                ]
              }, 
              'publicPath': {
                '$ifNull': [
                  '$publicPath', []
                ]
              }
            }
        });

        pipline.push({
            '$addFields': {
              'path': {
                '$cond': [
                  {
                    '$isArray': [
                      '$path'
                    ]
                  }, '$path', [
                    '$path'
                  ]
                ]
              }, 
              'publicPath': {
                '$cond': [
                  {
                    '$isArray': [
                      '$publicPath'
                    ]
                  }, '$publicPath', [
                    '$publicPath'
                  ]
                ]
              }
            }
        });

        pipline.push({
            '$addFields': {
              'path': {
                '$concatArrays': [
                  '$path', '$publicPath'
                ]
              }
            }
        });

        pipline.push({
            '$project': {
              'path': 1
            }
        });

        pipline.push({
            '$unwind': {
              'path': '$path', 
              'includeArrayIndex': 'index', 
              'preserveNullAndEmptyArrays': false
            }
        });

        if(startWith && startWith.length > 0) {
            try {
                new RegExp(startWith);

                pipline.push({
                    '$match': {
                        'path': {
                          '$regex': startWith,
                          '$options': "i"
                        }
                      }
                });

            } catch (error) {
                Msg.error("The filter fail the regex!");
            }
        }

        pipline.push({
            '$addFields': {
              'paths': {
                '$split': [
                  '$path', ' | '
                ]
              }
            }
        });

        pipline.push({
            '$unwind': {
              'path': '$paths', 
              'includeArrayIndex': 'partIndex', 
              'preserveNullAndEmptyArrays': false
            }
        });

        pipline.push({
            '$match': {
              'partIndex': level
            }
        });

        pipline.push({
            '$project': {
              'paths': 1, 
              '_id': 0
            }
        });

        pipline.push({
            '$group': {
              '_id': '$paths'
            }
        });

        pipline.push({
            '$sort': {
              '_id': 1
            }
        });

        const results: { _id: string }[] = await UMongoDB.documents?.aggregate(pipline) ?? [];
        return results;
    }

    static GetById =  async (id: ObjectId) => {
        const cachedUsers = await spacesCache.getItem<ISpace>(`${id.toHexString()}`)

        if (cachedUsers) {
            return cachedUsers;
        }

        const result = await UMongoDB.spaces?.findOne({ _id : id});

        if(result) await spacesCache.setItem(`${id.toHexString()}`, result, {ttl: Constants.CacheTimoutInSec})

        return result
    }

    static GetByOwnerId =  async (id: ObjectId) => {
        const result = await UMongoDB.spaces?.find({ createdById: AuthService.currentProfile?._id });
        return result ?? [];
    }

    static All = async (sf?: ISpaceFilter, so?: ISpaceOrder) => {
        const isPublicOnly = sf?.isPublicOnly ?? false;
        const isPrivateOnly = sf?.isPrivateOnly ?? false;
        const filter = sf ?? SpaceFilterDefault();
        const order = so ?? SpaceOrderDefault();

        const startDate = filter?.startDate;
        const endDate = filter?.endDate;

        const limit = filter.limit ?? 100;
        const skip = filter.skip ?? 0;

        const pipline: any = [];

        const matchAnd: any = []

        if(isPrivateOnly) {
            matchAnd.push({
                'createdById': AuthService.currentProfile?._id
            });
        }

        if(isPublicOnly) {
            matchAnd.push({
                'createdById': {
                    '$ne': AuthService.currentProfile?._id
                }
            });
        }

        if(startDate) {
            matchAnd.push({
                'modifiedDateTimeUTC': {
                    '$gte': startDate.toUTC().toJSDate()
                }
            });
        }

        if(endDate) {
            matchAnd.push({
                'modifiedDateTimeUTC': {
                    '$lte': endDate.toUTC().toJSDate()
                }
            });
        }

        if(filter?.text && filter?.text.filter && filter?.text.filter.length > 0) {
            const titleFilter = filter?.text;

            let text = titleFilter.filter;

            if(titleFilter.type === FilterType.StartWith) text = `^${text}`;
            if(titleFilter.type === FilterType.EndWith) text = `${text}$`;

            try {
                new RegExp(text);

                matchAnd.push({
                    'name': {
                        '$regex': text,
                        '$options': "i"
                    }
                });
            } catch (error) {
                Msg.error(Localized.Space.SpaceNameFilterFailRegex);
            }
        }

        if(matchAnd.length > 0) {
            pipline.push({
                '$match': {
                    '$and': matchAnd
                }
            });
        }

        // Sort

        var o = order?.fields.filter(f => f.direction !== "N/A") ?? [];

        if(o && o.length > 0) {
            const sort: any = {}

            for (const s of o) {
                sort[s.field] = s.direction === "A" ? 1 : -1
            }

            console.log("Sort", sort);

            pipline.push({
                '$sort': sort
            });
        }

        // skip
        pipline.push({
            '$skip': skip
        });

        // Limit
        pipline.push({
            '$limit': limit
        });

        const result = await UMongoDB.spaces?.aggregate(pipline);
        return result ?? [];
    }
}
