import IDocumentOrder from "./IDocumentOrder";
import DocumentOrderPopupContent from "./DocumentOrderPopupContent";
import { OrderPopup } from "../../../utils/List/OrderPopup";

export class DocumentOrderPopup extends OrderPopup<IDocumentOrder> {
    getPopupContent(apply: (o: IDocumentOrder) => void): JSX.Element {
        return <DocumentOrderPopupContent order={this.props.order} 
        apply={apply} />;
    }
}
