import styled from "@emotion/styled";
import React from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import Constants from "../Constants";
import OffcanvasService, { Direction, IOffcanvas } from "../services/OffcanvasService";
import ZI from "../styles/ZIndex";
import Colors, { ColorSections } from "../utils/Color/MainColors";

type TOffcanvasContainerState = {
    open: boolean;
    offcanvas: IOffcanvas | null;
    direction: string;
    backdrop?: boolean;
}

export default class OffcanvasContainer extends React.Component<{}, TOffcanvasContainerState> {
    constructor(props: Readonly<{}>) {
        super(props);

        this.state = {
            open: false,
            offcanvas: null,
            direction: "left"
        }
    }

    directionToString = (direction: Direction) => {
        switch (direction) {
            case Direction.Bottom:
                return "bottom";
            case Direction.Top:
                return "top";
            case Direction.Start:
                return "start";
            case Direction.End:
                return "end";
        }
    }

    override componentDidMount = () => {
        OffcanvasService.init(o => {
            this.setState({
                open: true,
                offcanvas: o,
                direction: this.directionToString(o.direction),
                backdrop: o.backdrop ?? true
            });
        }, () => {
            this.setState({
                open: false,
                offcanvas: null,
                direction: "left",
                backdrop: true
            });
        });
    }

    override componentWillUnmount = () => {
        OffcanvasService.init(null, null);
    }

    close = () => {
        this.setState({
            open: false
        });
    }

    render = () => {
        return <>
            <OffcanvasStyled direction={this.state.direction} toggle={this.close} isOpen={this.state.open} scrollable backdrop={this.state.backdrop}>
                <OffcanvasHeader toggle={this.close} style={{
                    backgroundColor: Colors.get(ColorSections.OffcanvasHeaderBG),
                    color: Colors.get(ColorSections.OffcanvasHeaderText)}}>
                    <React.StrictMode>
                        {this.state.offcanvas?.header}
                    </React.StrictMode>
                </OffcanvasHeader>
                <OffcanvasBody style={{
                    backgroundColor: Colors.get(ColorSections.OffcanvasBobyBG),
                    color: Colors.get(ColorSections.OffcanvasBodyText)}}>
                    <React.StrictMode>
                        {this.state.offcanvas?.body}
                    </React.StrictMode>
                </OffcanvasBody>
            </OffcanvasStyled>
        </>
    }
}

const OffcanvasStyled = styled(Offcanvas)`
    z-index: ${ZI.OffCanvasHight};
    .offcanvas-header .btn-close {
        background-color: ${Colors.get(ColorSections.OffcanvasHeaderText)};
        opacity: ${Constants.ActionDefaultOpacity};
        align-self: start;
        margin-top: 0.5rem;
        :hover {
            opacity: 1;
        }
    }

    .offcanvas-title {
        line-height: 1;
        font-size: 2.5em;
    }

    .offcanvas-body {
        padding: 0;
    }
`
