import { mdiContentSave, mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { ButtonGroup } from "reactstrap";
import Colors, { ColorSections } from "../../../../../utils/Color/MainColors";
import { ActionButton } from "../ActionButton";

export type TAddCustomProps = {
    onClick?: () => Promise<boolean>
}

export type TAddCustomState = {
    inAction: boolean
}

export class AddCustom extends React.Component<TAddCustomProps, TAddCustomState> {
    protected _backgroundColor: string;
    protected _borderColor: string;
    protected _color: string;
    protected _icon: string;

    constructor(props: TAddCustomProps | Readonly<TAddCustomProps>) {
        super(props);

        this._backgroundColor = Colors.get(ColorSections.BtnSecondaryBG);
        this._borderColor = Colors.get(ColorSections.BtnSecondaryBorder);
        this._color = Colors.get(ColorSections.BtnSecondaryColor);
        this._icon = mdiContentSave;

        this.state = {
            inAction: false
        }
    }

    action = () => {
        if(this.props.onClick) {
            this.setState({
                inAction: true
            }, () => {
                if(this.props.onClick) this.props.onClick().then(result => {
                    this.setState({
                        inAction: false
                    });
                });
            });
        }
    }

    render = () => {
        return <ButtonGroup size="sm">
        <ActionButton enabled={!this.state.inAction} onClick={this.action} style={{
            backgroundColor: this._backgroundColor,
            borderColor: this._borderColor,
            color: this._color,
        }} ><Icon path={this.state.inAction ? mdiLoading : this._icon} size="1.5em" spin={this.state.inAction} /></ActionButton>
    </ButtonGroup>
    }
}


