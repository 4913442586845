import { useActive, useCommands } from "@remirror/react";
import { ButtonGroup } from "reactstrap";
import { ActionButton } from "../ActionButton";
import Icon from '@mdi/react';
import { mdiFormatListCheckbox, mdiFormatListBulleted, mdiFormatListNumbered } from '@mdi/js'; 

export const AddList = () => {
    const commands = useCommands();
    const active = useActive(true);

    return <ButtonGroup size="sm">
        <ActionButton active={active.taskList()} enabled={commands.toggleTaskList.enabled()} onClick={() => commands.toggleTaskList()}><Icon path={mdiFormatListCheckbox} size="1.5em" /></ActionButton>
        <ActionButton active={active.bulletList()} enabled={commands.toggleBulletList.enabled()} onClick={() => commands.toggleBulletList()}><Icon path={mdiFormatListBulleted} size="1.5em" /></ActionButton>
        <ActionButton active={active.orderedList()} enabled={commands.toggleOrderedList.enabled()} onClick={() => commands.toggleOrderedList()}><Icon path={mdiFormatListNumbered} size="1.5em" /></ActionButton>
    </ButtonGroup>
}