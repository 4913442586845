import React from "react";
import { Input } from "reactstrap";
import Colors, { ColorSections } from "./Color/MainColors";
import {style} from "typestyle";
import { InputType } from "reactstrap/types/lib/Input";
import { isMobile } from "react-device-detect";

enum InputState {
    Default,
    Success,
    Warning,
    Error
}

interface IProps {
    size?: "sm" | "lg";
    disabled?: boolean;
    style?: React.CSSProperties;
    state?: InputState
    placeholder?: string;
    defaultValue?: string;
    value?: string;
    type?: InputType;
    name?: string;
    readOnly?: boolean;
    maxLength?: number;
    onChange?:(evt:React.ChangeEvent<HTMLInputElement>) => void;
}

interface ICustomProps extends IProps {
    state?:InputState;
}

export const CustomInput = (props: ICustomProps) => {
    // TODO: Change color depending of the state
    //const state = props.state ?? InputState.Default;
    const size = props.size ?? (isMobile ? "lg" : "sm");

    const inputClass = style({
        backgroundColor: Colors.get(ColorSections.InputBG), 
        borderColor: Colors.get(ColorSections.InputBorder),
        color: Colors.get(ColorSections.InputColor),
        $nest: {
            '&:focus': {
                backgroundColor: Colors.get(ColorSections.InputFocusBG), 
                borderColor: Colors.get(ColorSections.InputFocusBorder),
                color: Colors.get(ColorSections.InputFocusColor),
                boxShadow: `0 0 0 0.25rem ${Colors.get(ColorSections.InputFocusBoxShadow, 0.25)}`
            },
            '&::placeholder': {
                color: Colors.get(ColorSections.InputPlaceholder)
            },

            '&:focus::placeholder': {
                color: Colors.get(ColorSections.InputFocusPlaceholder)
            }
        }
    });

    return <Input 
        name={props.name}
        type={props.type}
        bsSize={size} 
        className={inputClass} 
        placeholder={props.placeholder} 
        defaultValue={props.defaultValue} 
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
        maxLength={props.maxLength}
        disable={props.disabled} />
}

interface TCustomSelectProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {

}

export const CustomSelect = (props: TCustomSelectProps) => {
    const inputClass = style({
        backgroundColor: Colors.get(ColorSections.InputBG), 
        borderColor: Colors.get(ColorSections.InputBorder),
        color: Colors.get(ColorSections.InputColor),
        $nest: {
            '&:focus': {
                backgroundColor: Colors.get(ColorSections.InputFocusBG), 
                borderColor: Colors.get(ColorSections.InputFocusBorder),
                color: Colors.get(ColorSections.InputFocusColor),
                boxShadow: `0 0 0 0.25rem ${Colors.get(ColorSections.InputFocusBoxShadow, 0.25)}`
            },
            '&::placeholder': {
                color: Colors.get(ColorSections.InputPlaceholder)
            },

            '&:focus::placeholder': {
                color: Colors.get(ColorSections.InputFocusPlaceholder)
            }
        }
    });

    return <select className={inputClass} {...props}>{props.children}</select>
}