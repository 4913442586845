import React from "react";
import { InputGroup } from "reactstrap";
import { CustomInput } from "../../utils/CustomInput";
import { CustomInputGroupText } from "../../utils/CustomInputGroupText";
import { ObjectId } from "bson";
import { ISpace } from "../../realm/schemas/ISpace";
import { AuthService } from "../../services/AuthService";
import styled from "@emotion/styled";
import Keys from "./DocumentView/Keys";
import { DialogResult } from "../../utils/dialog/enums/DialogResult";
import { FilterType, ITextFiltred } from "../../utils/List/Filter";
import { Dialog } from "../../utils/dialog/DialogManager";
import DocumentService from "../../services/DocumentService";
import SpaceService from "../../services/SpaceService";
import Localized from "../../Localization/Localized";
import {isMobile} from 'react-device-detect';
import { DialogSize } from "../../utils/dialog/enums/DialogSize";

export default class CreateDocumentPopup {
    static open = async (pathFilter?: ITextFiltred, spaceId?: ObjectId) => {
        let newTitle: string | null = null;
        let newPath: string | null = null;
        let newSpaceId: ObjectId | undefined;
        let newKeys: string[] = [];

        const path = pathFilter?.type === FilterType.Equal || pathFilter?.type === FilterType.StartWith ? pathFilter.filter : undefined; 
        const result = await Dialog.prompt(Localized.Document.CreateDocumentPopupTitle, <CreateDocument path={path} onChange={(t, p, k, s) => {
            newTitle = t;
            newPath = p;
            newSpaceId = s;
            newKeys = k;
        }} spaceId={spaceId} />, {
            size: isMobile ? DialogSize.Max : DialogSize.L
        });

        if(result === DialogResult.Ok && newTitle) {
            await DocumentService.create(newTitle, newKeys, newPath ?? undefined, newSpaceId);
          
            return true;
        }

        return false;
    }
}


type TCreateDocumentProps = {
    spaceId?: ObjectId;
    path?: string | null;
    onChange: (title:string, path:string, keys: string[], space?: ObjectId) => void;
}

type TCreateDocumentState = {
    newTitle: string;
    newPath: string;
    newSpaceId?: ObjectId;
    spaces: ISpace[];
    keys: string[];
}

class CreateDocument extends React.Component<TCreateDocumentProps, TCreateDocumentState> {
    constructor(props: Readonly<TCreateDocumentProps>) {
        super(props);

        this.state = {
            newTitle: "",
            newPath: props.path ?? "",
            newSpaceId: props.spaceId,
            spaces: [],
            keys: []
        };
    }

    componentDidMount = () => {
        this.loadSpace();
    }
    onChange = () => {
        this.props.onChange(this.state.newTitle, this.state.newPath, this.state.keys, this.state.newSpaceId);
    };

    loadSpace = async () => {
        if(!AuthService.currentProfile) return;

        const spaces = await SpaceService.GetByOwnerId(AuthService.currentProfile?._id);

        this.setState({
            spaces: spaces ?? []
        });
    }

    keysChanged = async (keys: string[]) => {
        this.setState({
            keys: keys
        });

        return true;
    };

    render = () => {
        return <Form>
        <InputGroup size="sm">
            <CustomInputGroupText>{Localized.General.Space}</CustomInputGroupText>
            <select className="form-select" value={this.state.newSpaceId?.toHexString()} onChange={(evt) => this.setState({
                    newSpaceId: new ObjectId(evt.currentTarget.value)
                }, this.onChange)}>
                {this.state.spaces.map(m => <option key={m._id.toHexString()} value={m._id.toHexString()}>{m.name}</option>)}
            </select>
        </InputGroup>
        <InputGroup size="sm">
            <CustomInputGroupText>{Localized.General.Path}</CustomInputGroupText>
            <CustomInput value={this.state.newPath} onChange={(evt) => this.setState({
                newPath: evt.currentTarget.value
            }, this.onChange)} />
        </InputGroup>
        <InputGroup size="sm">
            <CustomInputGroupText>{Localized.General.Title}</CustomInputGroupText>
            <CustomInput value={this.state.newTitle} onChange={(evt) => this.setState({
                newTitle: evt.currentTarget.value
            }, this.onChange)} />
        </InputGroup>
        <Keys keys={this.state.keys} isEditable keysChanged={this.keysChanged}/>
        </Form>;
    };
}

const Form = styled.div`
    padding: 0.25rem;
    > div:not(:first-of-type) {
        margin-top: 0.25rem
    }
`;