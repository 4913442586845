import styled from "@emotion/styled";
import { mdiDelete, mdiPublish } from "@mdi/js";
import Icon from "@mdi/react";
import { DateTime } from "luxon";
import React from "react";
import Localized from "../../../Localization/Localized";
import IDocumentInfo from "../../../realm/schemas/Document/IDocument";
import { AuthService } from "../../../services/AuthService";
import DocumentService from "../../../services/DocumentService";
import { DangerButton, PrimaryButton } from "../../../utils/CustomButton";
import DateHelper from "../../../utils/date/DateHelper";
import { Dialog } from "../../../utils/dialog/DialogManager";
import { DialogResult } from "../../../utils/dialog/enums/DialogResult";
import { Display, DisplayId } from "../../../utils/DisplayId";
import DisplayName from "../../../utils/DisplayName";
import { Msg } from "../../../utils/Messenger";
import DocumentTitleAndPath from "./DocumentTitleAndPath";
import Keys from "./Keys";

type DocumentInfosViewProps = {
    document: IDocumentInfo;
    onRemove?: () => void;
    onChange?: () => void;
}

export const DocumentInfosView: React.FC<DocumentInfosViewProps> = (props: DocumentInfosViewProps) => {
    const isEditable = AuthService.isOwner(props.document.createdById);
    const remove = async () => {
        const result = await Dialog.confirm(<span dangerouslySetInnerHTML={{
            __html: Localized.Document.MsgConfirmationRemove
        }} />);

        if(result === DialogResult.Ok) {
            const result = await DocumentService.remove(props.document._id);
            if(result && props.onRemove) props.onRemove();
        }
    }

    const publish = async () => {
        if(!props.document.publicTitle || props.document.publicTitle.length === 0) {
            Msg.error(Localized.Document.ErrorPublishPublictitle);
            return;
        }

        //TODO: Add an input to validate the delete. Ex: Enter DELETE to confirm
        const result = await Dialog.confirm(Localized.Document.MsgConfirmationPublish);

        if(result) {
            const result = await DocumentService.publish(props.document._id);
            if(result && props.onChange) props.onChange();
        }
    }

    const keysChanged = async (keys: string[]) => {
        const response = await DocumentService.UpdateKeys(props.document._id, keys);

        return response;
    }

    return <FormStyled>
        <DisplayId value={props.document._id.toHexString()} label={Localized.General.Id} />
        <DocumentTitleAndPath document={props.document} />
        <Keys keys={props.document.keys ?? []} isEditable={isEditable} keysChanged={keysChanged}/>
        {props.document.createdById && props.document.createdDateTimeUTC && <UserAndTimeInfos>
            <span>{Localized.General.CreatedBy}</span>
            <DisplayName id={props.document.createdById} />
            <span>{Localized.General.OnDate}</span>
            <span>{DateTime.fromJSDate(props.document.createdDateTimeUTC).toLocal().toFormat(DateHelper.dateTimeFormatShort)}</span>
        </UserAndTimeInfos>}
        {props.document.modifiedById && props.document.modifiedDateTimeUTC && <UserAndTimeInfos>
            <span>{Localized.General.LastModifiedBy}</span>
            <DisplayName id={props.document.modifiedById} />
            <span>{Localized.General.OnDate}</span>
            <span>{DateTime.fromJSDate(props.document.modifiedDateTimeUTC).toLocal().toFormat(DateHelper.dateTimeFormatShort)}</span>
        </UserAndTimeInfos>}
        {props.document.publishedDateTimeUTC && <Display value={DateTime.fromJSDate(props.document.publishedDateTimeUTC).toLocal().toFormat(DateHelper.dateTimeFormatShort)} 
        label={`${Localized.General.Published} ${Localized.General.OnDate}`} />}
        {AuthService.isOwner(props.document.createdById) && <InfoActions>
            {!props.document.isPublished && <PrimaryButton title={Localized.General.Publish} maIcon={mdiPublish}
                onClick={publish}>
                    {Localized.General.Publish}
            </PrimaryButton>}
            <DangerButton onClick={remove} style={{
                marginLeft: "2em"
            }}><Icon path={mdiDelete} size="1.5em" /> {Localized.General.Remove}</DangerButton>
        </InfoActions>}
    </FormStyled>
}

const FormStyled = styled.div`
    padding: 0.25rem;
    > * {
        margin-bottom: 0.25rem;
    }
`;

const InfoActions = styled.div`
    display: flex;
    justify-content: start;
`;

const UserAndTimeInfos = styled.div`
    > span:not(:last-of-type) {
        margin-right: 0.25rem;
    }
`;