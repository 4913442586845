import { RGBColor } from "react-color";
import Observer from "../utils/Observer";

export default class ColorService extends Observer {
    public static readonly ColorChangedEventKey = "ColorChangedEventKey";

    private static readonly _instance: ColorService = new ColorService();
    public static get Instance() {
        return this._instance;
    }

    private _currentHighlighterColor: RGBColor = {
        r: 252,
        g: 211,
        b: 3,
        a: 1
    };

    private _currentTextColor: RGBColor = {
        r: 228,
        g: 230,
        b: 235,
        a: 1
    };

    static HighlighterColorChangedKey = "HighlighterColorChanged";

    static TextColorChangedKey = "TextColorChanged";

    static get CurrentHighlighterColor(){
        return this._instance._currentHighlighterColor;
    }

    static set CurrentHighlighterColor(color: RGBColor){
        this._instance._currentHighlighterColor = color;
        this._instance.notifyChange(this.ColorChangedEventKey, this.HighlighterColorChangedKey, color);
    }

    static get CurrentTextColor(){
        return this._instance._currentTextColor;
    }

    static set CurrentTextColor(color: RGBColor){
        this._instance._currentTextColor = color;
        this._instance.notifyChange(this.ColorChangedEventKey, this.TextColorChangedKey, color);
    }

    static subscribeChange(key: string, callBack: (field?: string, value?: any) => void): void {
        this.Instance.subscribeChange(this.ColorChangedEventKey, key, callBack);
    }

    static unsubscribeChange(key: string): void {
        this.Instance.unsubscribeChange(this.ColorChangedEventKey, key);
    }
}