import Icon, { Stack } from '@mdi/react';
import { mdiFormatLetterCaseUpper, mdiFormatLetterCaseLower, mdiFormatLetterCase, mdiMenuDown } from '@mdi/js'; 
import { ActionButton } from "../ActionButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCommands } from '@remirror/react';
import { ButtonGroup } from 'reactstrap';
import { useState } from 'react';
import styled from '@emotion/styled';
import { SecondaryButton } from '../../../../../utils/CustomButton';

export const AddTestCase = () => {
    const [open, setOpen] = useState(false);
    const commands = useCommands();

    return <ButtonGroup size="sm">
        <SecondaryButton onClick={() => setOpen(!open)}>
            <Toggle size="1.5em" open={open}>
                <Icon path={mdiFormatLetterCase} />
                <Icon path={mdiMenuDown} />
            </Toggle>
        </SecondaryButton>
        {open && <>
            <ActionButton enabled onClick={() => commands.setTextCase({ casing : "small-caps" })}><span>A<span style={{ fontSize: "x-small", fontWeight: "bold" }}>A</span></span></ActionButton>
            <ActionButton enabled onClick={() => commands.setTextCase({ casing : "uppercase" })}><Icon path={mdiFormatLetterCaseUpper} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={() => commands.setTextCase({ casing : "lowercase" })}><Icon path={mdiFormatLetterCaseLower} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={() => commands.setTextCase({ casing : "capitalize" })}><Icon path={mdiFormatLetterCase} size="1.5em" /></ActionButton>
            <ActionButton enabled onClick={() => commands.setTextCase({ casing : "none" })}><FontAwesomeIcon icon="ban" /></ActionButton>
        </>}
    </ButtonGroup>
}

type TProps = {
    open: boolean;
}

const Toggle = styled(Stack)<TProps>`
    path:first-of-type {
        transform: scale(0.85) translate(-5px, 0px)!important;
        transform-origin: center center;
    }
    path:last-of-type {
        transform: ${props => `scale(1.125) translate(8px, 0px) rotate(${props.open ? "-" : ""}90deg) !important`};
        transform-origin: center center;
    }
`;