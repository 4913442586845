import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCommands } from "@remirror/react";
import { ButtonGroup } from "reactstrap";
import { ActionButton } from "../ActionButton";

export const AddHorizontalRule = () => {
    const commands = useCommands();
    return <ButtonGroup size="sm">
            <ActionButton enabled onClick={() => commands.insertHorizontalRule()}><FontAwesomeIcon icon="minus" /></ActionButton>
        </ButtonGroup>
}